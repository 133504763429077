package org.company.app.presentation.viewmodel


import androidx.compose.ui.graphics.ImageBitmap
import api.RegistrationApi
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import dev.icerock.moko.mvvm.viewmodel.ViewModel
import io.github.aakira.napier.Napier
import io.ktor.client.HttpClient
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import org.company.app.data.model.*
import org.company.app.util.ApiResponse
import org.company.app.util.Constants
import org.company.app.util.safeRequest
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.readResourceBytes
import org.openapitools.client.models.LoginRequest
import org.openapitools.client.models.UserRegistrationResponse
import toImageBitmap

public data class LoginUiState(
    val loginResponse: UserRegistrationResponse = UserRegistrationResponse(),
    val errorMessage: String? =null,
    var isError: Boolean = false,
    val sign_ingoogle_image: ImageBitmap?=null,
)


@OptIn(InternalResourceApi::class)
public class LoginViewModel(val client: HttpClient): ViewModel() {
    private val _loginState = MutableStateFlow<LoginUiState>(LoginUiState())
    public val loginState: StateFlow<LoginUiState> = _loginState.asStateFlow()


    init {
        viewModelScope.launch {
            try {
                _loginState.update {
                    it.copy(sign_ingoogle_image = readResourceBytes("drawable/google_.png").toImageBitmap())
                }
            } catch (exception:Exception){}

        }

    }

    override fun onCleared() {
        client.close()
    }
    fun resetPassword(email: String,block:  () -> Unit){
        viewModelScope.launch {
            val response= client.safeRequest<ResetPasswordResponse, ErrorResponse> {
                url {
                    method = HttpMethod.Post
                    url("https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode")
                    parameters.append("key",Constants.FirebaseKey)
                }
                contentType(ContentType.Application.Json)
                setBody(ResetPasswordRequest(requestType = "PASSWORD_RESET", email = email))


            }
            when(response){
                is ApiResponse.Success -> {
                    block()
                }
                is ApiResponse.Error.GenericError -> {
                    Napier.e("GenericError $response")
                    _loginState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.HttpError -> {
                    Napier.e("HttpError $response")

                        _loginState.update {
                            it.copy(
                                errorMessage = response.errorBody)
                        }



                }
                is ApiResponse.Error.SerializationError -> {
                    Napier.e("SerializationError ${response.errorMessage}")
                    _loginState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
            }

        }
    }
    fun login(email:String,password:String){
        viewModelScope.launch {
            val settings: Settings = Settings()
            when(val response = firebaseLoginSafeRequest(email, password)){
                is ApiResponse.Success -> {
                    Constants.token = response.body.idToken
                    settings["access_token"] = response.body.idToken
                    settings["refresh_token"] = response.body.refreshToken
                    loginApi(email)
                }
                is ApiResponse.Error.GenericError -> {
                    Napier.e("GenericError $response")
                    _loginState.update {
                        it.copy(loginResponse = UserRegistrationResponse(),
                            errorMessage = response.errorMessage)
                    }
                    Constants.token = ""
                    settings.clear()
                    //  throw Throwable("${response.errorMessage}")
                }
                is ApiResponse.Error.HttpError -> {
                    Napier.e("HttpError $response")
                    val json: JsonElement = Json.parseToJsonElement(response.fireBaseMessage?:"Something went wrong")
                    val message: String = json.jsonObject["error"]?.jsonObject?.get("message")?.jsonPrimitive?.content ?: "Something went wrong"
                    _loginState.update {
                        it.copy(loginResponse = UserRegistrationResponse(),
                            errorMessage = message)
                    }
                    Constants.token = ""
                    settings.clear()


                }
                is ApiResponse.Error.SerializationError -> {
                    Napier.e("SerializationError ${response.errorMessage}")
                    _loginState.update {
                        it.copy(loginResponse = UserRegistrationResponse(),
                            errorMessage = response.errorMessage)
                    }
                    Constants.token = ""
                    settings.clear()
                    //  throw Throwable("${response.errorMessage}")
                }
            }




        }
        /*
        viewModelScope.launch {
                when(val response =loginSafeRequest(email, password)){
                    is ApiResponse.Success -> {
                       // response.body
                        _loginState.update {
                            it.copy(loginResponse = response.body)
                        }
                    }
                    is ApiResponse.Error.GenericError -> {
                        Napier.e("GenericError $response")
                        _loginState.update {
                            it.copy(loginResponse = null,
                                errorMessage = response.errorMessage)
                        }
                      //  throw Throwable("${response.errorMessage}")
                    }
                    is ApiResponse.Error.HttpError -> {
                        Napier.e("HttpError $response")
                        _loginState.update {
                            it.copy(loginResponse = null,
                                errorMessage = response.errorMessage)
                        }
                       // throw Throwable("${response.code} - ${response.errorMessage}")
                    }
                    is ApiResponse.Error.SerializationError -> {
                        Napier.e("SerializationError ${response.errorMessage}")
                        _loginState.update {
                            it.copy(loginResponse = null,
                                errorMessage = response.errorMessage)
                        }
                      //  throw Throwable("${response.errorMessage}")
                    }
                }

        }
        */
    }
    fun clearLoginUIState(){
        _loginState.update {
            it.copy(loginResponse = UserRegistrationResponse(),errorMessage = null,isError = false)
        }
    }
    fun setOpenDialog(boolean: Boolean){
        _loginState.update {
            it.copy(isError = boolean, loginResponse = UserRegistrationResponse())
        }
    }
    suspend fun loginSafeRequest(email: String): ApiResponse<LoginResponse, ErrorResponse> {
       return client.safeRequest<LoginResponse,ErrorResponse> {
            url {
                method = HttpMethod.Post
                
                path("${Constants.env}/users/login")
                setBody(SignInRequest(email))
            }
        }
    }
    suspend fun firebaseLoginSafeRequest(email: String,password: String): ApiResponse<FirebaseLoginResponse, ErrorResponse> {
        return client.safeRequest<FirebaseLoginResponse,ErrorResponse> {
            url {
                method = HttpMethod.Post
                url("https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${Constants.FirebaseKey}")
                setBody(FirebaseLoginRequest(email,password,true))
            }
        }
    }
    suspend fun loginApi(email: String){
        val settings = Settings()
        val registrationApi = RegistrationApi()
       val response= registrationApi.login(loginRequest = LoginRequest(email = email),authorization = settings["access_token"])
        if (response.success){
            _loginState.update {
                it.copy(loginResponse = response.body())
            }
            settings["isEmailVerified"] = response.body().data?.isEmailVerified
        } else {
            _loginState.update {
                it.copy(loginResponse = UserRegistrationResponse(),
                    errorMessage = response.body().message)
            }
        }

    }

}