package org.company.app.presentation.viewmodel

import api.BoardsApi
import org.company.app.data.model.CreateBoardRequest


import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import dev.icerock.moko.mvvm.viewmodel.ViewModel
import io.github.aakira.napier.Napier
import io.ktor.client.HttpClient
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.util.InternalAPI
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import org.company.app.data.model.ErrorResponse
import org.company.app.util.ApiResponse
import org.company.app.util.Constants
import org.company.app.util.safeRequest
import org.openapitools.client.models.BoardRequest
import org.openapitools.client.models.BoardResponse

public data class OnBoardingUiState(
    val createBoard: BoardResponse = BoardResponse(),
    val errorMessage: String? =null,
    var isError: Boolean = false,
)


public class OnBoardingViewModel(val client: HttpClient): ViewModel() {
    private val _onBoardingState = MutableStateFlow<OnBoardingUiState>(OnBoardingUiState())
    public val onBoardingState: StateFlow<OnBoardingUiState> = _onBoardingState.asStateFlow()


    init {

    }

    override fun onCleared() {
        client.close()
    }
    fun clearUIState(){
        _onBoardingState.update {
            it.copy(errorMessage = null,isError = false)
        }
    }
    @OptIn(InternalAPI::class)
    fun createBoard(name:String, columns:List<BoardRequest.Columns>?=null,numPeopleOptions:String?=null,toolUsed:String?=null){
        val boardsApi = BoardsApi()
        val settings = Settings()
        viewModelScope.launch {
            val response =  boardsApi.createBoard(BoardRequest(name = name, columns = columns,numPeopleOptions=numPeopleOptions, toolUsed = toolUsed),authorization =settings["access_token"] )
            if (response.success){
                _onBoardingState.update {
                    it.copy(createBoard = response.body())
                }
            } else {
                Napier.e("ApiError $response")
                _onBoardingState.update {
                    it.copy(
                        errorMessage = response.body().message)
                }
        }



    }

}
}