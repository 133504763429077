package org.company.app.theme

/*
 * Copyright 2020 The Android Open Source Project
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */


import androidx.compose.material3.Typography
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp

var Sf: FontFamily? = null // init in platform code
var Karla: FontFamily? = null // init in platform code

val Typography by lazy {
    Typography(
        bodyLarge = TextStyle(
          //  fontSize = 24.sp,
            fontFamily = Sf,
            fontWeight = FontWeight.Bold,
            lineHeight = 24.sp
        ),
        bodySmall = TextStyle(
          //  fontSize = 16.sp,
            fontFamily = Sf,
            fontWeight = FontWeight.Normal,
            lineHeight = 24.sp
        ),
        bodyMedium = TextStyle(
          //  fontSize = 18.sp,
            fontFamily = Sf,
            fontWeight = FontWeight.Medium,
            lineHeight = 24.sp
        ),
        displayMedium = TextStyle(
            //  fontSize = 18.sp,
            fontFamily = Sf,
            fontWeight = FontWeight.SemiBold,
            lineHeight = 24.sp
        )
    )
}
