package org.company.app.presentation.component

import androidx.compose.foundation.layout.Row
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Home
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Snackbar
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color



@Composable
fun CustomSnackBar(
    message: String,
    containerColor: Color = Color.White
) {
    Snackbar(containerColor = containerColor) {
        Row {
            Text(message, style = MaterialTheme.typography.bodySmall, color = Color.Black)
           /* Icon(
                Icons.Default.Home,
                contentDescription = null
            ) */
        }

    }
}