package org.company.app.presentation.ui.profile

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowBack
import androidx.compose.material.icons.filled.MoreVert
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.darkrockstudios.libraries.mpfilepicker.FilePicker
import com.mohamedrejeb.calf.core.LocalPlatformContext
import com.mohamedrejeb.calf.io.readByteArray
import com.mohamedrejeb.calf.picker.FilePickerFileType
import com.mohamedrejeb.calf.picker.FilePickerSelectionMode
import com.mohamedrejeb.calf.picker.rememberFilePickerLauncher
import com.seiko.imageloader.rememberImagePainter
import kotlinx.coroutines.launch
import org.company.app.presentation.component.ZiFocusPasswordTextField
import org.company.app.presentation.component.ZiFocusTextField
import org.company.app.presentation.ui.isValidEmail
import org.company.app.presentation.utils.getRandomColor
import org.company.app.presentation.utils.mapBlockerToTask
import org.company.app.presentation.viewmodel.HomeViewModel
import org.company.app.theme.AppTheme
import org.koin.compose.koinInject

object ProfileScreen : Screen {
    @Composable
    override fun Content() {
        Column {
            ProfileScreenContent()
        }

    }
    @OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
    @Composable
    fun ProfileScreenContent(){
        val windowSizeClass = calculateWindowSizeClass()
        val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
        val homeViewModel = koinInject<HomeViewModel>()
        val homeState = homeViewModel.homeState.collectAsState()
        val navigator = LocalNavigator.currentOrThrow
        val fullName = remember { mutableStateOf("") }
        val userName = remember { mutableStateOf("") }
        val avatar = remember { mutableStateOf<String?>(null) }
        val user = homeState.value.user
        fullName.value = user?.name?:""
        userName.value = user?.userName?:""
        avatar.value = user?.avatar
        var isUpdated by remember { mutableStateOf(false) }
        val expandDropDown = remember { mutableStateOf(false) }
        var showFilePicker by remember { mutableStateOf(false) }



        val scope = rememberCoroutineScope()
        val context = LocalPlatformContext.current

        val pickerLauncher = rememberFilePickerLauncher(
            type = FilePickerFileType.Custom(listOf("application/pdf","image/*")),
            selectionMode = FilePickerSelectionMode.Single,
            onResult = { files ->
                scope.launch {
                    files.firstOrNull()?.let { file ->
                        // Do something with the selected file
                        // You can get the ByteArray of the file
                        expandDropDown.value=false
                        file.readByteArray(context)
                        homeViewModel.uploadProfilePhoto(file, context = context, block = {
                            avatar.value = it
                            user?.copy(avatar = avatar.value)?.let { homeViewModel.updateUser(it) }
                        })
                    }
                }
            }
        )



        AppTheme {
            TextButton(onClick = { navigator.pop() }, modifier = Modifier.padding(horizontal = 16.dp)){
                Icon(Icons.Default.ArrowBack,null, tint = MaterialTheme.colorScheme.primaryContainer)
                Text(
                    text = "Back",
                    fontSize = 18.sp,
                    color = MaterialTheme.colorScheme.primaryContainer,
                    // style = MaterialTheme.typography.bodyMedium
                    style = MaterialTheme.typography.bodyMedium
                )
            }
            Column(modifier = Modifier.fillMaxSize(),
              //  verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally)
            {
                Spacer(modifier = Modifier.height(45.dp))
                Box {
                    if (avatar.value.isNullOrEmpty()){
                        val name = user?.name?:""
                        val words = name.split(" ")
                        val initials = if (words.size > 1) {
                            words[0].take(1) + words[words.lastIndex].take(1)
                        } else {
                            words[0].take(2)
                        }.uppercase()
                        TextButton(onClick = {expandDropDown.value = true},modifier = Modifier
                            .size(90.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                            Text(
                                text = initials,
                                fontSize = 24.sp,
                                color = Color.White,
                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                maxLines = 1,
                                overflow = TextOverflow.Ellipsis,
                                textAlign = TextAlign.Center
                            )
                        }

                    }
                    else {
                        Image(rememberImagePainter(avatar.value?:""),modifier = Modifier
                            .size(90.dp)
                            .clip(CircleShape)
                            .clickable { expandDropDown.value=true }
                            .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                    }


                    DropdownMenu(
                        expanded = expandDropDown.value,
                        onDismissRequest = { expandDropDown.value = false },
                        modifier = Modifier.align(Alignment.TopEnd).background(color = Color.White)
                    ){
                        DropdownMenuItem(
                            text = { Text("Upload image",
                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                style =  MaterialTheme.typography.bodySmall,
                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                , fontSize = 15.sp) },
                            onClick = {
                                pickerLauncher.launch()
                            }
                        )
                        DropdownMenuItem(
                            text = { Text("Delete image",
                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                style =  MaterialTheme.typography.bodySmall,
                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                , fontSize = 15.sp) },
                            onClick = {
                                expandDropDown.value=false
                                avatar.value = null
                                if (user != null) {
                                    homeViewModel.updateUser(user.copy(avatar=""))
                                }
                            }
                        )

                    }
                }

                Spacer(modifier = Modifier.height(45.dp))
                Text("Full name",
                    fontSize = 13.sp,
                    color=Color(0xFF5C728A),
                    style = MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(horizontal = 8.dp)
                )
                ZiFocusTextField(
                    value = fullName.value,
                    onValueChange = {
                        fullName.value = it
                        isUpdated = true
                    },
                    placeHolder = "Type Full name",
                    modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(horizontal = 8.dp)

                )
                Spacer(modifier = Modifier.height(24.dp))
                Text("Username",
                    fontSize = 13.sp,
                    color=Color(0xFF5C728A),
                    style = MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(horizontal = 8.dp)
                )
                ZiFocusTextField(
                    value = userName.value,
                    onValueChange = {
                        userName.value = it
                        isUpdated = true
                    },
                    placeHolder = "Type Username",
                    modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(horizontal = 8.dp)
                )
                Spacer(modifier = Modifier.height(24.dp))
                Text("Email",
                    fontSize = 13.sp,
                    color=Color(0xFF5C728A),
                    style = MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(horizontal = 8.dp)
                )
                ZiFocusTextField(
                    value = user?.email?:"",
                    onValueChange = {
                    },
                    placeHolder = "Type Email",
                    modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(horizontal = 8.dp),
                    readOnly = true

                )
                Spacer(modifier = Modifier.height(24.dp))
                AnimatedVisibility(isUpdated){
                    Row(modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(horizontal = 8.dp)) {
                        Spacer(modifier = Modifier.weight(1f))
                        TextButton(onClick = {
                            fullName.value = user?.name?:""
                            userName.value = user?.userName?:""
                            isUpdated = false
                        }){
                            Text("Discard", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                color = Color(0xFF3b4958))
                        }
                        Spacer(modifier = Modifier.width(8.dp))
                        Button(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                            modifier = Modifier
                                .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp).width(56.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF495564)),
                            onClick = {
                                user?.copy(name = fullName.value, userName = userName.value)?.let { homeViewModel.updateUser(it){
                                    isUpdated=false
                                } }
                            }
                        ){
                            Text("Save", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                        }

                        Spacer(modifier = Modifier.width(8.dp))

                    }

                }

            }
        }
    }
}