/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param id 
 * @param email 
 * @param name 
 * @param username 
 * @param focus 
 * @param areasOfInterest 
 * @param role 
 * @param avatar 
 * @param status 
 * @param createdAt 
 */
@Serializable

data class User (

    @SerialName(value = "id") val id: kotlin.String? = null,

    @SerialName(value = "email") val email: kotlin.String? = null,

    @SerialName(value = "name") val name: kotlin.String? = null,

    @SerialName(value = "username") val username: kotlin.String? = null,

    @SerialName(value = "focus") val focus: kotlin.String? = null,

    @SerialName(value = "areasOfInterest") val areasOfInterest: kotlin.collections.List<kotlin.String>? = null,

    @SerialName(value = "role") val role: kotlin.String? = null,

    @SerialName(value = "avatar") val avatar: kotlin.String? = null,

    @SerialName(value = "status") val status: kotlin.String? = null,

    @SerialName(value = "createdAt") val createdAt: kotlin.String? = null

)

