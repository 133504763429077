package org.company.app.presentation.viewmodel


import androidx.compose.ui.graphics.ImageBitmap
import api.RegistrationApi
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import dev.icerock.moko.mvvm.viewmodel.ViewModel
import io.github.aakira.napier.Napier
import io.ktor.client.HttpClient
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import org.company.app.data.model.*
import org.company.app.data.model.LoginResponse
import org.company.app.data.model.User
import org.company.app.util.ApiResponse
import org.company.app.util.Constants
import org.company.app.util.safeRequest
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.readResourceBytes
import org.openapitools.client.models.*
import toImageBitmap

public data class RegisterUiState(
    val registerResponse: UserRegistrationResponse = UserRegistrationResponse(),
    val errorMessage: String? =null,
    var isError: Boolean = false,
    var updateUserResponse: UserUpdateResponse = UserUpdateResponse(),
    var otpVerified: Boolean=false,

    val workImage:  ImageBitmap?=null,
    val workImageFilled: ImageBitmap?=null,
    val personalImage: ImageBitmap?=null,
    val personalImageFilled: ImageBitmap?=null,
)


public class RegisterViewModel(val client: HttpClient): ViewModel() {
    private val _registerState = MutableStateFlow<RegisterUiState>(RegisterUiState())
    public val registerState: StateFlow<RegisterUiState> = _registerState.asStateFlow()




    @OptIn( InternalResourceApi::class)
    fun loadImages(){
        if (_registerState.value.workImage==null){
            viewModelScope.launch {
                try {
                    _registerState.update {
                        it.copy(personalImage = readResourceBytes("drawable/personal_image.png").toImageBitmap(),
                            workImage = readResourceBytes("drawable/work_image.png").toImageBitmap(),
                            workImageFilled = readResourceBytes("drawable/work_filled.png").toImageBitmap(),
                            personalImageFilled = readResourceBytes("drawable/personal_filled.png").toImageBitmap(),
                        )
                    }
                }catch (exception:Exception){}

            }
        }

    }

    override fun onCleared() {
        client.close()
    }
    fun register(email:String, password:String){
        viewModelScope.launch {
            val settings: Settings = Settings()
            when(val response =firebaseRegisterSafeRequest(email, password)){
                is ApiResponse.Success -> {
                    Constants.token =  response.body.idToken?:""
                    settings["access_token"] = response.body.idToken
                    registerApi(email)

                }
                is ApiResponse.Error.GenericError -> {
                    Napier.e("GenericError $response")
                    _registerState.update {
                        it.copy(registerResponse = UserRegistrationResponse(),
                            errorMessage = response.errorMessage,isError = true)
                    }
                }
                is ApiResponse.Error.HttpError -> {
                    Napier.e("HttpError $response")
                    val json: JsonElement = Json.parseToJsonElement(response.fireBaseMessage?:"Something went wrong")
                    val message: String = json.jsonObject["error"]?.jsonObject?.get("message")?.jsonPrimitive?.content ?: "Something went wrong"
                    _registerState.update {
                        it.copy(registerResponse = UserRegistrationResponse(),
                            errorMessage = message,isError = true)
                    }
                }
                is ApiResponse.Error.SerializationError -> {
                    Napier.e("SerializationError ${response.errorMessage}")
                    _registerState.update {
                        it.copy(registerResponse = UserRegistrationResponse(),
                            errorMessage = response.errorMessage,isError = true)
                    }
                }
            }



        }
    }
    fun updateUser(user: User,block: (UserRegistration)->Unit={}){
        val registrationApi = RegistrationApi()
        val settings = Settings()
        viewModelScope.launch {

            val response = registrationApi.updateUser(userUpdateRequest = UserUpdateRequest(
                userName = user.userName,
                name = user.name,
                focus = user.focus,
                role = user.role,
                areasOfInterest = user.areasOfInterest.firstOrNull(),
                avatar = user.avatar
            ),authorization = settings["access_token"])
            if (response.success){
                _registerState.update {
                    it.copy(updateUserResponse = response.body(),errorMessage = null,isError = false)
                }
                block(response.body().data?: UserRegistration())
            }
            else {
                _registerState.update {
                    it.copy(registerResponse = UserRegistrationResponse(),
                        errorMessage = response.body().message
                    )
                }


            }



        }


    }
    fun clearRegisterUIState(){
        _registerState.update {
            it.copy(registerResponse = UserRegistrationResponse(),errorMessage = null,isError = false)
        }
    }
    fun setOpenDialog(boolean: Boolean){
        _registerState.update {
            it.copy(isError = boolean, registerResponse = UserRegistrationResponse())
        }
    }
    suspend fun registerSafeRequest(email: String,password: String): ApiResponse<SignUpResponse, ErrorResponse> {
        return client.safeRequest<SignUpResponse, ErrorResponse> {
            url {
                method = HttpMethod.Post
                path("${Constants.env}/users/signup")
                setBody(SignUpRequest(email))
            }
        }
    }
    suspend fun registerApi(email: String){
        val registrationApi = RegistrationApi()
        val settings = Settings()
        val response = registrationApi.signupUser(userRegistrationRequest = UserRegistrationRequest(email = email),authorization = settings["access_token"])
        if (response.success){
            _registerState.update {
                it.copy(registerResponse = response.body(),errorMessage = null,isError = false)
            }
            sendOTPSafeRequest(email)
        } else {
            _registerState.update {
                it.copy(registerResponse = UserRegistrationResponse(),
                    errorMessage = response.body().message,isError = true)
            }
        }

    }


     fun verifyOtp(email: String,otp: Int,block:(VerifyOtpResponse)->Unit={}){
         viewModelScope.launch {
             when(val response =verifyOTPSafeRequest(email, otp)){
                 is ApiResponse.Success -> {
                     _registerState.update {
                         it.copy(otpVerified = response.body.verified?:false,errorMessage = null,isError = false)
                     }
                     block(response.body)
                 }
                 is ApiResponse.Error.GenericError -> {
                     Napier.e("GenericError $response")
                     _registerState.update {
                         it.copy(registerResponse = UserRegistrationResponse(),
                             errorMessage = response.errorMessage)
                     }
                 }
                 is ApiResponse.Error.HttpError -> {
                     Napier.e("HttpError $response")
                     _registerState.update {
                         it.copy(registerResponse = UserRegistrationResponse(),
                             errorMessage = response.errorMessage)
                     }
                 }
                 is ApiResponse.Error.SerializationError -> {
                     Napier.e("SerializationError ${response.errorMessage}")
                     _registerState.update {
                         it.copy(registerResponse = UserRegistrationResponse(),
                             errorMessage = response.errorMessage)
                     }
                 }
             }
         }

    }


    suspend fun firebaseRegisterSafeRequest(email: String,password: String): ApiResponse<FirebaseRegisterResponse, ErrorResponse> {
        return client.safeRequest<FirebaseRegisterResponse, ErrorResponse> {
            url {
                method = HttpMethod.Post
                url("https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${Constants.FirebaseKey}")
                setBody(FirebaseLoginRequest(email,password,true))
            }
        }
    }
    fun resendOtp(email: String){
        viewModelScope.launch {
            sendOTPSafeRequest(email)
        }
    }
    suspend fun sendOTPSafeRequest(email: String): ApiResponse<SendOtpResponse, ErrorResponse> {
        return client.safeRequest<SendOtpResponse, ErrorResponse> {
            url {
                method = HttpMethod.Post
                url("${Constants.env}/users/sendOTPForEmail")
                setBody(SendOtpRequest(email))
            }
        }
    }
    suspend fun verifyOTPSafeRequest(email: String,otp:Int): ApiResponse<VerifyOtpResponse, ErrorResponse> {
        return client.safeRequest<VerifyOtpResponse, ErrorResponse> {
            url {
                method = HttpMethod.Post
                url("${Constants.env}/users/verifyOTP")
                setBody(VerifyOtpRequest(email,otp))
            }
        }
    }
    suspend fun updateUserSafeRequest(user:User): ApiResponse<LoginResponse, ErrorResponse> {
        return client.safeRequest<LoginResponse, ErrorResponse> {
            url {
                method = HttpMethod.Put
                path("${Constants.env}/users/updateUser")
                setBody(user)
            }
        }
    }

}