package org.company.app.data.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class User(
    @SerialName("id"              ) var id              : String?           = null,
    @SerialName("status"          ) var status          : String?           = null,
    @SerialName("name"            ) var name            : String?           = null,
    @SerialName("email"           ) var email           : String?           = null,
    @SerialName("focus"           ) var focus           : String?           = null,
    @SerialName("areasOfInterest" ) var areasOfInterest : ArrayList<String> = arrayListOf(),
    @SerialName("role"            ) var role            : String?           = null,
    @SerialName("avatar"          ) var avatar          : String?           = null,
    @SerialName("createdAt"       ) var createdAt       : String?           = null,
    @SerialName("userName"        ) var userName        : String?           = null,
    @SerialName("isEmailVerified" ) var isEmailVerified : Boolean?          = null,
    val userPageId: String? = null
)