package org.company.app.data.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Category(
    @SerialName("title" ) var title : String? = null,
    @SerialName("color" ) var color : Long? = null
)


