package org.company.app.presentation.component


import androidx.compose.foundation.Canvas
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.DropdownMenuItem
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.outlined.FormatAlignLeft
import androidx.compose.material.icons.automirrored.outlined.FormatAlignRight
import androidx.compose.material.icons.filled.Abc
import androidx.compose.material.icons.filled.Circle
import androidx.compose.material.icons.filled.FormatIndentIncrease
import androidx.compose.material.icons.filled.TextFormat
import androidx.compose.material.icons.outlined.*
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Outline
import androidx.compose.ui.graphics.drawOutline
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mohamedrejeb.richeditor.model.RichTextState


@Composable
fun RichTextStyleRow(
    modifier: Modifier = Modifier,
    state: RichTextState,
) {
    LazyRow(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
    ) {
        item {
            RichTextStyleButton(
                onClick = {
                    state.addParagraphStyle(
                        ParagraphStyle(
                            textAlign = TextAlign.Left,
                        )
                    )
                },
                isSelected = state.currentParagraphStyle.textAlign == TextAlign.Left,
                icon = Icons.AutoMirrored.Outlined.FormatAlignLeft,
            )
        }

        item {
            RichTextStyleButton(
                onClick = {
                    state.addParagraphStyle(
                        ParagraphStyle(
                            textAlign = TextAlign.Center
                        )
                    )
                },
                isSelected = state.currentParagraphStyle.textAlign == TextAlign.Center,
                icon = Icons.Outlined.FormatAlignCenter
            )
        }

        item {
            RichTextStyleButton(
                onClick = {
                    state.addParagraphStyle(
                        ParagraphStyle(
                            textAlign = TextAlign.Right
                        )
                    )
                },
                isSelected = state.currentParagraphStyle.textAlign == TextAlign.Right,
                icon = Icons.AutoMirrored.Outlined.FormatAlignRight
            )
        }

        item {
            RichTextStyleButton(
                onClick = {
                    state.toggleSpanStyle(
                        SpanStyle(
                            fontWeight = FontWeight.Bold
                        )
                    )
                },
                isSelected = state.currentSpanStyle.fontWeight == FontWeight.Bold,
                icon = Icons.Outlined.FormatBold
            )
        }

        item {
            RichTextStyleButton(
                onClick = {
                    state.toggleSpanStyle(
                        SpanStyle(
                            fontStyle = FontStyle.Italic
                        )
                    )
                },
                isSelected = state.currentSpanStyle.fontStyle == FontStyle.Italic,
                icon = Icons.Outlined.FormatItalic
            )
        }

        item {
            RichTextStyleButton(
                onClick = {
                    state.toggleSpanStyle(
                        SpanStyle(
                            textDecoration = TextDecoration.Underline
                        )
                    )
                },
                isSelected = state.currentSpanStyle.textDecoration?.contains(TextDecoration.Underline) == true,
                icon = Icons.Outlined.FormatUnderlined
            )
        }

        item {
            RichTextStyleButton(
                onClick = {
                    state.toggleSpanStyle(
                        SpanStyle(
                            textDecoration = TextDecoration.LineThrough
                        )
                    )
                },
                isSelected = state.currentSpanStyle.textDecoration?.contains(TextDecoration.LineThrough) == true,
                icon = Icons.Outlined.FormatStrikethrough
            )
        }

        item {
            RichTextStyleButton(
                onClick = {
                    state.toggleSpanStyle(
                        SpanStyle(
                            fontSize = 28.sp
                        )
                    )
                },
                isSelected = state.currentSpanStyle.fontSize == 28.sp,
                icon = Icons.Outlined.FormatSize
            )
        }
        /*
               item {
                   RichTextStyleButton(
                       onClick = {
                           state.toggleSpanStyle(
                               SpanStyle(
                                   color = Color.Red
                               )
                           )
                       },
                       isSelected = state.currentSpanStyle.color == Color.Red,
                       icon = Icons.Filled.Circle,
                       tint = Color.Red
                   )
               }

               item {
                   RichTextStyleButton(
                       onClick = {
                           state.toggleSpanStyle(
                               SpanStyle(
                                   background = Color.Yellow
                               )
                           )
                       },
                       isSelected = state.currentSpanStyle.background == Color.Yellow,
                       icon = Icons.Outlined.Circle,
                       tint = Color.Yellow
                   )
               }
               */

        item {

            // First list: Gray, Light Yellow, Yellow, Orange
            val firstColorList = listOf(
                Color(0xFFf5f6f8), // Gray
                Color(0xFFfff9b1), // Light Yellow
                Color(0xFFf5d127), // Yellow
                Color(0xFFfe9d47)  // Orange
            )

// Second list: Light Green, Green, Dark Green, Cyan
            val secondColorList = listOf(
                Color(0xFFd4f693), // Light Green
                Color(0xFFc9df56), // Green
                Color(0xFF94d175), // Dark Green
                Color(0xFF67c6c0)  // Cyan
            )

// Third list: Light Pink, Pink, Violet, Red
            val thirdColorList = listOf(
                Color(0xFFfecedf), // Light Pink
                Color(0xFFea94ba), // Pink
                Color(0xFFc5a1d2), // Violet
                Color(0xFFf0939c)  // Red
            )

// Fourth list: Light Blue, Blue, Dark Blue, Black
            val fourthColorList = listOf(
                Color(0xFFa7ccf5), // Light Blue
                Color(0xFF6dd8fa), // Blue
                Color(0xFF9daaff), // Dark Blue
                Color(0xFF000000)  // Black
            )


            val colorSelected = remember { mutableStateOf(Color(0xFF000000)) }
            val dropdownExpanded = remember { mutableStateOf(false) }
            Box {
                val borderColor = Color.Gray // Define the color of the border
                val borderWidth = 0.5.dp // Define the width of the border
                RichTextStyleButton(
                    onClick = {
                        dropdownExpanded.value=true
                    },
                    isSelected = state.currentSpanStyle.color == colorSelected.value,
                    icon = Icons.Filled.Circle,
                    tint = colorSelected.value
                )
                DropdownMenu(
                    expanded = dropdownExpanded.value,
                    onDismissRequest = { dropdownExpanded.value = false },
                    modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                ){
                    Column {
                        Row {
                            firstColorList.forEach { color ->
                                Canvas(modifier = Modifier.size(40.dp).padding(8.dp).clickable { dropdownExpanded.value = false
                                    colorSelected.value = color
                                    state.addSpanStyle(
                                        SpanStyle(
                                            color = color
                                        )
                                    ) }, onDraw = {
                                    drawCircle(
                                        color = borderColor,
                                        style = Stroke(width = borderWidth.toPx()),
                                        center = Offset(size.width / 2, size.height / 2),
                                        radius = size.width / 2
                                    )
                                    // Draw the original circle
                                    drawCircle(
                                        color = color,
                                        center = Offset(size.width / 2, size.height / 2),
                                        radius = size.width / 2 - borderWidth.toPx()
                                    )

                                })

                            }
                        }
                        Spacer(modifier=Modifier.height(8.dp))
                        Row {

                            secondColorList.forEach { color ->
                                Canvas(modifier = Modifier.size(40.dp).padding(8.dp).clickable { dropdownExpanded.value = false
                                    colorSelected.value = color
                                    state.addSpanStyle(
                                        SpanStyle(
                                            color = color
                                        )
                                    ) }, onDraw = {

                                    drawCircle(
                                        color = borderColor,
                                        style = Stroke(width = borderWidth.toPx()),
                                        center = Offset(size.width / 2, size.height / 2),
                                        radius = size.width / 2
                                    )
                                    // Draw the original circle
                                    drawCircle(
                                        color = color,
                                        center = Offset(size.width / 2, size.height / 2),
                                        radius = size.width / 2 - borderWidth.toPx()
                                    )

                                })

                            }
                        }
                        Spacer(modifier=Modifier.height(8.dp))
                        Row {
                            thirdColorList.forEach { color ->
                                Canvas(modifier = Modifier.size(40.dp).padding(8.dp).clickable { dropdownExpanded.value = false
                                    colorSelected.value = color
                                    state.addSpanStyle(
                                        SpanStyle(
                                            color = color
                                        )
                                    ) }, onDraw = {
                                    drawCircle(
                                        color = borderColor,
                                        style = Stroke(width = borderWidth.toPx()),
                                        center = Offset(size.width / 2, size.height / 2),
                                        radius = size.width / 2
                                    )
                                    // Draw the original circle
                                    drawCircle(
                                        color = color,
                                        center = Offset(size.width / 2, size.height / 2),
                                        radius = size.width / 2 - borderWidth.toPx()
                                    )

                                })

                            }
                        }
                        Spacer(modifier=Modifier.height(8.dp))
                        Row {
                            fourthColorList.forEach { color ->
                                Canvas(modifier = Modifier.size(40.dp).padding(8.dp).clickable { dropdownExpanded.value = false
                                    colorSelected.value = color
                                    state.addSpanStyle(
                                        SpanStyle(
                                            color = color
                                        )
                                    ) }, onDraw = {
                                    drawCircle(
                                        color = borderColor,
                                        style = Stroke(width = borderWidth.toPx()),
                                        center = Offset(size.width / 2, size.height / 2),
                                        radius = size.width / 2
                                    )
                                    // Draw the original circle
                                    drawCircle(
                                        color = color,
                                        center = Offset(size.width / 2, size.height / 2),
                                        radius = size.width / 2 - borderWidth.toPx()
                                    )

                                })

                            }
                        }
                    }


                }
            }
        }

        item {
            Box(
                Modifier
                    .height(24.dp)
                    .width(1.dp)
                    .background(Color(0xFF393B3D))
            )
        }

        item {
            RichTextStyleButton(
                onClick = {
                    state.toggleUnorderedList()
                },
                isSelected = state.isUnorderedList,
                icon = Icons.Outlined.FormatListBulleted,
            )
        }

        item {
            RichTextStyleButton(
                onClick = {
                    state.toggleOrderedList()
                },
                isSelected = state.isOrderedList,
                icon = Icons.Outlined.FormatListNumbered,
            )
        }

        item {
            val fontSizes = (10..30).toList()
            val selectedFontSize = remember { mutableStateOf(fontSizes[0]) }
            val dropdownExpanded = remember { mutableStateOf(false) }
            Box {

                RichTextStyleButton(
                    onClick = {
                       dropdownExpanded.value=true
                       /* state.addSpanStyle(
                            SpanStyle(
                                color = Color.Black,
                                fontSize = state.currentSpanStyle.fontSize
                            )
                        ) */

                    },
                    isSelected = false,
                    icon =  Icons.Filled.TextFormat,
                )
                DropdownMenu(
                    expanded = dropdownExpanded.value,
                    onDismissRequest = { dropdownExpanded.value = false },
                    modifier = Modifier.align(Alignment.TopEnd).background(color = Color.White,shape = RoundedCornerShape(10.dp)).padding(12.dp)
                ){
                    fontSizes.forEach { size ->
                        DropdownMenuItem(
                            onClick = {
                                selectedFontSize.value = size
                                dropdownExpanded.value = false
                                state.addSpanStyle(
                                    SpanStyle(
                                        fontSize = size.sp
                                    )
                                )
                            }
                        ) {
                            Text(text = "$size")
                        }
                    }
                }
            }

        }

        item {
            Box(
                Modifier
                    .height(24.dp)
                    .width(1.dp)
                    .background(Color(0xFF393B3D))
            )
        }

        item {
            RichTextStyleButton(
                onClick = {
                    state.toggleCodeSpan()
                },
                isSelected = state.isCodeSpan,
                icon = Icons.Outlined.Code,
            )
        }
    }
}