package org.company.app.presentation.ui.onBoarding

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowBack
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.ban.otptextfield.OtpTextField
import kotlinx.coroutines.delay

import org.company.app.presentation.viewmodel.RegisterViewModel
import org.company.app.theme.AppTheme
import org.koin.compose.koinInject

class OtpVerificationScreen(val email:String,val nextWithRouter: () -> Unit={}) : Screen {
    @Composable
    override fun Content() {
        OtpVerificationScreen()
    }
    @OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
    @Composable
    fun OtpVerificationScreen() {
        AppTheme {
            var otp by remember { mutableStateOf("") }
            var resendOtp by remember { mutableStateOf(false) }
            val otpClickable = remember { mutableStateOf(true) }
            val navigator = LocalNavigator.currentOrThrow
            val viewModel = koinInject<RegisterViewModel>()

            var timer by rememberSaveable { mutableStateOf(0L) }
            val started = remember { mutableStateOf(false) }
            var otpClicked by remember { mutableStateOf(false) }

            LaunchedEffect(started.value) {
                while (started.value) {
                    delay(200L)
                    timer += 200L
                    if ((timer/1000).toInt() ==10){
                        started.value=false
                        otpClickable.value=true
                    }
                }
            }
            if (resendOtp){
                viewModel.resendOtp(email)
                resendOtp=false
            }
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(16.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {

                Text(
                    text = "Check your mail                       ",
                    fontSize = 32.sp,
                    textAlign = TextAlign.Start,
                    style = MaterialTheme.typography.bodyLarge,
                    fontFamily = MaterialTheme.typography.bodyLarge.fontFamily,
                    color = Color(0xFF03264A),
                )

                Text(
                    text = "We have sent a verification code to ${email},\ntype the code below to continue",
                    modifier = Modifier.padding(top = 12.dp),
                    textAlign = TextAlign.Start,
                    style = MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    color = Color(0xFF5C728A),
                    fontSize = 17.sp,
                )


                Spacer(modifier = Modifier.height(32.dp))



                OtpTextField(
                    otpText = otp,
                    onOtpTextChange = { value, otpInputFilled ->
                        otp = value
                    }
                )



                Spacer(modifier = Modifier.height(32.dp))
                Button(
                    onClick = {
                        /* Verify OTP */
                        otpClicked = true
                        viewModel.verifyOtp(email,otp.toInt()){
                            navigator.push(WhatWeCallYouScreen(nextWithRouter))
                            viewModel.clearRegisterUIState()
                        }
                    },
                    shape = RoundedCornerShape(10.dp),
                    modifier = Modifier.width(213.dp).height(50.dp),
                ) {
                    Text(text = "Verify", color = Color(0xFFFAFAFA),
                        fontFamily = MaterialTheme.typography.displayMedium.fontFamily,
                        style = MaterialTheme.typography.displayMedium,
                        fontSize = 17.sp)
                }

                Text(
                    text = buildAnnotatedString {
                        append("Didn’t receive the OTP? ")
                        withStyle(
                            SpanStyle(
                                color = if (otpClickable.value) MaterialTheme.colorScheme.primaryContainer else Color(0xFF5C728A),
                            )
                        ) {
                            append("Resend${if (otpClickable.value==false) " (${10-(timer/1000)})" else ""}")
                        }

                    },
                    modifier = Modifier.padding(top = 24.dp).clickable(enabled = otpClickable.value) {
                        resendOtp=true
                        started.value=true
                        otpClickable.value=false
                    },
                    textAlign = TextAlign.Start,
                    style = MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    fontSize = 14.sp,
                    color = Color(0xFF5C728A).copy(0.6f)
                )

                OutlinedButton(
                    onClick = {
                        navigator.pop()
                    },
                    shape = RoundedCornerShape(10.dp),
                    modifier = Modifier.align(Alignment.CenterHorizontally).padding(top = 24.dp)
                ) {
                    Icon(Icons.Default.ArrowBack,null, tint = Color.Black)
                    Text(text = "Back", color = Color.Black, style = MaterialTheme.typography.bodySmall)
                }

                AnimatedVisibility(otpClicked){
                    CircularProgressIndicator()
                }


            }
        }

    }

}


