package org.company.app.data.model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Links(
    @SerialName("git")
    val git: String? = null,
    @SerialName("html")
    val html: String? = null,
    @SerialName("self")
    val self: String? = null
)