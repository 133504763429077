/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param emails 
 * @param sharedLink 
 * @param boardId 
 */
@Serializable

data class BoardMemberInviteRequest (

    @SerialName(value = "emails") val emails: kotlin.collections.List<kotlin.String>? = null,

    @SerialName(value = "sharedLink") val sharedLink: kotlin.String? = null,

    @SerialName(value = "boardId") val boardId: kotlin.String? = null

)

