@file:OptIn(ExperimentalComposeUiApi::class)

package org.company.app.presentation.ui.carddetails

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.animation.slideInHorizontally
import androidx.compose.animation.slideOutHorizontally
import androidx.compose.foundation.*
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.DropdownMenuItem
import androidx.compose.material.ExperimentalMaterialApi
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.filled.*
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.*
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.key.*
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.darkrockstudios.libraries.mpfilepicker.FilePicker
import com.mohamedrejeb.calf.core.LocalPlatformContext
import com.mohamedrejeb.calf.picker.FilePickerFileType
import com.mohamedrejeb.calf.picker.FilePickerSelectionMode
import com.mohamedrejeb.calf.picker.rememberFilePickerLauncher
import com.mohamedrejeb.richeditor.annotation.ExperimentalRichTextApi
import com.mohamedrejeb.richeditor.model.RichTextState
import com.mohamedrejeb.richeditor.model.rememberRichTextState
import com.mohamedrejeb.richeditor.ui.BasicRichTextEditor
import com.moriatsushi.insetsx.ExperimentalSoftwareKeyboardApi
import com.seiko.imageloader.rememberImagePainter
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import multiplatform_app.composeapp.generated.resources.*
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.add_blocker
import multiplatform_app.composeapp.generated.resources.add_comment
import org.company.app.data.model.*
import org.company.app.openUrl
import org.company.app.platformName
import org.company.app.presentation.component.CustomAlertDialog
import org.company.app.presentation.component.RichTextStyleRow
import org.company.app.presentation.component.ZiFocusTextField
import org.company.app.presentation.ui.board.formatDateString
import org.company.app.presentation.ui.board.menu.slide.SlideMenu
import org.company.app.presentation.utils.*
import org.company.app.presentation.viewmodel.HomeViewModel
import org.company.app.theme.AppTheme
import org.company.app.util.Constants
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject
import org.openapitools.client.models.*
import org.openapitools.client.models.Comment
import org.openapitools.client.models.CreateTaskRequest
import kotlin.time.Duration


class CardDetailsScreen(val navigateBackFromCardDetails: () -> Unit={}) : Screen {
    @OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
    @Composable
    override fun Content() {
        val windowSizeClass = calculateWindowSizeClass()
        val isExpandedScreen = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
        val navigator = LocalNavigator.currentOrThrow
        val viewModel= koinInject<HomeViewModel>()
        val homeState = viewModel.homeState.collectAsState()
        if (homeState.value.navigateToHome){
            navigator.pop()
            viewModel.navigateToHome()
        }

            AppTheme {
                CardDetailsRoute(isExpandedScreen = isExpandedScreen,
                    onCancelClick = {
                        if (viewModel.homeState.value.selectedWorkspaceId?.isNotEmpty() == true) viewModel.getWorkspaceTasks()
                        viewModel.homeState.value.subTasks.clear()
                        if (platformName()=="Web") navigateBackFromCardDetails()
                        navigator.pop()


                    })
            }



    }

}


@OptIn(ExperimentalSoftwareKeyboardApi::class)
@Composable
fun CardDetailsRoute(
    isExpandedScreen: Boolean,
    modifier: Modifier = Modifier,
    onCancelClick: () -> Unit,
) {

    Surface(
        modifier = modifier.fillMaxSize(),
    ) {

        Scaffold(
            topBar = {

            },
        ) {



                TaskManagementScreen(onCancelClick)

            /*   DesktopCardDetailsContent(
                   expandedLeftScrollState,
                   expandedRightScrollState,
                   onCancelClick,
                   viewModel
               ) */

        }
    }
}
@OptIn(ExperimentalMaterial3Api::class, ExperimentalComposeUiApi::class, ExperimentalResourceApi::class,
    ExperimentalRichTextApi::class
)
@Composable
fun TaskManagementScreen( onCancelClick: () -> Unit) {
    val viewModel= koinInject<HomeViewModel>()
    val homeState by viewModel.homeState.collectAsState()
    val taskDetails :TaskItem? = homeState.task
    val selectedProject = homeState.selectedProject
    val user = homeState.user
    val taskName = remember { mutableStateOf(taskDetails?.name) }
    val description = remember { mutableStateOf(taskDetails?.description) }
    taskName.value = taskDetails?.name
    description.value = taskDetails?.description
    val basicRichTextState = rememberRichTextState()
    basicRichTextState.setConfig(
        linkColor = Color.Blue,
        linkTextDecoration = TextDecoration.Underline,
        codeColor = Color.Yellow,
        codeBackgroundColor = Color.Transparent,
        codeStrokeColor = Color.LightGray,
    )
    LaunchedEffect(Unit){
        basicRichTextState.setHtml(taskDetails?.description?:"")
    }
    val addComment = remember { mutableStateOf(false) }
    val addBlocker = remember { mutableStateOf(false) }
    val statusChange = remember { mutableStateOf(false) }
    val priortyChange = remember { mutableStateOf(false) }
    val assignToMember = remember { mutableStateOf(false) }
    val changeCategory = remember { mutableStateOf(false) }

    val customCategory = remember { mutableStateOf(false) }
    val customCategoryToEdit = remember { mutableStateOf(Category()) }
    val categorySelectedtitle = remember { mutableStateOf(customCategoryToEdit.value.title) }
    val selectedColor = remember { mutableStateOf(customCategoryToEdit.value.color) }


    val isFocused = remember { mutableStateOf(false) }
    val hasFocus = remember { mutableStateOf(false) }
    val isCaptured = remember { mutableStateOf(false) }
    val isDropdownMenuExpanded = remember { mutableStateOf(false) }
    val isTransferToDropdownMenuExpanded = remember { mutableStateOf(false) }
    val isDeleteTaskDialogVisible = remember { mutableStateOf(false) }


    val focusManager = LocalFocusManager.current


    // for categories
    val searchText by viewModel.searchText.collectAsState() // Query for SearchBar
    val categoriesList by viewModel.categories.collectAsState()




    val date = mutableStateOf("")
    val time = mutableStateOf("")
    if (taskDetails?.createdAt!=null){
        date.value = taskDetails.createdAt?.substring(0, 10)?:""
        time.value = taskDetails.createdAt?.substring(11, 16)?:""
    }

    val dateSelected =rememberDatePickerState(yearRange = 2023..2050,)
    val datePicker = remember { mutableStateOf(false) }
    var dueDate by remember { mutableStateOf(taskDetails?.dueDate) }

    if (datePicker.value){
        DatePickerDialog(onDismissRequest = {datePicker.value=false}, confirmButton = {
            TextButton(onClick = {
                dueDate = dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().dateTimeToString()
                datePicker.value = false
                viewModel.updateTask(taskDetails?.copy(dueDate = dueDate)?: TaskItem()){
                    viewModel.showSnackBar("Task Due date updated")
                }
            }) {
                Text("Confirm",
                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                    style =  MaterialTheme.typography.bodySmall
                    , fontSize = 15.sp, color = MaterialTheme.colorScheme.primaryContainer)
            }
        }, colors = DatePickerDefaults.colors(containerColor = Color.White)){
            DatePicker(
                state = dateSelected,
                modifier = Modifier.wrapContentHeight().wrapContentWidth(),
                colors = DatePickerDefaults.colors(selectedDayContainerColor = MaterialTheme.colorScheme.primaryContainer, todayDateBorderColor = MaterialTheme.colorScheme.primaryContainer, todayContentColor = MaterialTheme.colorScheme.primaryContainer)
            )
        }
    }

    var timer by rememberSaveable { mutableStateOf(0L) }
    val started = remember { mutableStateOf(false) }

    LaunchedEffect(started.value) {
        while (started.value) {
            delay(200L)
            timer += 200L
        }
    }



    val scope = rememberCoroutineScope()
    val context = LocalPlatformContext.current


    val pickerLauncher = rememberFilePickerLauncher(
        type = FilePickerFileType.Custom(listOf("application/pdf","image/*")),
        selectionMode = FilePickerSelectionMode.Multiple,
        onResult = { files ->
            scope.launch {
                try {
                    files.forEach { file ->
                        // Do something with the selected file
                        // You can get the ByteArray of the file
                        viewModel.uploadFile(file,{},context)
                    }
                }catch (_:Exception){}

            }
        }
    )

    val showResolved = remember { mutableStateOf(false) }
    val showImage = remember { mutableStateOf(false) }
    val imageUrl = remember { mutableStateOf("") }
    val blockerToDoStatus = viewModel.homeState.value.selectedProject?.taskStatusDetails?.firstOrNull()?.id
    val blockerResolvedStatus = viewModel.homeState.value.selectedProject?.taskStatusDetails?.lastOrNull()?.id

    var fibonacci by remember { mutableStateOf(true) }
    if (!taskDetails?.capacityType.isNullOrEmpty()){
        if (taskDetails?.capacityType=="fibonacci") fibonacci = true
        else if (taskDetails?.capacityType=="size") fibonacci = false
    }





    if (homeState.comment!=null){
        addComment.value=false
        homeState.comment=null
    }
    if (homeState.blocker!=null){
        addBlocker.value=false
        homeState.blocker=null
    }

    if (customCategory.value) {
        AlertDialog(modifier = Modifier.width(250.dp),containerColor = Color(0xFFFAFAFA),
            onDismissRequest = { customCategory.value=false },
            properties = DialogProperties(
                dismissOnClickOutside = true,
                dismissOnBackPress = true,
                usePlatformDefaultWidth = false

            ),
            title = {  Row {
                Spacer(modifier = Modifier.weight(1f))
                Image(painterResource(Res.drawable.x_cross),contentDescription = null,modifier=Modifier.clickable { customCategory.value=false })
            } },
            text = {

                Column {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Box {
                            Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                drawCircle(Color(selectedColor.value?:0))
                            })

                        }

                        Text(categorySelectedtitle.value?:"", fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A),
                            modifier = Modifier.padding(start = 4.dp))
                        Spacer(modifier = Modifier.weight(1f))


                    }
                    Spacer(modifier = Modifier.height(18.dp))
                    ZiFocusTextField(value = categorySelectedtitle.value?:"", onValueChange = {
                        categorySelectedtitle.value = it
                    }, placeHolder = "Title")
                    Spacer(modifier = Modifier.height(18.dp))
                    Row {
                        Box(modifier = Modifier.clickable { selectedColor.value= 0xFFA98EDA}) {
                            Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                drawCircle(Color(0xFFA98EDA))
                            })
                            if (selectedColor.value==0xFFA98EDA)
                            Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                Alignment.Center))
                        }

                        Spacer(modifier = Modifier.width(12.dp))
                        Box(modifier = Modifier.clickable { selectedColor.value= 0xFFFEB272}) {
                            Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                drawCircle(Color(0xFFFEB272))
                            })
                            if (selectedColor.value==0xFFFEB272)
                                Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                    Alignment.Center))
                        }
                        Spacer(modifier = Modifier.width(12.dp))
                        Box(modifier = Modifier.clickable { selectedColor.value= 0xFFFFDA6A}) {
                            Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                drawCircle(Color(0xFFFFDA6A))
                            })
                            if (selectedColor.value==0xFFFFDA6A)
                                Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                    Alignment.Center))
                        }
                        Spacer(modifier = Modifier.width(12.dp))
                        Box(modifier = Modifier.clickable { selectedColor.value= 0xFF79DFC1}) {
                            Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                drawCircle(Color(0xFF79DFC1))
                            })
                            if (selectedColor.value==0xFF79DFC1)
                                Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                    Alignment.Center))
                        }
                        Spacer(modifier = Modifier.width(12.dp))
                        Box(modifier = Modifier.clickable { selectedColor.value= 0xFF6EA8FE}) {
                            Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                drawCircle(Color(0xFF6EA8FE))
                            })
                            if (selectedColor.value==0xFF6EA8FE)
                                Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                    Alignment.Center))
                        }


                    }
                    Spacer(modifier = Modifier.height(18.dp))
                    Row {
                        Box(modifier = Modifier.clickable { selectedColor.value= 0xFF75B798}) {
                            Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                drawCircle(Color(0xFF75B798))
                            })
                            if (selectedColor.value==0xFF75B798)
                                Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                    Alignment.Center))
                        }

                        Spacer(modifier = Modifier.width(12.dp))
                        Box(modifier = Modifier.clickable { selectedColor.value= 0xFF6EDFF6}) {
                            Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                drawCircle(Color(0xFF6EDFF6))
                            })
                            if (selectedColor.value==0xFF6EDFF6)
                                Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                    Alignment.Center))
                        }
                        Spacer(modifier = Modifier.width(12.dp))
                        Box(modifier = Modifier.clickable { selectedColor.value= 0xFFEA868F}) {
                            Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                drawCircle(Color(0xFFEA868F))
                            })
                            if (selectedColor.value==0xFFEA868F)
                                Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                    Alignment.Center))
                        }
                        Spacer(modifier = Modifier.width(12.dp))
                        Box(modifier = Modifier.clickable { selectedColor.value= 0xFFADB5BD}) {
                            Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                drawCircle(Color(0xFFADB5BD))
                            })
                            if (selectedColor.value==0xFFADB5BD)
                                Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                    Alignment.Center))
                        }
                        Spacer(modifier = Modifier.width(12.dp))
                        Box(modifier = Modifier.clickable { selectedColor.value= 0xFFE685B5}) {
                            Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                drawCircle(Color(0xFFE685B5))
                            })
                            if (selectedColor.value==0xFFE685B5)
                                Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                    Alignment.Center))
                        }


                    }

                }


                },
            confirmButton = {
                Row(verticalAlignment = Alignment.CenterVertically) {
                    TextButton(onClick = {
                        customCategory.value=false
                    }){
                        Text("Discard", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                            color = Color(0xFF3b4958))
                    }
                    Spacer(modifier = Modifier.weight(1f))
                    Button(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                        modifier = Modifier
                            .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp).width(56.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF495564)),onClick = {
                            viewModel.updateTask(taskDetails?.copy(category = CategoryItem(title = categorySelectedtitle.value, color = selectedColor.value?:0))?: TaskItem()){
                                viewModel.updateCategory(CategoryItem(title = categorySelectedtitle.value, color = selectedColor.value?:0))
                            }
                            customCategory.value=false
                            changeCategory.value = false
                            viewModel.onSearchTextChange("")

                        }){
                        Text("Save", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                    }



                }

            }
        )
    }


    if (showImage.value) {
        AlertDialog(modifier = Modifier.fillMaxSize(0.8f),containerColor = Color.Transparent,
            onDismissRequest = { showImage.value=false },
            properties = DialogProperties(
                dismissOnClickOutside = true,
                dismissOnBackPress = true,
                usePlatformDefaultWidth = false
            ),
            title = {  Row {

                Spacer(modifier = Modifier.weight(1f))
                Icon(painter = painterResource(Res.drawable.x_cross),null, tint = Color.White,modifier=Modifier.clickable { showImage.value=false })

            } },
            text = { Image(rememberImagePainter(imageUrl.value),contentDescription = null,
                modifier = Modifier
                  //  .aspectRatio(16f/9f)
                    .fillMaxSize()
            ) },
            confirmButton = {

            }
        )
    }

    if (homeState.errorMessage!=null) {
        AlertDialog(
            onDismissRequest = {  },
            properties = DialogProperties(
                dismissOnClickOutside = false,
                dismissOnBackPress = true
            ),
            title = {  },
            text = { Text(homeState.errorMessage?:"Error") },
            confirmButton = {
                Button(
                    modifier = Modifier.fillMaxWidth(),
                    onClick = {
                        viewModel.clearUIState()

                    }
                ) {
                    Text("Dismiss")
                }
            }
        )
    }
    if (isDeleteTaskDialogVisible.value){
        CustomAlertDialog(onDelete = {
            viewModel.deleteTask(taskDetails?.id?:"")
            onCancelClick()
        }, onDismiss = {
            isDeleteTaskDialogVisible.value=false
        })
    }


    Box(modifier = Modifier.fillMaxSize()) {
        if (taskDetails==null){
            Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
                CircularProgressIndicator()
            }

        } else {
            Column(modifier = Modifier.fillMaxSize().background(MaterialTheme.colorScheme.background).clickable(onClick = {focusManager.clearFocus()}, interactionSource = MutableInteractionSource(),indication = null)) {

                Row {
                    TextButton(onClick = { onCancelClick() }, modifier = Modifier.padding(horizontal = 16.dp)){
                        Icon(Icons.AutoMirrored.Filled.ArrowBack,null, tint = MaterialTheme.colorScheme.primaryContainer)
                        Text(
                            text = "Back",
                            fontSize = 18.sp,
                            color = MaterialTheme.colorScheme.primaryContainer,
                            // style = MaterialTheme.typography.bodyMedium
                            style = MaterialTheme.typography.bodyMedium
                        )
                    }
                    Spacer(modifier = Modifier.weight(1f))
                    Box {
                        IconButton(
                            onClick = { isDropdownMenuExpanded.value = !isDropdownMenuExpanded.value }
                        ) {
                            Icon(
                                imageVector = Icons.Default.MoreVert,
                                contentDescription = "More Options"
                            )
                        }


                        DropdownMenu(
                            expanded = isDropdownMenuExpanded.value,
                            onDismissRequest = { isDropdownMenuExpanded.value = false },
                            modifier = Modifier.align(Alignment.TopEnd).background(color = Color.White)
                        ){

                            Box {

                                DropdownMenuItem(
                                    text = { Text("Transfer to",
                                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                        style =  MaterialTheme.typography.bodySmall,
                                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                        , fontSize = 15.sp) },
                                    onClick = {
                                        isTransferToDropdownMenuExpanded.value = true

                                    }
                                )


                                DropdownMenu(
                                    expanded = isTransferToDropdownMenuExpanded.value,
                                    onDismissRequest = { isTransferToDropdownMenuExpanded.value = false },
                                    modifier = Modifier.align(Alignment.CenterStart).background(color = Color.White),
                                ){
                                    homeState.projects.forEach {
                                        DropdownMenuItem(
                                            text = { Text("${it?.projectName}",
                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                , fontSize = 15.sp) },
                                            onClick = {
                                                isTransferToDropdownMenuExpanded.value = false
                                                isDropdownMenuExpanded.value = false
                                                viewModel.updateTask(taskDetails.copy(projectId = it?.id)?: TaskItem())
                                            }
                                        )
                                    }

                                }
                            }



                            DropdownMenuItem(
                                text = { Text("Delete",
                                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                    style =  MaterialTheme.typography.bodySmall,
                                    fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                    , fontSize = 15.sp) },
                                onClick = {
                                    isDropdownMenuExpanded.value = false
                                    isDeleteTaskDialogVisible.value=true
                                }
                            )

                        }
                    }
                }

                Row(modifier = Modifier.padding(start = 16.dp), verticalAlignment = Alignment.CenterVertically) {
                    Text(
                        text = "#${taskDetails?.taskNumber}",
                        fontSize = 14.sp,
                        color = Color(0xFF5C728A).copy(alpha = 0.6f),
                        style = MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    )
                    Text(
                        text = "Created on ${formatDateString(taskDetails?.createdAt?:"")}",
                        fontSize = 14.sp,
                        color = Color(0xFF5C728A).copy(alpha = 0.6f),
                        style = MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                        modifier = Modifier.padding(start = 24.dp)
                    )
                    Spacer(modifier = Modifier.weight(1f))
                    TextButton(onClick = {addComment.value=true}){
                        Image(painterResource(Res.drawable.add_comment),null)
                        Text(
                            text = "Add comment",
                            fontSize = 14.sp,
                            style = MaterialTheme.typography.bodySmall,
                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                            color = Color(0xFF5C728A),
                            modifier = Modifier.padding(start = 8.dp)
                        )
                    }
                    TextButton(onClick = {addBlocker.value=true}){
                        Image(painterResource(Res.drawable.add_blocker),null)
                        Text(
                            text = "Add blocker",
                            fontSize = 14.sp,
                            style = MaterialTheme.typography.bodySmall,
                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                            color = Color(0xFF5C728A),
                            modifier = Modifier.padding(start = 8.dp)
                        )
                    }



                    TextButton(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp), modifier = Modifier.defaultMinSize(1.dp,1.dp),
                        onClick = {
                            showResolved.value = true
                        }
                    ){
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            Text(
                                text = "Resolved",
                                fontSize = 14.sp,
                                style = MaterialTheme.typography.bodySmall,
                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                color = Color(0xFF5C728A),
                                modifier = Modifier.padding(start = 8.dp)
                            )
                            Box(
                                modifier = Modifier
                                    .background(Color.Transparent)
                                    .padding(horizontal = 10.dp, vertical = 0.dp)
                                    .border(BorderStroke(0.5f.dp, Color.Gray), shape = RoundedCornerShape(50))
                                    .alpha(0.5f),
                                contentAlignment = Alignment.Center
                            ) {
                                Text("${taskDetails?.blockers?.filter { it.taskStatus==blockerResolvedStatus }?.size}", fontSize = 12.sp,modifier = Modifier.padding(horizontal = 10.dp, vertical = 0.dp),fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF5C728A))

                            }
                            /*
                            Badge(containerColor = MaterialTheme.colorScheme.primaryContainer, contentColor = Color.White) {
                                Text("${taskDetails?.blockers?.filter { it.taskStatus=="Resolved" }?.size}", fontFamily = MaterialTheme.typography.bodySmall.fontFamily)
                            } */

                        }
                    }



                    // Spacer(modifier = Modifier.width(16.dp))


                }
                Row {

                    Column(modifier = Modifier.fillMaxWidth(0.6f).verticalScroll(rememberScrollState())) {
                        AutoSubmittingTextField(taskName, textStyle = MaterialTheme.typography.bodyMedium.copy(fontSize = 24.sp, color = Color(0xFF03264A), fontFamily = MaterialTheme.typography.bodyMedium.fontFamily), onClick = {
                            viewModel.updateTask(taskDetails.copy(name = taskName.value, description = basicRichTextState.toHtml()))
                        })


                      //  Spacer(modifier = Modifier.height(12.dp))


                        Row(modifier = Modifier.padding(start = 16.dp), verticalAlignment = Alignment.CenterVertically) {

                            Box {



                                TextButton(contentPadding = PaddingValues(), modifier = Modifier.defaultMinSize(1.dp,1.dp),
                                    onClick = { changeCategory.value = !changeCategory.value }
                                ) {
                                    Row {
                                        Canvas(modifier = Modifier.size(18.dp).padding(top = 4.dp), onDraw = {
                                            drawCircle(Color(taskDetails.category?.color?.toLong()?:0xFF9056F0))
                                        })

                                        Text(taskDetails.category?.title?:"Work", fontSize = 18.sp, style = MaterialTheme.typography.bodySmall,
                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A),
                                            modifier = Modifier.padding(start = 4.dp))
                                    }

                                }
                                DropdownMenu(
                                    expanded = changeCategory.value,
                                    onDismissRequest = { changeCategory.value = false },
                                    modifier = Modifier.background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp))
                                ){


                                    DockedSearchBar(
                                        query = searchText,//text showed on SearchBar
                                        onQueryChange = viewModel::onSearchTextChange, //update the value of searchText
                                        onSearch = viewModel::onSearchTextChange, //the callback to be invoked when the input service triggers the ImeAction.Search action
                                        active = true, //whether the user is searching or not
                                        onActiveChange = { viewModel.onToogleSearch() }, //the callback to be invoked when this search bar's active state is changed
                                        modifier = Modifier
                                            .width(250.dp)
                                            .padding(4.dp),
                                        placeholder = {
                                            Text(text = "Search or create new", fontSize = 15.sp,
                                                style = MaterialTheme.typography.bodySmall,
                                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily)
                                        },
                                        leadingIcon = {
                                            Icon(imageVector = Icons.Default.Search, contentDescription = null)
                                        },
                                        colors = SearchBarDefaults.colors(containerColor = Color(0xFFFAFAFA))
                                    ) {
                                        categoriesList.let {
                                            Column(
                                                modifier = Modifier
                                                    .fillMaxWidth()
                                                    .heightIn(max=250.dp)
                                                    .verticalScroll(state = rememberScrollState())
                                            ) {
                                                it.forEach { categpry ->
                                                    val interactionSource = remember { MutableInteractionSource() }
                                                    val isHovered = interactionSource.collectIsHoveredAsState().value
                                                    Box(modifier = Modifier.fillMaxWidth().hoverable(interactionSource)) {


                                                        TextButton(modifier = Modifier.padding(end = 24.dp),
                                                            onClick = {
                                                                viewModel.updateTask(taskDetails.copy(category = categpry)?: TaskItem())
                                                                changeCategory.value = false
                                                            }
                                                        ) {
                                                            Row(verticalAlignment = Alignment.CenterVertically) {
                                                                Canvas(modifier = Modifier.size(18.dp).padding(top = 4.dp), onDraw = {
                                                                    drawCircle(Color(categpry.color?.toLong()?:0))
                                                                })
                                                                Text(categpry.title?:"", fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A),
                                                                    modifier = Modifier.padding(start = 4.dp))
                                                                Spacer(modifier = Modifier.weight(1f))

                                                            }

                                                        }
                                                        if (isHovered){
                                                            // hamokshaa
                                                            Image(painterResource(Res.drawable.edit),contentDescription = null, modifier = Modifier.align(
                                                                Alignment.CenterEnd).clickable {
                                                                categorySelectedtitle.value = categpry.title
                                                                selectedColor.value = categpry.color?.toLong()
                                                                    customCategory.value = true

                                                            })
                                                        }

                                                    }


                                                }
                                               if (searchText.isNotEmpty()){

                                                           Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(horizontal = 12.dp).fillMaxWidth()) {
                                                               Canvas(modifier = Modifier.size(18.dp).padding(top = 4.dp), onDraw = {
                                                                   drawCircle(Color( 0xFF1BBEC1))
                                                               })
                                                               Text(searchText, fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                                                   fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A),
                                                                   modifier = Modifier.padding(start = 4.dp).fillMaxWidth(0.6f))
                                                               Spacer(modifier = Modifier.weight(1f))

                                                                   TextButton(onClick = {
                                                                       categorySelectedtitle.value = searchText
                                                                       selectedColor.value = 0xFF1BBEC1
                                                                       customCategory.value=true



                                                                   }){
                                                                       Text("Create", fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                                                           fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = MaterialTheme.colorScheme.primary,
                                                                       )
                                                                   }


                                                           }







                                               }


                                            }
                                        }
                                    }
                                }
                            }
                            Spacer(modifier=Modifier.width(32.dp))
                            //  Image(painterResource(MR.images.time), contentDescription = null)


                            if (taskDetails.assignedTo==user?.id){
                                if ((taskDetails.taskStatus!="TO_DO"&&taskDetails.startTime?.isNotEmpty() == true)||(taskDetails.taskStatus=="DONE"&&taskDetails.stopTime?.isNotEmpty()==true)){
                                    var now = Clock.System.now()
                                    val startTime: Instant = Instant.parse(taskDetails.startTime?:"" )

                                    var durationSinceThenState: MutableState<Duration> = remember { mutableStateOf(now - startTime) }
                                    if (taskDetails.taskStatus=="DONE") {
                                        val stopedTime: Instant = Instant.parse(taskDetails.stopTime?:"" )
                                        durationSinceThenState = remember { mutableStateOf(stopedTime - startTime) }

                                    }
                                    LaunchedEffect(durationSinceThenState.value.inWholeMinutes) {
                                        if (taskDetails.taskStatus!="DONE"){
                                            while (true) {
                                                now = Clock.System.now()
                                                durationSinceThenState.value = now - startTime
                                                delay(60000)
                                            }
                                        }


                                    }

                                    val formattedDuration = when {
                                        durationSinceThenState.value.inWholeMinutes.toInt() == 0 -> {
                                            "Just now"
                                        }
                                        durationSinceThenState.value.inWholeMinutes < 60 -> {
                                            val minutes = durationSinceThenState.value.inWholeMinutes
                                            "$minutes ${if (minutes > 1) "mins" else "min"}"
                                        }
                                        durationSinceThenState.value.inWholeHours < 24 -> {
                                            val hours = durationSinceThenState.value.inWholeHours
                                            "$hours ${if (hours > 1) "hours" else "hour"}" +
                                                    (if (durationSinceThenState.value.inWholeMinutes % 60 > 0) " ${durationSinceThenState.value.inWholeMinutes % 60} min" else "")
                                        }
                                        else -> {
                                            val days = durationSinceThenState.value.inWholeDays
                                            val hours = durationSinceThenState.value.inWholeHours % 24
                                          //  val minutes = durationSinceThenState.value.inWholeMinutes % 60
                                            val daysText = if (days > 0) "$days day" + (if (days > 1) "s" else "") else ""
                                            val hoursText = if (hours > 0) " $hours hour" + (if (hours > 1) "s" else "") else ""
                                          //  val minutesText = if (minutes > 0) " $minutes min" + (if (minutes > 1) "s" else "") else ""
                                           // "$daysText$hoursText$minutesText"
                                            "$daysText$hoursText"
                                        }
                                    }
                                    //  Image(painterResource(time_image.value), contentDescription = null)
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                        Image(painterResource(Res.drawable.time_),
                                            contentDescription = null,
                                            modifier=Modifier.size(16.dp))

                                        Text(
                                            formattedDuration,
                                            fontSize = 14.sp,
                                            // fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                                            style =  MaterialTheme.typography.bodySmall,
                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                            modifier = Modifier.padding(start = 5.dp),
                                            color = Color(0xFF5C728A)
                                        )
                                    }

                                }

                            }

                        }

                        Row(modifier = Modifier.padding(start = 16.dp), verticalAlignment = Alignment.CenterVertically){

                            Box {


                                TextButton(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                                    modifier = Modifier
                                        .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp)
                                        .width(100.dp)
                                    ,onClick = {statusChange.value=!statusChange.value}, colors = ButtonDefaults.textButtonColors(containerColor = when (taskDetails.taskStatus) {
                                        selectedProject?.taskStatusDetails?.firstOrNull()?.id -> Color(0xFFCFE2FF)
                                        selectedProject?.taskStatusDetails?.find { it.name== "In Progress"}?.id -> Color(0xFFFFF3CD)
                                        selectedProject?.taskStatusDetails?.lastOrNull()?.id -> Color(0xFFD1E7DD)
                                      //  else -> getRandomColorForColumns()
                                        else ->  Color(0xFFCFE2FF)
                                    })){
                                    Text(selectedProject?.taskStatusDetails?.find { it.id==taskDetails.taskStatus }?.name?:"To Do", style = MaterialTheme.typography.bodySmall, fontSize = 12.sp, color = Color(0xFF5C728A))
                                }

                                DropdownMenu(
                                    expanded = statusChange.value,
                                    onDismissRequest = { statusChange.value = false },
                                    modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                                ){
                                    StatusChoose(viewModel,taskDetails,statusChange)
                                }
                            }


                            Spacer(modifier = Modifier.width(32.dp))
                            Box {

                                //Collecting states from ViewModel
                                val searchText by viewModel.searchText.collectAsState() // Query for SearchBar
                                val countriesList by viewModel.boardMemberList.collectAsState()


                                TextButton(
                                    onClick = { assignToMember.value = !assignToMember.value }
                                ) {
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                       // Image(Icons.Default.Circle,null)
                                        if (taskDetails.assignedToDetails?.name==null){
                                            Image(painterResource(Res.drawable.assign_member_),modifier = Modifier.size(24.dp), contentDescription = null)
                                        } else {
                                            if (taskDetails.assignedToDetails?.avatar.isNullOrEmpty()){
                                                val name = taskDetails.assignedToDetails?.name?:""
                                                val words = name.split(" ")
                                                val initials = if (words.size > 1) {
                                                    words[0].take(1) + words[words.lastIndex].take(1)
                                                } else {
                                                    words[0].take(2)
                                                }.uppercase()
                                                TextButton(onClick = {},modifier = Modifier
                                                    .size(30.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                                    Text(
                                                        text = initials,
                                                        fontSize = 18.sp,
                                                        color = Color.White,
                                                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                        maxLines = 1,
                                                        overflow = TextOverflow.Ellipsis,
                                                        textAlign = TextAlign.Center
                                                    )
                                                }

                                            }
                                            else {
                                                Image(rememberImagePainter(taskDetails.assignedToDetails?.avatar?:""),modifier = Modifier
                                                    .size(30.dp)
                                                    .clip(CircleShape)
                                                    .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                            }
                                            Spacer(modifier = Modifier.width(6.dp))
                                            Text("${taskDetails.assignedToDetails?.name}", style = MaterialTheme.typography.bodySmall,
                                                fontSize = 14.sp, color = Color(0xFF5C728A),modifier = Modifier.padding(start = 4.dp))

                                        }

                                     }

                                }

                                DropdownMenu(
                                    expanded = assignToMember.value,
                                    onDismissRequest = { assignToMember.value = false },
                                    modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                                ){
                                    Text("Assign to", fontSize = 16.sp,
                                        style = MaterialTheme.typography.displayMedium
                                    )
                                    DockedSearchBar(
                                        query = searchText,//text showed on SearchBar
                                        onQueryChange = viewModel::onSearchTextChange, //update the value of searchText
                                        onSearch = viewModel::onSearchTextChange, //the callback to be invoked when the input service triggers the ImeAction.Search action
                                        active = true, //whether the user is searching or not
                                        onActiveChange = { viewModel.onToogleSearch() }, //the callback to be invoked when this search bar's active state is changed
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .padding(16.dp)
                                        ,
                                        placeholder = {
                                            Text(text = "Search members", fontSize = 15.sp, style = MaterialTheme.typography.bodySmall)
                                        },
                                        leadingIcon = {
                                            Icon(imageVector = Icons.Default.Search, contentDescription = null)
                                        },
                                        colors = SearchBarDefaults.colors(containerColor = Color(0xFFFAFAFA))
                                    ) {
                                        countriesList.let {
                                            Column(
                                                modifier = Modifier
                                                    .fillMaxWidth()
                                                    .heightIn(max=250.dp)
                                                    .verticalScroll(state = rememberScrollState())
                                            ) {
                                                it.forEach { member ->
                                                    TextButton(
                                                        onClick = {
                                                            taskDetails.copy(assignedTo = member?.id)
                                                                .let { it1 -> viewModel.updateTask(it1) }
                                                            assignToMember.value = false
                                                        }
                                                    ) {
                                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                                            if (member?.avatar.isNullOrEmpty()){
                                                                val name = member?.name?:""
                                                                val words = name.split(" ")
                                                                val initials = if (words.size > 1) {
                                                                    words[0].take(1) + words[words.lastIndex].take(1)
                                                                } else {
                                                                    words[0].take(2)
                                                                }.uppercase()
                                                                TextButton(onClick = {},modifier = Modifier
                                                                    .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                                                    Text(
                                                                        text = initials,
                                                                        fontSize = 16.sp,
                                                                        color = Color.White,
                                                                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                                        maxLines = 1,
                                                                        overflow = TextOverflow.Ellipsis,
                                                                        textAlign = TextAlign.Center
                                                                    )
                                                                }

                                                            }
                                                            else {
                                                                Image(
                                                                    rememberImagePainter(member?.avatar?:""),modifier = Modifier
                                                                        .size(35.dp)
                                                                        .clip(CircleShape)
                                                                        .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                                            }
                                                            Spacer(modifier = Modifier.width(6.dp))

                                                            Text(if (member?.id==user?.id) "${member?.name} - @Me" else member?.name?:"",
                                                                //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                style =  MaterialTheme.typography.bodySmall,
                                                                fontSize = 14.sp, color = Color.Black,modifier = Modifier.padding(start = 4.dp))
                                                        }

                                                    }

                                                }
                                            }
                                        }
                                    }



                                }
                            }





                            Spacer(modifier = Modifier.width(32.dp))

                            Box {

                                OutlinedButton(contentPadding = PaddingValues(),
                                    modifier = Modifier
                                        .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp),
                                    onClick = {priortyChange.value=!priortyChange.value},
                                    colors = ButtonDefaults.outlinedButtonColors(containerColor = Color.Transparent),
                                    border = BorderStroke(0.dp, Color.Transparent),){
                                    Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                                        if (taskDetails.priority==""){
                                            Image(painterResource(Res.drawable.priority_flag_),modifier = Modifier.size(24.dp), contentDescription = null)
                                        } else {
                                            taskDetails.priority?.let { priority ->
                                                when (priority) {
                                                    "Urgent" -> Res.drawable.urgent_flag
                                                    "Medium" -> Res.drawable.medium_flag
                                                    "Low" -> Res.drawable.low_flag
                                                    else -> Res.drawable.urgent_flag
                                                }
                                            }?.let {
                                                Image(
                                                    painter = painterResource(it), // use defaultBitmapImage if task.priority is null or the flag is null
                                                    contentDescription = null,
                                                    modifier = Modifier.size(24.dp)
                                                )
                                            }


                                            Text(taskDetails.priority?:"Urgent", color = Color(0xFF5C728A), style = MaterialTheme.typography.bodySmall, fontSize = 14.sp)

                                        }


                                    }

                                }
                                DropdownMenu(
                                    expanded = priortyChange.value,
                                    onDismissRequest = { priortyChange.value = false },
                                    modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                                ){
                                    PriorityChoose(viewModel,taskDetails,priortyChange)
                                }
                            }
                            Spacer(modifier = Modifier.width(32.dp))
                            TextButton(
                                onClick = { datePicker.value=true }
                            ) {
                                if (dueDate==null){
                                    Icon(painterResource(Res.drawable.due_datepicker_),modifier = Modifier.size(24.dp), contentDescription = null, tint = Color.Gray)
                                } else {
                                    Text(
                                        formatDateString(dueDate?:""),
                                        //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                        style =  MaterialTheme.typography.bodySmall, fontSize = 15.sp, color = Color(0xFF5C728A))
                                }


                            }
                            Spacer(modifier = Modifier.width(32.dp))
                            // capacity
                            Box {
                                var expanded by remember { mutableStateOf(false) }


                                val fibonacciOptions = listOf("Select","1 points",
                                    "2 points",
                                    "5 points",
                                    "10 points",
                                    "20 points",
                                    "50 points",
                                    "100 points",
                                )
                                val sizeOptions = listOf("Select","Small",
                                    "Medium",
                                    "Large",
                                    "X-Large",
                                    "XX-Large",
                                )
                                var selectedOption = remember { mutableStateOf(fibonacciOptions[0]) }



                                TextButton(onClick = {expanded=true}, contentPadding = PaddingValues(), modifier = Modifier.defaultMinSize(1.dp,1.dp)){
                                    Row {
                                        Text(taskDetails.capacityValue?:"Capacity", fontSize = 14.sp, style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily, color = Color(0xFF5C728A))
                                        Image(painterResource(Res.drawable.arrow),null, modifier = Modifier.rotate(90f))
                                    }
                                }

                                DropdownMenu(
                                    expanded = expanded,
                                    onDismissRequest = { expanded = false },
                                    modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                                ){
                                        Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(start = 8.dp)) {
                                            Text(text = "Fibonacci", style = MaterialTheme.typography.bodySmall,color = Color(0xFF5C728A), fontSize = 14.sp, modifier = Modifier.padding(end = 4.dp))
                                            Switch(modifier = Modifier.scale(0.7f),
                                                checked = fibonacci,
                                                onCheckedChange = {
                                                    fibonacci = it
                                                }
                                            )
                                        }

                                  HorizontalDivider()

                                    if (fibonacci){
                                        fibonacciOptions.filter { it!="Select" }.forEach { option ->
                                            DropdownMenuItem(onClick = {
                                                selectedOption.value = option
                                                viewModel.updateTask(taskDetails.copy(capacityType = "fibonacci", capacityValue = option))
                                                expanded = false
                                            }) {
                                                Text(text = option, fontSize = 14.sp,color = Color(0xFF5C728A),style = MaterialTheme.typography.bodySmall)
                                            }
                                        }
                                    } else {
                                        sizeOptions.filter { it!="Select" }.forEach { option ->
                                            DropdownMenuItem(onClick = {
                                                selectedOption.value = option
                                                viewModel.updateTask(taskDetails.copy(capacityType = "size", capacityValue = option))
                                                expanded = false
                                            }) {
                                                Text(text = option, fontSize = 14.sp,color = Color(0xFF5C728A),style = MaterialTheme.typography.bodySmall)
                                            }
                                        }
                                    }


                                }
                            }









                        }
                        Spacer(modifier = Modifier.height(16.dp))
                        AnimatedVisibility(isFocused.value){
                            Surface(shadowElevation = 3.dp, color = Color(0xFFFAFAFA), modifier = Modifier.padding(start = 16.dp)) {
                                RichTextStyleRow(
                                    modifier = Modifier.fillMaxWidth(),
                                    state = basicRichTextState,
                                )

                            }

                        }
                        Spacer(modifier = Modifier.height(8.dp))
                        Row {
                            Spacer(modifier = Modifier.width(16.dp))
                            Box(modifier = Modifier.background(if (isFocused.value)Color(0xFFedf3fc) else Color.Transparent).padding(if (isFocused.value)16.dp else 0.dp).fillMaxWidth()) {

                                if (basicRichTextState.annotatedString.text==""){
                                    Text("Type Description", fontSize = 18.sp, style = MaterialTheme.typography.bodySmall, fontWeight = FontWeight.Light,color = Color.Gray,modifier = Modifier.padding(start = 16.dp))
                                }

                                AutoSubmittingRicthTextEditor(basicRichTextState, onClick = {
                                    viewModel.updateTask(taskDetails.copy(name = taskName.value, description = if (basicRichTextState.annotatedString.text.isEmpty()) "" else basicRichTextState.toHtml())?: TaskItem())

                                }, textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 18.sp, color = Color(0xFF5C728A), fontWeight = FontWeight.Light), modifier = Modifier.onPreviewKeyEvent {
                                    when {
                                        (it.isCtrlPressed && it.key == Key.Minus && it.type == KeyEventType.KeyUp) -> {
                                            true
                                        }
                                        ( it.key == Key.Enter && (it.type == KeyEventType.KeyUp)) -> {
                                            isFocused.value = true
                                            hasFocus.value = true
                                            isCaptured.value= true
                                            true
                                        }
                                        else -> false
                                    }
                                }.onFocusChanged {
                                    isFocused.value = it.isFocused
                                    hasFocus.value = it.hasFocus
                                    isCaptured.value= it.isCaptured
                                }
                                )
                                /*
                                if (description.value==""){
                                    Text("Type Description", fontSize = 18.sp, style = MaterialTheme.typography.bodySmall, color = Color.Gray,modifier = Modifier.padding(start = 16.dp))
                                }
                                AutoSubmittingTextField(description, modifier = Modifier.onFocusChanged {
                                    isFocused.value = it.isFocused
                                    hasFocus.value = it.hasFocus
                                    isCaptured.value= it.isCaptured
                                }.fillMaxWidth(),textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 18.sp, color = Color(0xFF5C728A), fontFamily = MaterialTheme.typography.bodySmall.fontFamily), onClick = {
                                    viewModel.updateTask(taskDetails.copy(name = taskName.value, description = description.value))
                                }) */

                            }
                        }



                        /*
                        AutoSubmittingTextField(description, textStyle = TextStyle(fontSize = 18.sp, style = MaterialTheme.typography.bodySmall), onClick = {
                            viewModel.updateTask(taskDetails?.copy(name = taskName.value, description = description.value)?:Task(id=null))
                        }) */




                        Spacer(modifier = Modifier.height(32.dp))
                        SubTasks(viewModel,taskDetails)
                        Spacer(modifier = Modifier.height(24.dp))

                        Text("Attachments (${taskDetails.attachments?.size})",style =  MaterialTheme.typography.bodyLarge, fontSize = 18.sp,color = Color(0xFF5C728A), modifier = Modifier.padding(start = 16.dp))
                        Spacer(modifier = Modifier.height(4.dp))
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            Image(Icons.Default.AttachFile,null,modifier = Modifier.padding(start = 16.dp).clickable {

                                pickerLauncher.launch()
                            })

                            LazyRow(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(vertical = 12.dp)) {
                                items(taskDetails.attachments?: arrayListOf()){
                                    // outside image
                                    Box(modifier = Modifier.padding(start = 8.dp)) {
                                        Card(colors = CardDefaults.cardColors(containerColor = Color(0xFFFAFAFA)), modifier = Modifier.height(204.dp).width(206.dp).padding(start = 8.dp, bottom = 8.dp, end = 6.dp, top = 24.dp).clickable {
                                            if (it.contains(".pdf")){
                                                openUrl(it)
                                            } else {
                                                imageUrl.value = it
                                                showImage.value = true
                                            }
                                        },
                                            elevation = CardDefaults.cardElevation(defaultElevation = 4.dp)) {
                                            Column {
                                                if (it.contains(".pdf")){
                                                    Image(painterResource(Res.drawable.pdf_svgrepo_com),null,modifier = Modifier.weight(0.8f).fillMaxWidth(), contentScale = ContentScale.Crop)

                                                } else {
                                                    Image(rememberImagePainter(it),null,modifier = Modifier.weight(0.75f).fillMaxWidth(), contentScale = ContentScale.Crop)
                                                }
                                                Text(extractFileNameFromUrl(it), textAlign = TextAlign.Center,overflow = TextOverflow.Ellipsis, style =  MaterialTheme.typography.bodyMedium, fontSize = 14.sp,color = Color(0xFF5C728A),modifier = Modifier.weight(0.25f).padding(horizontal = 8.dp).padding(top = 10.dp), maxLines = 1)

                                            }
                                        }
                                        Image(painterResource(Res.drawable.delete),contentDescription = null, modifier = Modifier.align(
                                            Alignment.TopEnd).size(18.dp).clickable {
                                                taskDetails.attachments?.remove(it)
                                                viewModel.updateTask(taskDetails){
                                                    viewModel.showSnackBar("Attachment Deleted Successfully")
                                                }
                                            })
                                    }


                                    /*
                                    OutlinedButton(border = BorderStroke(1.dp, Color(0xFFD9D9D9)),onClick = {
                                        //  openUrl(it)
                                        if (it.contains(".pdf")){
                                            openUrl(it)
                                        } else {
                                            imageUrl.value = it
                                            showImage.value = true
                                        }

                                    },
                                        modifier = Modifier.padding(start = 8.dp)
                                            // .width(110.dp)
                                            .defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(vertical = 5.dp,horizontal = 8.dp)
                                    ){
                                        Icon(Icons.Default.FilePresent,null, tint = Color(0xFF5C728A))
                                        Text(extractFileNameFromUrl(it), color = Color(0xFF5C728A),
                                            // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                            //  maxLines = 1,
                                            style =  MaterialTheme.typography.bodySmall, fontSize = 14.sp, modifier = Modifier.padding(start = 8.dp))
                                    }
                                    */

                                }
                            }
                        }




                    }
                    Column(modifier = Modifier.fillMaxWidth()) {
                        if (addBlocker.value){
                            createBlocker(viewModel,taskDetails?.id,addBlocker)
                        }
                        if (addComment.value){
                            createComment(viewModel,taskDetails?.id,addComment)
                        }

                        LazyColumn {
                            items(taskDetails?.blockers?.toList()?.filter { it.taskStatus!=blockerResolvedStatus }?: listOf()){
                                Blocker(it,  name = it.assignedToDetails?.name?:"",
                                    onResolveClick = {
                                        viewModel.resolveBlocker(it.copy(taskStatus = if (it.taskStatus==blockerResolvedStatus) blockerToDoStatus else blockerResolvedStatus),
                                            blocker = it)
                                        viewModel.getTaskDetails(taskDetails?.id)
                                    }, onDeleteClick = {
                                        viewModel.deleteTask(it.id?:"", blocker = true)
                                    },viewModel

                                )
                            }
                            items(taskDetails?.comments?.data?.toList()?: listOf()){
                                Comment(it,viewModel
                                ) {
                                    viewModel.deleteComment(comment = it)
                                }

                            }

                        }
                    }

                }

            }
            AnimatedVisibility(
                enter = slideInHorizontally { it },
                exit = slideOutHorizontally { it },
                visible = showResolved.value,
            ) {
                Row {
                    Spacer(
                        modifier = Modifier
                            .weight(1f)
                            .fillMaxSize()
                            .background(Color.Black.copy(alpha = 0.4f))
                            .clickable(
                                onClick = {
                                    showResolved.value = false
                                },
                                indication = null,
                                interactionSource = remember { MutableInteractionSource() }
                            )
                    )
                    SlideMenu(
                        modifier = Modifier.fillMaxHeight(),
                        backgroundColor = Color(0xFFFAFAFA),
                        changeToFullScreen = {

                        },
                        content = {
                            // showingResolved
                            ResolvedList(viewModel,taskDetails?: TaskItem()) { showResolved.value = false }
                        }
                    )
                }
            }

        }

    }
}
@OptIn(ExperimentalMaterial3Api::class, ExperimentalResourceApi::class)
@Composable
fun Blocker(blocker: TaskItem, name: String, onResolveClick: () -> Unit, onDeleteClick: () -> Unit, viewModel: HomeViewModel){
    val editable = remember { mutableStateOf(false) }
    val assignToMember = remember { mutableStateOf(false) }
    val blockerText = remember { mutableStateOf("") }
    val blockerResolvedStatus = viewModel.homeState.value.selectedProject?.taskStatusDetails?.lastOrNull()?.id
    blockerText.value = blocker.name?:""
    var now = Clock.System.now()
    val startTime: Instant = Instant.parse(blocker.createdAt?:"" )

    var durationSinceThenState: MutableState<Duration> = remember { mutableStateOf(now - startTime) }

    val formattedDuration = when {
        durationSinceThenState.value.inWholeMinutes.toInt() == 0 -> {
            "Just now"
        }
        durationSinceThenState.value.inWholeMinutes < 60 -> {
            val minutes = durationSinceThenState.value.inWholeMinutes
            "$minutes ${if (minutes > 1) "mins" else "min"}  ago"
        }
        durationSinceThenState.value.inWholeHours < 24 -> {
            val hours = durationSinceThenState.value.inWholeHours
            "$hours ${if (hours > 1) "hours" else "hour"}" +
                    (if (durationSinceThenState.value.inWholeMinutes % 60 > 0) " ${durationSinceThenState.value.inWholeMinutes % 60} min ago" else "")
        }
        else -> {
            val days = durationSinceThenState.value.inWholeDays
            val hours = durationSinceThenState.value.inWholeHours % 24
            val minutes = durationSinceThenState.value.inWholeMinutes % 60
            val daysText = if (days > 0) "$days day" + (if (days > 1) "s" else "") else ""
            val hoursText = if (hours > 0) " $hours hour" + (if (hours > 1) "s" else "") else ""
            val minutesText = if (minutes > 0) " $minutes min" else ""
           // "$daysText$hoursText$minutesText ago"
            "$daysText$hoursText ago"
        }
    }

    Card(
        modifier = Modifier
            .padding(8.dp)
            .fillMaxWidth()

        ,
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
        colors = CardDefaults.cardColors(
            containerColor = Color(0xFFFAFAFA), // set the background color of the card
        )
        ,
        shape = RoundedCornerShape(10.dp),
        border = BorderStroke( 1.dp, if (blocker.taskStatus=="Resolved") Color(0xFF018000) else Color.Red)
    ){
        Column {
                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(start = 16.dp)) {

                   // imagesState.value.xBlocker_image?.let { Image(bitmap = it, contentDescription = null) }
                    Image(painterResource(Res.drawable.x_blocker),contentDescription = null)
                    Text( "Blocker",  fontSize = 16.sp,
                        modifier = Modifier.padding(start = 4.dp),
                        style = MaterialTheme.typography.bodyMedium,
                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                        color = Color.Black
                    )
                    Spacer(modifier = Modifier.weight(1f))



                    val isDropdownMenuExpanded = remember { mutableStateOf(false) }
                    Box {
                        IconButton(
                            onClick = { isDropdownMenuExpanded.value = !isDropdownMenuExpanded.value },
                        ) {
                            Icon(
                                imageVector = Icons.Default.MoreVert,
                                contentDescription = "More Options"
                            )
                        }


                        DropdownMenu(
                            expanded = isDropdownMenuExpanded.value,
                            onDismissRequest = { isDropdownMenuExpanded.value = false },
                            modifier = Modifier.align(Alignment.TopEnd).background(color = Color.White)
                        ){
                            DropdownMenuItem(
                                text = { Text("Edit blocker",
                                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                    style = MaterialTheme.typography.bodySmall,
                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                    color = Color(0xFF5C728A)
                                    , fontSize = 15.sp) },
                                onClick = {
                                    isDropdownMenuExpanded.value = false
                                    editable.value = true
                                }
                            )
                            DropdownMenuItem(
                                text = { Text("Delete",
                                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                    style = MaterialTheme.typography.bodySmall,
                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                    color = Color(0xFF5C728A)
                                    , fontSize = 15.sp) },
                                onClick = {
                                    isDropdownMenuExpanded.value = false
                                    onDeleteClick()

                                }
                            )


                        }
                    }

                }
            if (editable.value){
                Row(modifier = Modifier.padding(horizontal = 8.dp)) {
                    Box(modifier = Modifier.background(Color(0xFFe5eefd), shape = RoundedCornerShape(10.dp)).padding(horizontal = 8.dp, vertical = 4.dp).fillMaxWidth().height(74.dp)) {
                        ZiFocusTextField(text = blockerText, textStyle = MaterialTheme.typography.bodySmall, placeHolder = "Type blocker here", withDivider = false)
                    }
                }
                Spacer(modifier = Modifier.height(2.dp))
                Row(modifier = Modifier.padding(horizontal = 8.dp), verticalAlignment = Alignment.CenterVertically) {
                    Text("Assign To", style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                        fontSize = 14.sp,color=Color(0xFF3b4958) )
                    Spacer(modifier = Modifier.width(2.dp))

                    Box {

                        //Collecting states from ViewModel
                        val searchText by viewModel.searchText.collectAsState() // Query for SearchBar
                        val countriesList by viewModel.boardMemberList.collectAsState()


                        TextButton(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                            modifier = Modifier
                                .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp),
                            onClick = { assignToMember.value = !assignToMember.value }
                        ) {
                            Row(verticalAlignment = Alignment.CenterVertically) {

                                if (blocker.assignedTo.isNullOrEmpty()){
                                    Image(painterResource(Res.drawable.assign_member_),modifier = Modifier.size(24.dp), contentDescription = null)
                                } else {
                                    if (blocker.assignedToDetails?.avatar.isNullOrEmpty()){
                                        val name = blocker.assignedToDetails?.name?:""
                                        val words = name.split(" ")
                                        val initials = if (words.size > 1) {
                                            words[0].take(1) + words[words.lastIndex].take(1)
                                        } else {
                                            words[0].take(2)
                                        }.uppercase()
                                        TextButton(onClick = {},modifier = Modifier
                                            .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                            Text(
                                                text = initials,
                                                fontSize = 16.sp,
                                                color = Color.White,
                                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                maxLines = 1,
                                                overflow = TextOverflow.Ellipsis,
                                                textAlign = TextAlign.Center
                                            )
                                        }

                                    }
                                    else {
                                        Image(
                                            rememberImagePainter(blocker.assignedToDetails?.avatar?:""),modifier = Modifier
                                                .size(35.dp)
                                                .clip(CircleShape)
                                                .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                    }
                                    Spacer(modifier = Modifier.width(6.dp))
                                    Text(blocker.assignedToDetails?.name?:"",
                                        //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                        style =  MaterialTheme.typography.bodySmall
                                        ,
                                        fontSize = 14.sp, color = Color.Black,modifier = Modifier.padding(start = 4.dp))
                                }

                            }

                        }
                        DropdownMenu(
                            expanded = assignToMember.value,
                            onDismissRequest = { assignToMember.value = false },
                            modifier = Modifier.align(Alignment.TopEnd).background(color = Color.White,shape = RoundedCornerShape(10.dp)).padding(12.dp)
                        ){
                            Text("Assign to", fontSize = 16.sp,
                                //   fontFamily = fontFamilyResource(MR.fonts.sf.semibold)
                                style =  MaterialTheme.typography.displayMedium
                            )
                            DockedSearchBar(
                                query = searchText,//text showed on SearchBar
                                onQueryChange = viewModel::onSearchTextChange, //update the value of searchText
                                onSearch = viewModel::onSearchTextChange, //the callback to be invoked when the input service triggers the ImeAction.Search action
                                active = true, //whether the user is searching or not
                                onActiveChange = { viewModel.onToogleSearch() }, //the callback to be invoked when this search bar's active state is changed
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .padding(16.dp),
                                placeholder = {
                                    Text(text = "Search members", fontSize = 15.sp,
                                        //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                        style =  MaterialTheme.typography.bodySmall
                                    )
                                },
                                leadingIcon = {
                                    Icon(imageVector = Icons.Default.Search, contentDescription = null)
                                },
                                colors = SearchBarDefaults.colors(containerColor = Color.White)
                            ) {
                                countriesList.let {
                                    Column(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .heightIn(max=250.dp)
                                            .verticalScroll(state = rememberScrollState())
                                    ) {
                                        it.forEach { member ->
                                            TextButton(
                                                onClick = {
                                                    blocker.assignedTo = member?.id
                                                    blocker.assignedToDetails.apply {
                                                        this?.name = member?.name
                                                        this?.avatar = member?.avatar
                                                        this?.id = member?.id
                                                        this?.email = member?.email
                                                    }
                                                    assignToMember.value = false
                                                }
                                            ) {
                                                Row(verticalAlignment = Alignment.CenterVertically) {
                                                    if (member?.avatar.isNullOrEmpty()){
                                                        val name = member?.name?:""
                                                        val words = name.split(" ")
                                                        val initials = if (words.size > 1) {
                                                            words[0].take(1) + words[words.lastIndex].take(1)
                                                        } else {
                                                            words[0].take(2)
                                                        }.uppercase()
                                                        TextButton(onClick = {},modifier = Modifier
                                                            .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                                            Text(
                                                                text = initials,
                                                                fontSize = 16.sp,
                                                                color = Color.White,
                                                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                                maxLines = 1,
                                                                overflow = TextOverflow.Ellipsis,
                                                                textAlign = TextAlign.Center
                                                            )
                                                        }

                                                    }
                                                    else {
                                                        Image(
                                                            rememberImagePainter(member?.avatar?:""),modifier = Modifier
                                                                .size(35.dp)
                                                                .clip(CircleShape)
                                                                .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                                    }
                                                    Spacer(modifier = Modifier.width(6.dp))

                                                    Text(if (member?.id==viewModel.homeState.value.user?.id) "${member?.name} - @Me" else member?.name?:"",
                                                        //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                        style =  MaterialTheme.typography.bodySmall,
                                                        fontSize = 14.sp, color = Color.Black,modifier = Modifier.padding(start = 4.dp))
                                                }

                                            }


                                        }
                                    }
                                }
                            }
                        }
                    }



                }
                Spacer(modifier = Modifier.height(2.dp))


                Row(modifier = Modifier.padding(bottom = 14.dp)) {
                    Spacer(modifier = Modifier.weight(1f))
                    TextButton(onClick = {
                        editable.value = false
                    }){
                        Text("Discard", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                            color = Color(0xFF3b4958))
                    }
                    Spacer(modifier = Modifier.width(8.dp))
                    Button(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                        modifier = Modifier
                            .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp).width(56.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF495564)),onClick = {

                            viewModel.resolveBlocker(blocker.copy(name=blockerText.value), blocker = blocker)
                            editable.value = false

                        }){
                        Text("Save", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                    }

                    Spacer(modifier = Modifier.width(8.dp))

                }

            } else {
                Text(blocker.name?:"",
                    fontSize = 14.sp,
                    style = MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    color = Color(0xFF5C728A),
                    modifier = Modifier.padding(horizontal = 16.dp).padding(bottom = 16.dp)
                )

                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(16.dp)) {
                   /* Image(rememberImagePainter(blocker.assignedToDetails?.avatar?:""), modifier = Modifier.size(35.dp), contentDescription = null)
                    Text(blocker.assignedToDetails?.name?:"", style = MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                        color = Color(0xFF5C728A),
                        fontSize = 14.sp, modifier = Modifier.padding(start = 4.dp))
                    */
                    if (blocker.assignedToDetails?.avatar.isNullOrEmpty()){
                        val name = blocker.assignedToDetails?.name?:""
                        val words = name.split(" ")
                        val initials = if (words.size > 1) {
                            words[0].take(1) + words[words.lastIndex].take(1)
                        } else {
                            words[0].take(2)
                        }.uppercase()
                        TextButton(onClick = {}, modifier = Modifier
                            .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                            Text(
                                text = initials,
                                fontSize = 16.sp,
                                color = Color.White,
                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                maxLines = 1,
                                overflow = TextOverflow.Ellipsis,
                                textAlign = TextAlign.Center,
                            )
                        }

                    }
                    else {
                        Image(rememberImagePainter(blocker.assignedToDetails?.avatar?:""),modifier = Modifier
                            .size(28.dp)
                            .clip(CircleShape)
                            .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                    }
                    Spacer(modifier = Modifier.width(6.dp))
                    Text(blocker.assignedToDetails?.name?:"", style = MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                        color = Color(0xFF5C728A),
                        fontSize = 14.sp, modifier = Modifier.padding(start = 4.dp))

                    Spacer(modifier = Modifier.width(24.dp))
                    Text(formattedDuration, color = Color.Gray, modifier = Modifier.alpha(0.5f),fontSize = 12.sp, style = MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily)
                    Spacer(modifier = Modifier.weight(1f))
                    Text(if (blocker.taskStatus==blockerResolvedStatus) "Reopen" else "Resolve", color = MaterialTheme.colorScheme.primaryContainer, style = MaterialTheme.typography.bodySmall,
                        fontSize = 14.sp,
                        modifier = Modifier.clickable { onResolveClick() }
                    )
                }

            }




        }
    }
}


@Composable
fun Comment(comment: Comment, viewModel: HomeViewModel, onDeleteClick: () -> Unit){
    val editable = remember { mutableStateOf(false) }
    val commentText = remember { mutableStateOf("") }
    commentText.value = comment.text?:""
    val user = viewModel.homeState.value.user

    var now = Clock.System.now()
    val startTime: Instant = Instant.parse(comment.createdAt?:"")

    var durationSinceThenState: MutableState<Duration> = remember { mutableStateOf(now - startTime) }

    val formattedDuration = when {
        durationSinceThenState.value.inWholeMinutes.toInt() == 0 -> {
            "Just now"
        }
        durationSinceThenState.value.inWholeMinutes < 60 -> {
            val minutes = durationSinceThenState.value.inWholeMinutes
            "$minutes ${if (minutes > 1) "mins" else "min"}  ago"
        }
        durationSinceThenState.value.inWholeHours < 24 -> {
            val hours = durationSinceThenState.value.inWholeHours
            "$hours ${if (hours > 1) "hours" else "hour"}" +
                    (if (durationSinceThenState.value.inWholeMinutes % 60 > 0) " ${durationSinceThenState.value.inWholeMinutes % 60} min ago" else "")
        }
        else -> {
            val days = durationSinceThenState.value.inWholeDays
            val hours = durationSinceThenState.value.inWholeHours % 24
          //  val minutes = durationSinceThenState.value.inWholeMinutes % 60
            val daysText = if (days > 0) "$days day" + (if (days > 1) "s" else "") else ""
            val hoursText = if (hours > 0) " $hours hour" + (if (hours > 1) "s" else "") else ""
         //   val minutesText = if (minutes > 0) " $minutes min" else ""
          //  "$daysText$hoursText$minutesText ago"
            "$daysText$hoursText ago"
        }
    }

    Card(
        modifier = Modifier
            .padding(8.dp)
            .fillMaxWidth()

        ,
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
        colors = CardDefaults.cardColors(
            containerColor = Color(0xFFFAFAFA), // set the background color of the card
        )
        ,
        shape = RoundedCornerShape(10.dp),
    ){
        Column {


                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(start = 16.dp)) {
                    if (comment.createdByDetails?.avatar.isNullOrEmpty()){
                        val name = comment.createdByDetails?.name?:""
                        val words = name.split(" ")
                        val initials = if (words.size > 1) {
                            words[0].take(1) + words[words.lastIndex].take(1)
                        } else {
                            words[0].take(2)
                        }.uppercase()
                        TextButton(onClick = {},modifier = Modifier
                            .size(28.dp).defaultMinSize(1.dp,1.dp), 
                             contentPadding = PaddingValues(), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                            Text(
                                text = initials,
                                fontSize = 14.sp,
                                color = Color.White,
                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                maxLines = 1,
                                overflow = TextOverflow.Ellipsis,
                                textAlign = TextAlign.Center
                            )
                        }

                    }
                    else {
                        Image(rememberImagePainter(comment.createdByDetails?.avatar?:""),modifier = Modifier
                            .size(28.dp)
                            .clip(CircleShape)
                            .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                    }
                    Spacer(modifier = Modifier.width(6.dp))
                    Text(comment.createdByDetails?.name?:"", style = MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                        color = Color(0xFF5C728A),
                        fontSize = 14.sp, modifier = Modifier.padding(start = 4.dp))
                    Spacer(modifier = Modifier.width(24.dp))
                    Text(formattedDuration, color = Color.Gray, modifier = Modifier.alpha(0.5f), fontSize = 12.sp,
                        style = MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily)
                    Spacer(modifier = Modifier.weight(1f))

                    val isDropdownMenuExpanded = remember { mutableStateOf(false) }
                   // if (user?.id==comment.createdBy){
                        Box {
                            IconButton(
                                onClick = {  isDropdownMenuExpanded.value = !isDropdownMenuExpanded.value },
                                enabled = user?.id==comment.createdBy
                            ) {
                                if (user?.id==comment.createdBy){
                                    Icon(
                                        imageVector = Icons.Default.MoreVert,
                                        contentDescription = "More Options"
                                    )
                                }

                            }


                            DropdownMenu(
                                expanded = isDropdownMenuExpanded.value,
                                onDismissRequest = { isDropdownMenuExpanded.value = false },
                                modifier = Modifier.align(Alignment.TopEnd).background(color = Color.White)
                            ){
                                DropdownMenuItem(
                                    text = { Text("Edit comment",
                                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                        style = MaterialTheme.typography.bodySmall,
                                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                        color = Color(0xFF5C728A)
                                        , fontSize = 15.sp) },
                                    onClick = {
                                        isDropdownMenuExpanded.value = false
                                        editable.value = true
                                    }
                                )
                                DropdownMenuItem(
                                    text = { Text("Delete",
                                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                        style = MaterialTheme.typography.bodySmall,
                                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                        color = Color(0xFF5C728A)
                                        , fontSize = 15.sp) },
                                    onClick = {
                                        isDropdownMenuExpanded.value = false
                                        onDeleteClick()

                                    }
                                )


                            }
                        }
                 //   }


                }
            if (editable.value){
                Row(modifier = Modifier.padding(horizontal = 8.dp)) {
                    Box(modifier = Modifier.background(Color(0xFFe5eefd), shape = RoundedCornerShape(10.dp)).padding(horizontal = 8.dp, vertical = 4.dp).fillMaxWidth().height(74.dp)) {
                        ZiFocusTextField(text = commentText, textStyle = MaterialTheme.typography.bodySmall, placeHolder = "Type comment", withDivider = false)
                    }
                }


                Row(modifier = Modifier.padding(bottom = 14.dp)) {
                    Spacer(modifier = Modifier.weight(1f))
                    TextButton(onClick = {
                        editable.value = false
                    }){
                        Text("Discard", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                            color = Color(0xFF3b4958))
                    }
                    Spacer(modifier = Modifier.width(8.dp))
                    Button(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                        modifier = Modifier
                            .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp).width(56.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF495564)),onClick = {
                            viewModel.updateComment(comment.copy(text = commentText.value))
                            editable.value=false
                        }){
                        Text("Save", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                    }

                    Spacer(modifier = Modifier.width(8.dp))

                }
            } else {
                Spacer(modifier = Modifier.height(8.dp))
                Text(comment.text?:"",
                    fontSize = 14.sp,
                    style = MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    lineHeight = 22.sp,
                    color = Color(0xFF5C728A),
                    modifier = Modifier.padding(horizontal = 16.dp).padding(bottom = 16.dp)
                )
            }


        }
    }
}

@OptIn(ExperimentalMaterial3Api::class, ExperimentalResourceApi::class)
@Composable
fun createBlocker(viewModel: HomeViewModel, id: String?, addBlocker: MutableState<Boolean>){
    val blocker = remember { mutableStateOf("") }
    val assignToMember = remember { mutableStateOf(false) }
    val memberAssigned = remember { mutableStateOf("") }
    val assignedToId = remember { mutableStateOf("") }
    val memberAvatar = remember { mutableStateOf("") }
    val user = viewModel.homeState.value.user
    Card(
        modifier = Modifier
            .padding(8.dp)
            .fillMaxWidth()

        ,
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
        colors = CardDefaults.cardColors(
            containerColor = Color(0xFFFAFAFA), // set the background color of the card
        )
        ,
        shape = RoundedCornerShape(10.dp),
        border = BorderStroke( 0.dp, Color.Red)
    ){
        Column {

                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(16.dp)) {
                    Image(painterResource(Res.drawable.x_blocker),contentDescription = null)

                    Text("Blocker", color = Color(0xFF03264A), fontSize = 16.sp,
                        style = MaterialTheme.typography.bodyMedium,
                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                        modifier = Modifier.padding(start = 4.dp)
                    )
                    Spacer(modifier = Modifier.weight(1f))
                }
            Row(modifier = Modifier.padding(horizontal = 8.dp)) {
                Box(modifier = Modifier.background(Color(0xFFe5eefd), shape = RoundedCornerShape(10.dp)).padding(horizontal = 8.dp, vertical = 4.dp).fillMaxWidth().height(74.dp)) {
                    ZiFocusTextField(text = blocker, textStyle = MaterialTheme.typography.bodySmall, placeHolder = "Type blocker here", withDivider = false)
                }
            }

            Spacer(modifier = Modifier.height(2.dp))
            Row(modifier = Modifier.padding(horizontal = 8.dp), verticalAlignment = Alignment.CenterVertically) {
                Text("Assign To", style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    fontSize = 14.sp,color=Color(0xFF3b4958) )
                Spacer(modifier = Modifier.width(2.dp))

                Box {

                    //Collecting states from ViewModel
                    val searchText by viewModel.searchText.collectAsState() // Query for SearchBar
                    val isSearching by viewModel.isSearching.collectAsState() // Active state for SearchBar
                    val countriesList by viewModel.boardMemberList.collectAsState()


                    TextButton(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                        modifier = Modifier
                            .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp),
                        onClick = { assignToMember.value = !assignToMember.value }
                    ) {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            if (memberAssigned.value==""){
                                Image(painterResource(Res.drawable.assign_member_),modifier = Modifier.size(24.dp), contentDescription = null)
                            } else {
                                if (memberAvatar.value==""){
                                    val name = memberAssigned.value
                                    val words = name.split(" ")
                                    val initials = if (words.size > 1) {
                                        words[0].take(1) + words[words.lastIndex].take(1)
                                    } else {
                                        words[0].take(2)
                                    }.uppercase()
                                    TextButton(onClick = {},modifier = Modifier
                                        .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                        Text(
                                            text = initials,
                                            fontSize = 16.sp,
                                            color = Color.White,
                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                            maxLines = 1,
                                            overflow = TextOverflow.Ellipsis,
                                            textAlign = TextAlign.Center
                                        )
                                    }

                                }
                                else {
                                    Image(
                                        rememberImagePainter(memberAvatar.value?:""),modifier = Modifier
                                            .size(35.dp)
                                            .clip(CircleShape)
                                            .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                }
                                Spacer(modifier = Modifier.width(6.dp))
                                Text(memberAssigned.value,
                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                    style =  MaterialTheme.typography.bodySmall
                                    ,
                                    fontSize = 14.sp, color = Color.Black,modifier = Modifier.padding(start = 4.dp))
                            }

                        }

                    }
                    DropdownMenu(
                        expanded = assignToMember.value,
                        onDismissRequest = { assignToMember.value = false },
                        modifier = Modifier.align(Alignment.TopEnd).background(color = Color.White,shape = RoundedCornerShape(10.dp)).padding(12.dp)
                    ){
                        Text("Assign to", fontSize = 16.sp,
                            //   fontFamily = fontFamilyResource(MR.fonts.sf.semibold)
                            style =  MaterialTheme.typography.displayMedium
                        )
                        DockedSearchBar(
                            query = searchText,//text showed on SearchBar
                            onQueryChange = viewModel::onSearchTextChange, //update the value of searchText
                            onSearch = viewModel::onSearchTextChange, //the callback to be invoked when the input service triggers the ImeAction.Search action
                            active = true, //whether the user is searching or not
                            onActiveChange = { viewModel.onToogleSearch() }, //the callback to be invoked when this search bar's active state is changed
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(16.dp),
                            placeholder = {
                                Text(text = "Search members", fontSize = 15.sp,
                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                    style =  MaterialTheme.typography.bodySmall
                                )
                            },
                            leadingIcon = {
                                Icon(imageVector = Icons.Default.Search, contentDescription = null)
                            },
                            colors = SearchBarDefaults.colors(containerColor = Color.White)
                        ) {
                            countriesList.let {
                                Column(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .heightIn(max=250.dp)
                                        .verticalScroll(state = rememberScrollState())
                                ) {
                                    it.forEach { member ->
                                        TextButton(
                                            onClick = {
                                                assignedToId.value = member?.id?:""
                                                memberAssigned.value = member?.name?:""
                                                memberAvatar.value = member?.avatar?:""
                                                assignToMember.value = false
                                            }
                                        ) {
                                            Row(verticalAlignment = Alignment.CenterVertically) {
                                                if (member?.avatar.isNullOrEmpty()){
                                                    val name = member?.name?:""
                                                    val words = name.split(" ")
                                                    val initials = if (words.size > 1) {
                                                        words[0].take(1) + words[words.lastIndex].take(1)
                                                    } else {
                                                        words[0].take(2)
                                                    }.uppercase()
                                                    TextButton(onClick = {},modifier = Modifier
                                                        .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                                        Text(
                                                            text = initials,
                                                            fontSize = 16.sp,
                                                            color = Color.White,
                                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                            maxLines = 1,
                                                            overflow = TextOverflow.Ellipsis,
                                                            textAlign = TextAlign.Center
                                                        )
                                                    }

                                                }
                                                else {
                                                    Image(
                                                        rememberImagePainter(member?.avatar?:""),modifier = Modifier
                                                            .size(35.dp)
                                                            .clip(CircleShape)
                                                            .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                                }
                                                Spacer(modifier = Modifier.width(6.dp))

                                                Text(if (member?.id==user?.id) "${member?.name} - @Me" else member?.name?:"",
                                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                    style =  MaterialTheme.typography.bodySmall,
                                                    fontSize = 14.sp, color = Color.Black,modifier = Modifier.padding(start = 4.dp))
                                            }

                                        }

                                    }
                                }
                            }
                        }
                    }
                }


            }
            Spacer(modifier = Modifier.height(2.dp))


            Row(modifier = Modifier.padding(bottom = 14.dp)) {
                Spacer(modifier = Modifier.weight(1f))
                TextButton(onClick = {
                    addBlocker.value = false
                }){
                    Text("Discard", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                        color = Color(0xFF3b4958))
                }
                Spacer(modifier = Modifier.width(8.dp))
                Button(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                    modifier = Modifier
                        .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp).width(56.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF495564)),onClick = {
                    // blocker create
                            val blockerStatus = viewModel.homeState.value.selectedProject?.taskStatusDetails?.firstOrNull()?.id
                        viewModel.createSubTask(
                        CreateTaskRequest(parentTaskId = id, name = blocker.value, priority = "Blocker",
                            assignedTo = if (assignedToId.value=="") null else assignedToId.value , taskStatus = blockerStatus
                        ),
                        blocker = true
                    )
                }){
                    Text("Save", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                }

                Spacer(modifier = Modifier.width(8.dp))

            }


        }
    }
}


@Composable
fun createComment(viewModel: HomeViewModel, id: String?, addComment: MutableState<Boolean>) {
    val comment = remember { mutableStateOf("") }
    val commentCreated = remember { mutableStateOf(false) }

    Card(
        modifier = Modifier
            .padding(8.dp)
            .fillMaxWidth()

        ,
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
        colors = CardDefaults.cardColors(
            containerColor = Color(0xFFFAFAFA), // set the background color of the card
        )
        ,
        shape = RoundedCornerShape(10.dp),
    ){
        Column(modifier = Modifier.padding(top = 8.dp)) {


            Row(modifier = Modifier.padding(horizontal = 8.dp)) {
                Box(modifier = Modifier.background(Color(0xFFe5eefd), shape = RoundedCornerShape(10.dp)).padding(horizontal = 8.dp, vertical = 4.dp).fillMaxWidth().height(74.dp)) {
                    ZiFocusTextField(text = comment, textStyle = MaterialTheme.typography.bodySmall, placeHolder = "Type comment", withDivider = false)
                }
            }


            Row(modifier = Modifier.padding(bottom = 14.dp)) {
                Spacer(modifier = Modifier.weight(1f))
                TextButton(onClick = {
                    addComment.value = false
                }){
                    Text("Discard", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                        color = Color(0xFF3b4958))
                }
                Spacer(modifier = Modifier.width(8.dp))
                Button(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                    modifier = Modifier
                        .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp).width(56.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF495564)),onClick = {
                            addComment.value=false
                            viewModel.createComment(text = comment.value, taskId = id){
                            commentCreated.value = true
                        }
                    }){
                    Text("Save", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                }

                Spacer(modifier = Modifier.width(8.dp))

            }


        }
    }
}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun ResolvedList(viewModel: HomeViewModel, task: TaskItem, exit: () -> Unit={}){
    val blockerToDoStatus = viewModel.homeState.value.selectedProject?.taskStatusDetails?.firstOrNull()?.id
    val blockerResolvedStatus = viewModel.homeState.value.selectedProject?.taskStatusDetails?.lastOrNull()?.id
    Column {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Text("Resolved", fontSize = 15.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily, style = MaterialTheme.typography.bodySmall,
                color =Color(0xFF5C728A) )
            Spacer(modifier = Modifier.weight(1f))
            IconButton(onClick = {exit()}){
                 Image(painterResource(Res.drawable.x_cross),null)
            }
        }
        Spacer(modifier = Modifier.height(12.dp))

        task.blockers?.filter { it.taskStatus==blockerResolvedStatus }?.forEach {
            Blocker(it,  name = it.assignedToDetails?.name?:"",
                onResolveClick = {
                    viewModel.resolveBlocker(it.copy(taskStatus = if (it.taskStatus==blockerResolvedStatus) blockerToDoStatus else blockerResolvedStatus),
                        blocker = it)
                    viewModel.getTaskDetails(task.id)
                }, onDeleteClick = {
                    viewModel.deleteTask(it.id?:"", blocker = true)
                },viewModel

            )
        }



    }
}

@ExperimentalComposeUiApi
@Composable
fun AutoSubmittingTextField(textState: MutableState<String?>, onClick: () -> Unit, textStyle: TextStyle,modifier: Modifier=Modifier.padding(start = 16.dp)) {
    val keyboardController = LocalSoftwareKeyboardController.current
    val updatedTextState = rememberUpdatedState(newValue = textState.value)
    val isInitialLoad = remember { mutableStateOf(true) }
    val isUserInteracted = remember { mutableStateOf(false) }

    LaunchedEffect(updatedTextState.value) {
        if (isActive) {
            if (isInitialLoad.value) {
                isInitialLoad.value = false
            } else {
                delay(1300L) // Wait for 1 second after user stops typing

                if (textState.value?.isNotBlank() == true&&isUserInteracted.value) {
                    // Perform your action here, e.g., submit the text
                    keyboardController?.hide()
                    // Update Task
                    onClick()
                }
            }

        }
    }
    BasicTextField(value = textState.value?:"",
        onValueChange = {
            textState.value=it
            isUserInteracted.value = true
        }
        ,
        modifier = modifier,
        textStyle = textStyle,
         keyboardOptions = KeyboardOptions(
            keyboardType = KeyboardType.Text,
            imeAction = ImeAction.Done
        ),
        keyboardActions = KeyboardActions(
            onDone = { keyboardController?.hide() }
        )

    )
}



@ExperimentalComposeUiApi
@Composable
fun AutoSubmittingTextField(value:String,onValueChange:(String)->Unit, onClick: () -> Unit, textStyle: TextStyle,modifier: Modifier=Modifier.padding(start = 16.dp)) {
    val keyboardController = LocalSoftwareKeyboardController.current
    val updatedTextState = rememberUpdatedState(newValue = value)
    val isInitialLoad = remember { mutableStateOf(true) }
    val isUserInteracted = remember { mutableStateOf(false) }


    LaunchedEffect(updatedTextState.value) {
        if (isActive) {
            if (isInitialLoad.value) {
                isInitialLoad.value = false
            } else {
                delay(1300L) // Wait for 1 second after user stops typing

                if (value.isNotBlank()&&isUserInteracted.value) {
                    // Perform your action here, e.g., submit the text
                    keyboardController?.hide()
                    // Update Task
                    onClick()
                }
            }

        }
    }
    BasicTextField(value = value,
        onValueChange = {
            onValueChange(it)
            isUserInteracted.value = true
        }
        ,
        modifier = modifier,
        textStyle = textStyle,
        keyboardOptions = KeyboardOptions(
            keyboardType = KeyboardType.Text,
            imeAction = ImeAction.Done
        ),
        keyboardActions = KeyboardActions(
            onDone = { keyboardController?.hide() }
        )

    )
}
@OptIn(ExperimentalMaterialApi::class, ExperimentalComposeUiApi::class, ExperimentalMaterial3Api::class,
    ExperimentalResourceApi::class
)
@Composable
fun SubTasks(viewModel: HomeViewModel, parentTask: TaskItem) {
    val homeUiState = viewModel.homeState.collectAsState()
    val subTasks = homeUiState.value.subTasks
    val tasksDone = remember { mutableStateOf(parentTask.doneSubTasksCount?.toInt()?:0) }
    val isDeleteSubTaskDialogVisible = remember { mutableStateOf(false) }
    val assignToMember = remember { mutableStateOf(false) }
    val subTaskCreated = remember { mutableStateOf(false) }
    val taskId = remember { mutableStateOf("") }
    val subTaskName:MutableState<String?> = remember { mutableStateOf("") }

    val lazyListState = rememberLazyListState()

    val dateSelected =rememberDatePickerState(yearRange = 2023..2050,)
    val datePicker = remember { mutableStateOf(false) }
    var dueDate by remember { mutableStateOf("") }




    if (isDeleteSubTaskDialogVisible.value){
        CustomAlertDialog(onDelete = {
            isDeleteSubTaskDialogVisible.value=false
            viewModel.deleteTask(taskId.value?:"",subTask = true)
        }, onDismiss = {
            isDeleteSubTaskDialogVisible.value=false
        })
    }

    Column(modifier = Modifier.padding(16.dp)) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Text("Subtasks", style =  MaterialTheme.typography.bodyLarge, fontSize = 18.sp,color = Color(0xFF5C728A),
             //
            )
            Spacer(modifier = Modifier.weight(1f))
            Text("${tasksDone.value}/${subTasks.size}", style =  MaterialTheme.typography.bodyLarge, fontSize = 18.sp,color = Color(0xFF5C728A)
                )
        }
        LazyColumn(state = lazyListState,modifier = Modifier.heightIn(max=160.dp).simpleVerticalScrollbar(state = lazyListState)) {
            items(subTasks){task->

                if (datePicker.value){
                    DatePickerDialog(onDismissRequest = {datePicker.value=false}, confirmButton = {
                        TextButton(onClick = {
                            dueDate = dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().dateTimeToString()
                            datePicker.value = false
                            viewModel.updateTask(task.copy(dueDate = dueDate)?: TaskItem(),isSubTask = true){
                                task.dueDate = it?.dueDate
                                viewModel.showSnackBar("Task Due date updated")
                            }
                        }) {
                            Text("Confirm",
                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                style =  MaterialTheme.typography.bodySmall
                                , fontSize = 15.sp, color = MaterialTheme.colorScheme.primaryContainer)
                        }
                    }, colors = DatePickerDefaults.colors(containerColor = Color.White)){
                        DatePicker(
                            state = dateSelected,
                            modifier = Modifier.wrapContentHeight().wrapContentWidth(),
                            colors = DatePickerDefaults.colors(selectedDayContainerColor = MaterialTheme.colorScheme.primaryContainer, todayDateBorderColor = MaterialTheme.colorScheme.primaryContainer, todayContentColor = MaterialTheme.colorScheme.primaryContainer)
                        )
                    }
                }



                val isDropdownMenuExpanded = remember { mutableStateOf(false) }
                val taskName = remember { mutableStateOf(task.name?:"") }
                val doneStatus = homeUiState.value.selectedProject?.taskStatusDetails?.lastOrNull()?.id
                val toDoStatus = homeUiState.value.selectedProject?.taskStatusDetails?.firstOrNull()?.id
                val checked = remember { mutableStateOf(if (task.taskStatus==toDoStatus)false else true) }
                LaunchedEffect(task.name){
                    taskName.value = task.name?:""
                    checked.value = if (task.taskStatus==toDoStatus)false else true
                }

                val interactionSource = remember { MutableInteractionSource() }

                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.hoverable(interactionSource).fillMaxWidth()) {
                    androidx.compose.material.Checkbox(
                        checked = checked.value,
                        onCheckedChange = {
                                isChecked -> checked.value = isChecked

                            viewModel.updateTask(task.copy(taskStatus = if (checked.value) doneStatus else toDoStatus ),true ){
                                if (checked.value){
                                    tasksDone.value+=1
                                } else {
                                    if (tasksDone.value>0){
                                        tasksDone.value-=1
                                    }

                                }
                            }
                            // taskStatus = if (checked.value) "DONE" else "TO_DO"
                        }
                    )
                        Box (modifier = Modifier.fillMaxWidth()){

                            AutoSubmittingTextField(value = taskName.value?:"",
                                onValueChange = {
                                    taskName.value=it
                                                }, modifier = Modifier.padding(start = 0.dp).fillMaxWidth(0.75f), textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 16.sp, color = Color(0xFF5C728A),
                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily), onClick = {
                                viewModel.updateTask(task.copy(name = taskName.value),true ){

                                }
                                subTaskCreated.value !=subTaskCreated.value
                            })

                            if (task.name?.isEmpty() == true) {
                                Text(
                                    text = "Type subtask here",
                                    color = Color.Gray,
                                    fontSize = 16.sp,
                                    style = MaterialTheme.typography.bodySmall,
                                    modifier = Modifier.align(Alignment.CenterStart)
                                )
                            }
                            Row(modifier = Modifier.align(Alignment.CenterEnd), verticalAlignment = Alignment.CenterVertically) {
                               if (!task.assignedTo.isNullOrEmpty()){
                                   if (task.assignedToDetails?.avatar.isNullOrEmpty()){
                                       val name = task.assignedToDetails?.name?:""
                                       val words = name.split(" ")
                                       val initials = if (words.size > 1) {
                                           words[0].take(1) + words[words.lastIndex].take(1)
                                       } else {
                                           words[0].take(2)
                                       }.uppercase()
                                       TextButton(onClick = {},modifier = Modifier
                                           .size(24.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                           Text(
                                               text = initials,
                                               fontSize = 14.sp,
                                               color = Color.White,
                                               fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                               maxLines = 1,
                                               overflow = TextOverflow.Ellipsis,
                                               textAlign = TextAlign.Center
                                           )
                                       }
                                        Constants.WEBSITE_URL
                                   }
                                   else {
                                       Image(
                                           rememberImagePainter(task.assignedToDetails?.avatar?:""),modifier = Modifier
                                               .size(24.dp)
                                               .clip(CircleShape)
                                               .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                   }
                                   Spacer(modifier = Modifier.width(6.dp))

                                   Text( task.assignedToDetails?.name?:"",
                                       fontSize = 14.sp, color = Color(0xFF5C728A),
                                       style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,modifier = Modifier.padding(start = 4.dp))
                                   Spacer(modifier = Modifier.width(6.dp))
                                   Box {
                                       val assignToMemberEdit = remember { mutableStateOf(false) }
                                       Icon(painterResource(Res.drawable.arrow),contentDescription = null,
                                           modifier = Modifier.rotate(90f).clickable { assignToMemberEdit.value=true })

                                       val searchText by viewModel.searchText.collectAsState() // Query for SearchBar
                                       val countriesList by viewModel.boardMemberList.collectAsState()
                                       DropdownMenu(
                                           expanded = assignToMemberEdit.value,
                                           onDismissRequest = { assignToMemberEdit.value = false },
                                           modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                                       ){
                                           Text("Assign to", fontSize = 16.sp,
                                               style = MaterialTheme.typography.displayMedium
                                           )
                                           DockedSearchBar(
                                               query = searchText,//text showed on SearchBar
                                               onQueryChange = viewModel::onSearchTextChange, //update the value of searchText
                                               onSearch = viewModel::onSearchTextChange, //the callback to be invoked when the input service triggers the ImeAction.Search action
                                               active = true, //whether the user is searching or not
                                               onActiveChange = { viewModel.onToogleSearch() }, //the callback to be invoked when this search bar's active state is changed
                                               modifier = Modifier
                                                   .fillMaxWidth()
                                                   .padding(16.dp)
                                               ,
                                               placeholder = {
                                                   Text(text = "Search members", fontSize = 15.sp, style = MaterialTheme.typography.bodySmall)
                                               },
                                               leadingIcon = {
                                                   Icon(imageVector = Icons.Default.Search, contentDescription = null)
                                               },
                                               colors = SearchBarDefaults.colors(containerColor = Color(0xFFFAFAFA))
                                           ) {
                                               countriesList.let {
                                                   Column(
                                                       modifier = Modifier
                                                           .fillMaxWidth()
                                                           .heightIn(max=250.dp)
                                                           .verticalScroll(state = rememberScrollState())
                                                   ) {
                                                       it.forEach { member ->
                                                           TextButton(
                                                               onClick = {
                                                                   task.copy(assignedTo = member?.id)
                                                                       .let { it1 ->
                                                                           viewModel.updateTask(it1,true){it2->
                                                                               viewModel.getTaskDetails(homeUiState.value.task?.id)

                                                                           }
                                                                       }
                                                                   assignToMemberEdit.value = false
                                                               }
                                                           ) {
                                                               Row(verticalAlignment = Alignment.CenterVertically) {
                                                                   if (member?.avatar.isNullOrEmpty()){
                                                                       val name = member?.name?:""
                                                                       val words = name.split(" ")
                                                                       val initials = if (words.size > 1) {
                                                                           words[0].take(1) + words[words.lastIndex].take(1)
                                                                       } else {
                                                                           words[0].take(2)
                                                                       }.uppercase()
                                                                       TextButton(onClick = {},modifier = Modifier
                                                                           .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                                                           Text(
                                                                               text = initials,
                                                                               fontSize = 16.sp,
                                                                               color = Color.White,
                                                                               fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                                               maxLines = 1,
                                                                               overflow = TextOverflow.Ellipsis,
                                                                               textAlign = TextAlign.Center
                                                                           )
                                                                       }

                                                                   }
                                                                   else {
                                                                       Image(
                                                                           rememberImagePainter(member?.avatar?:""),modifier = Modifier
                                                                               .size(35.dp)
                                                                               .clip(CircleShape)
                                                                               .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                                                   }
                                                                   Spacer(modifier = Modifier.width(6.dp))

                                                                   Text(if (member?.id==homeUiState.value.user?.id) "${member?.name} - @Me" else member?.name?:"",
                                                                       //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                       style =  MaterialTheme.typography.bodySmall,
                                                                       fontSize = 14.sp, color = Color.Black,modifier = Modifier.padding(start = 4.dp))
                                                               }

                                                           }

                                                       }
                                                   }
                                               }
                                           }



                                       }
                                   }



                               }
                                Spacer(modifier = Modifier.width(6.dp))
                                if (!task.dueDate.isNullOrEmpty()){
                                    Text(formatDateString(task.dueDate?:""), fontSize = 14.sp, color = Color(0xFF5C728A),
                                        style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily)
                                    Spacer(modifier = Modifier.width(6.dp))
                                    Icon(painterResource(Res.drawable.arrow),contentDescription = null,
                                        modifier = Modifier.rotate(90f).clickable { datePicker.value=true })
                                }
                                Spacer(modifier = Modifier.width(6.dp))
                                Box {
                                    Icon(
                                        imageVector = Icons.Default.MoreVert,
                                        contentDescription = "More Options",
                                        tint = Color(0xFF5C728A),
                                        modifier=Modifier.align(Alignment.CenterEnd).clickable { isDropdownMenuExpanded.value = !isDropdownMenuExpanded.value }
                                    )

                                    DropdownMenu(
                                        expanded = isDropdownMenuExpanded.value,
                                        onDismissRequest = { isDropdownMenuExpanded.value = false },
                                        modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA))
                                    ){
                                        Box {
                                            val searchText by viewModel.searchText.collectAsState() // Query for SearchBar
                                            val countriesList by viewModel.boardMemberList.collectAsState()
                                            if (task.assignedTo== null){
                                                DropdownMenuItem(
                                                    text = { Text(if (task.assignedTo== null)"Member" else "Remove member",
                                                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                        style =  MaterialTheme.typography.bodySmall,
                                                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                        , fontSize = 15.sp) },
                                                    onClick = {
                                                        if (task.assignedTo== null) {assignToMember.value = true}
                                                        else {
                                                            task.copy(assignedTo = null, assignedToDetails = UserRegistration())
                                                                .let { it1 ->
                                                                    viewModel.updateTask(it1,true){it2->
                                                                    }
                                                                }
                                                            isDropdownMenuExpanded.value = false
                                                        }
                                                    }
                                                )
                                            }

                                            DropdownMenu(
                                                expanded = assignToMember.value,
                                                onDismissRequest = { assignToMember.value = false },
                                                modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                                            ){
                                                Text("Assign to", fontSize = 16.sp,
                                                    style = MaterialTheme.typography.displayMedium
                                                )
                                                DockedSearchBar(
                                                    query = searchText,//text showed on SearchBar
                                                    onQueryChange = viewModel::onSearchTextChange, //update the value of searchText
                                                    onSearch = viewModel::onSearchTextChange, //the callback to be invoked when the input service triggers the ImeAction.Search action
                                                    active = true, //whether the user is searching or not
                                                    onActiveChange = { viewModel.onToogleSearch() }, //the callback to be invoked when this search bar's active state is changed
                                                    modifier = Modifier
                                                        .fillMaxWidth()
                                                        .padding(16.dp)
                                                    ,
                                                    placeholder = {
                                                        Text(text = "Search members", fontSize = 15.sp, style = MaterialTheme.typography.bodySmall)
                                                    },
                                                    leadingIcon = {
                                                        Icon(imageVector = Icons.Default.Search, contentDescription = null)
                                                    },
                                                    colors = SearchBarDefaults.colors(containerColor = Color(0xFFFAFAFA))
                                                ) {
                                                    countriesList.let {
                                                        Column(
                                                            modifier = Modifier
                                                                .fillMaxWidth()
                                                                .heightIn(max=250.dp)
                                                                .verticalScroll(state = rememberScrollState())
                                                        ) {
                                                            it.forEach { member ->
                                                                TextButton(
                                                                    onClick = {
                                                                        task.copy(assignedTo = member?.id)
                                                                            .let { it1 ->
                                                                                viewModel.updateTask(it1,true){it2->
                                                                                    viewModel.getTaskDetails(homeUiState.value.task?.id)

                                                                                }
                                                                            }
                                                                        assignToMember.value = false
                                                                    }
                                                                ) {
                                                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                                                        if (member?.avatar.isNullOrEmpty()){
                                                                            val name = member?.name?:""
                                                                            val words = name.split(" ")
                                                                            val initials = if (words.size > 1) {
                                                                                words[0].take(1) + words[words.lastIndex].take(1)
                                                                            } else {
                                                                                words[0].take(2)
                                                                            }.uppercase()
                                                                            TextButton(onClick = {},modifier = Modifier
                                                                                .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                                                                Text(
                                                                                    text = initials,
                                                                                    fontSize = 16.sp,
                                                                                    color = Color.White,
                                                                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                                                    maxLines = 1,
                                                                                    overflow = TextOverflow.Ellipsis,
                                                                                    textAlign = TextAlign.Center
                                                                                )
                                                                            }

                                                                        }
                                                                        else {
                                                                            Image(
                                                                                rememberImagePainter(member?.avatar?:""),modifier = Modifier
                                                                                    .size(35.dp)
                                                                                    .clip(CircleShape)
                                                                                    .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                                                        }
                                                                        Spacer(modifier = Modifier.width(6.dp))

                                                                        Text(if (member?.id==homeUiState.value.user?.id) "${member?.name} - @Me" else member?.name?:"",
                                                                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                            style =  MaterialTheme.typography.bodySmall,
                                                                            fontSize = 14.sp, color = Color.Black,modifier = Modifier.padding(start = 4.dp))
                                                                    }

                                                                }

                                                            }
                                                        }
                                                    }
                                                }



                                            }
                                        }
                                        if (task.dueDate== null){
                                            DropdownMenuItem(
                                                text = { Text(if (task.dueDate== null)"Date" else "Remove Date",
                                                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                    style =  MaterialTheme.typography.bodySmall,
                                                    fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                    , fontSize = 15.sp) },
                                                onClick = {
                                                    if (task.dueDate== null)  datePicker.value = true
                                                    else {
                                                        viewModel.updateTask(task.copy(dueDate=null),isSubTask = true){
                                                            viewModel.showSnackBar("Due Date Removed")
                                                        }
                                                    }

                                                }
                                            )
                                        }

                                        DropdownMenuItem(
                                            text = { Text("Delete",
                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                , fontSize = 15.sp) },
                                            onClick = {
                                                taskId.value = task.id?:""
                                                isDropdownMenuExpanded.value = false
                                                isDeleteSubTaskDialogVisible.value = true

                                            }
                                        )

                                    }
                                }
                            }

                        }





                }
                Spacer(modifier = Modifier.height(12.dp))
            }

            item {
                val checked = remember { mutableStateOf(false) }
                val interactionSource = remember { MutableInteractionSource() }
                val isHovered = interactionSource.collectIsHoveredAsState().value
                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.hoverable(interactionSource)) {
                    androidx.compose.material.Checkbox(
                        checked = checked.value,
                        onCheckedChange = {
                                isChecked -> checked.value = isChecked
                          //  viewModel.updateTask(mapSubTaskToTask(task.copy(taskStatus = if (checked.value) "DONE" else "TO_DO")),true )
                            // taskStatus = if (checked.value) "DONE" else "TO_DO"
                        }
                    )

                    Box() {

                        AutoSubmittingTextField(value = subTaskName.value?:"", onValueChange = {
                            subTaskName.value = it

                        },modifier = Modifier.padding(start = 0.dp),textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 16.sp,
                            color = Color(0xFF5C728A), fontFamily = MaterialTheme.typography.bodySmall.fontFamily), onClick = {
                          //  viewModel.updateTask(mapSubTaskToTask(task.copy(name = taskName.value)),true )
                                // sub task create
                                val statusId = homeUiState.value.selectedProject?.taskStatusDetails?.firstOrNull()?.id
                            viewModel.createSubTask(CreateTaskRequest(name = subTaskName.value,  parentTaskId = parentTask.id, assignedTo = null, taskStatus = statusId),blocker = false){
                                subTaskName.value = ""
                            }

                        })



                        if (subTaskName.value?.isEmpty() == true) {
                            Text(
                                text = "Type subtask here",
                                color = Color.Gray.copy(0.5f),
                                fontSize = 16.sp,
                                style = MaterialTheme.typography.bodySmall,
                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                modifier = Modifier.align(Alignment.CenterStart)
                            )
                        }
                    }


                }

            }
        }
        /*
        subTasks.forEachIndexed { index, task ->
            var taskName = remember { mutableStateOf(task.name) }
            val checked = remember { mutableStateOf(if (task.taskStatus=="TO_DO")false else true) }
            val interactionSource = remember { MutableInteractionSource() }
            val isHovered = interactionSource.collectIsHoveredAsState().value
            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.hoverable(interactionSource)) {
                androidx.compose.material.Checkbox(
                    checked = checked.value,
                    onCheckedChange = {
                        isChecked -> checked.value = isChecked
                        viewModel.updateTask(mapSubTaskToTask(task.copy(taskStatus = if (checked.value) "DONE" else "TO_DO")),true )
                        // taskStatus = if (checked.value) "DONE" else "TO_DO"
                    }
                )

                Box() {

                    AutoSubmittingTextField(taskName, textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 16.sp), onClick = {
                        viewModel.updateTask(mapSubTaskToTask(task.copy(name = taskName.value)),true )
                    })


                  /*  BasicTextField(
                        value = taskName.value,
                        modifier = Modifier.align(Alignment.CenterStart),
                        onValueChange = {
                            taskName.value = it
                                        },
                        textStyle = TextStyle(fontSize = 16.sp,style = MaterialTheme.typography.bodySmall),
                    ) */

                    if (task.name?.isEmpty() == true) {
                        Text(
                            text = "Type '/' to attach a child topic",
                            color = Color.Gray,
                            fontSize = 16.sp,
                            style = MaterialTheme.typography.bodySmall,
                            modifier = Modifier.align(Alignment.CenterStart)
                        )
                    }
                }
                Spacer(modifier = Modifier.weight(1f))
                if (isHovered){
                    Icon(Icons.Default.Delete, null, tint = Color.Red,
                        modifier = Modifier.clickable {
                            // remove task
                            viewModel.deleteTask(task.id?:"")
                            homeUiState.value.subTasks.remove(homeUiState.value.subTasks.find { it.id==task.id })
                        })
                }
            }
        }
        */
    }
}

fun extractFileNameFromUrl(url: String): String {
    val lastSlashIndex = url.lastIndexOf("/")
    if (lastSlashIndex == -1) {
        return url
    }
    return url.substring(lastSlashIndex + 1)
}

@Composable
fun CreateSubTask(viewModel: HomeViewModel, parentTask: Task){
    var taskName by remember { mutableStateOf("") }
    var description by remember { mutableStateOf("") }
    val attachment = remember { mutableStateOf("") }
    var showFilePicker by remember { mutableStateOf(false) }
    var canContinue by remember { mutableStateOf(true) }
    val attachments =  mutableStateListOf<String>()




    val fileType = listOf("jpg", "png","jpeg","pdf")
    FilePicker(show = showFilePicker, fileExtensions = fileType) { platformFile ->
        showFilePicker = false
        // do something with the file
        viewModel.uploadFileOnCreateTask(platformFile,attachment)
        canContinue=false
    }
    if (attachment.value!=""){
        canContinue=true
        attachments.add(attachment.value)
    }

    Column(modifier = Modifier.background(Color.White)) {
        // Task name here
        Box {
            if (taskName==""){
                Text("SubTask Title", fontSize = 24.sp, style = MaterialTheme.typography.bodyMedium, color = Color.Gray,modifier = Modifier.padding(start = 16.dp))
            }
            BasicTextField(value = taskName,
                onValueChange = {
                    taskName=it
                }
                ,
                modifier = Modifier.padding(start = 16.dp),
                textStyle = MaterialTheme.typography.bodyMedium.copy(fontSize = 24.sp),
                keyboardOptions = KeyboardOptions(
                    keyboardType = KeyboardType.Text,
                    imeAction = ImeAction.Done
                ),


                )
        }

        Spacer(modifier = Modifier.height(24.dp))
        Box(modifier = Modifier.background(Color(0xFFCFE2FF)).padding(24.dp)) {
            if (description==""){
                Text("Type Description", fontSize = 16.sp, style = MaterialTheme.typography.bodySmall, color = Color.Gray,modifier = Modifier.padding(start = 16.dp))
            }
            BasicTextField(value = description,
                onValueChange = {
                    description=it
                }
                ,
                modifier = Modifier.padding(start = 16.dp).height(156.dp).fillMaxWidth(),
                textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 16.sp),
                keyboardOptions = KeyboardOptions(
                    keyboardType = KeyboardType.Text,
                    imeAction = ImeAction.Done
                ),


                )
        }
        Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(top = 32.dp)) {
            LazyRow(verticalAlignment = Alignment.CenterVertically) {
                item {
                    Image(Icons.Default.AttachFile,null,modifier = Modifier.padding(start = 16.dp).clickable {
                        showFilePicker = true
                    })
                }
                items(attachments){
                    OutlinedButton(onClick = { openUrl(it) },
                        modifier = Modifier.padding(start = 8.dp)){
                        Icon(Icons.Default.FilePresent,null, tint = Color.Black)
                        Text(extractFileNameFromUrl(it), color = Color.Black,style = MaterialTheme.typography.bodySmall, fontSize = 14.sp, modifier = Modifier.padding(start = 8.dp))
                    }
                }
            }

            Spacer(modifier = Modifier.weight(1f))
            Button(
                onClick = {
                    viewModel.createSubTask(CreateTaskRequest(name = taskName, description = description, parentTaskId = parentTask.id),false)
                },
                modifier = Modifier,
                enabled = true
            ){
                Text("Create subtask")
            }
        }



    }

}

@Composable
fun StatusChoose(viewModel: HomeViewModel, task: TaskItem, statusChange: MutableState<Boolean>) {
        val project = viewModel.homeState.collectAsState().value.selectedProject
        Column(
            modifier = Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            project?.taskStatusDetails?.forEach {
                TextButton(modifier = Modifier.fillMaxWidth(),onClick = {
                    viewModel.updateTask(task.copy(taskStatus = it.id))
                    statusChange.value = false
                },
                    colors = ButtonDefaults.textButtonColors(containerColor = when (it.id) {
                        project.taskStatusDetails?.firstOrNull()?.id -> Color(0xFFCFE2FF)
                        project.taskStatusDetails?.find { it.name== "In Progress"}?.id -> Color(0xFFFFF3CD)
                        project.taskStatusDetails?.lastOrNull()?.id -> Color(0xFFD1E7DD)
                       // else -> getRandomColorForColumns()
                        else ->  Color(0xFFCFE2FF)
                    })){
                    Text(it.name?:"", fontFamily = MaterialTheme.typography.bodySmall.fontFamily, fontSize = 12.sp, color = Color.Black)
                }

                Spacer(modifier = Modifier.height(14.dp))
            }
        }


}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun PriorityChoose(viewModel: HomeViewModel, task: TaskItem, priorityChange: MutableState<Boolean>){


        Column (modifier = Modifier.fillMaxWidth(),verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally){

            OutlinedButton(
                border = BorderStroke(1.dp, Color(0xFFD9D9D9)),
                modifier = Modifier.fillMaxWidth(),onClick = {
                viewModel.updateTask(task.copy(priority = "Urgent"))
                priorityChange.value=false
            },){
                Row(verticalAlignment = Alignment.CenterVertically) {

                        Image(
                            painter = painterResource(Res.drawable.urgent_flag),
                            contentDescription = null,
                        )

                    Spacer(modifier = Modifier.width(4.dp))
                    Text("Urgent", color = Color(0xFF03264A),textAlign = TextAlign.Center,
                        style = MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                        fontSize = 14.sp,)
                    Spacer(modifier = Modifier.weight(1f))
                }
            }
            Spacer(modifier = Modifier.height(14.dp))
            OutlinedButton(
                border = BorderStroke(1.dp, Color(0xFFD9D9D9)),
                modifier = Modifier.fillMaxWidth(),onClick = {
                viewModel.updateTask(task.copy(priority = "Medium"))
                priorityChange.value=false
            },){
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Image(
                        painter = painterResource(Res.drawable.medium_flag),
                        contentDescription = null,
                    )
                    Spacer(modifier = Modifier.width(4.dp))
                    Text("Medium", color = Color(0xFF03264A), textAlign = TextAlign.Center,
                        style = MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                        fontSize = 14.sp,)
                    Spacer(modifier = Modifier.weight(1f))
                }
            }
            Spacer(modifier = Modifier.height(14.dp))
            OutlinedButton(
                border = BorderStroke(1.dp, Color(0xFFD9D9D9)),
                modifier = Modifier.fillMaxWidth(),onClick = {
                viewModel.updateTask(task.copy(priority = "Low"))
                priorityChange.value=false
            },){
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Image(
                        painter = painterResource(Res.drawable.low_flag),
                        contentDescription = null,
                    )
                    Spacer(modifier = Modifier.width(4.dp))
                    Text("Low", color = Color(0xFF03264A), textAlign = TextAlign.Center,
                        style = MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                        fontSize = 14.sp,)
                    Spacer(modifier = Modifier.weight(1f))
                }
            }

        }


}

@ExperimentalComposeUiApi
@Composable
fun AutoSubmittingRicthTextEditor(basicRichTextState: RichTextState, onClick: () -> Unit, textStyle: TextStyle,modifier: Modifier=Modifier) {
    val keyboardController = LocalSoftwareKeyboardController.current

    val isInitialLoad = remember { mutableStateOf(true) }

    LaunchedEffect(basicRichTextState.toHtml()) {
        if (isActive) {
            if (isInitialLoad.value) {
                isInitialLoad.value = false
            } else {
                delay(1300L) // Wait for 1 second after user stops typing

               // if (basicRichTextState.annotatedString.text.isNotBlank()) {
                    // Perform your action here, e.g., submit the text
                    keyboardController?.hide()
                    // Update Task
                    onClick()
               // }
            }

        }
    }

    BasicRichTextEditor(
        modifier = modifier.fillMaxWidth().padding(start = 16.dp),
        state = basicRichTextState,
        textStyle = textStyle,
        keyboardOptions = KeyboardOptions(
            keyboardType = KeyboardType.Text,
            imeAction = ImeAction.Done
        ),
        keyboardActions = KeyboardActions(
            onDone = { keyboardController?.hide() }
        ), readOnly = false
        , enabled = true

    )

}

@Composable
fun Modifier.simpleVerticalScrollbar(
    state: LazyListState,
    width: Dp = 8.dp
): Modifier {
    val targetAlpha = if (state.isScrollInProgress) 1f else 0f
    val duration = if (state.isScrollInProgress) 150 else 500

    val alpha by animateFloatAsState(
        targetValue = targetAlpha,
        animationSpec = tween(durationMillis = duration)
    )

    return drawWithContent {
        drawContent()

        val firstVisibleElementIndex = state.layoutInfo.visibleItemsInfo.firstOrNull()?.index
        val needDrawScrollbar = state.isScrollInProgress || alpha > 0.0f

        // Draw scrollbar if scrolling or if the animation is still running and lazy column has content
        if (needDrawScrollbar && firstVisibleElementIndex != null) {
            val elementHeight = this.size.height / state.layoutInfo.totalItemsCount
            val scrollbarOffsetY = firstVisibleElementIndex * elementHeight
            val scrollbarHeight = state.layoutInfo.visibleItemsInfo.size * elementHeight

            drawRect(
                color = Color.Gray,
                topLeft = Offset(this.size.width - width.toPx(), scrollbarOffsetY),
                size = Size(width.toPx(), scrollbarHeight),
                alpha = alpha
            )
        }
    }
}

@Composable
fun Modifier.simpleHorizontalScrollbar(
    state: LazyListState,
    height: Float = 12f,
    backgroundColor: Color = Color.DarkGray,
    color: Color = Color.Red
): Modifier {

    return drawWithContent {
        drawContent()

        val firstVisibleElementIndex = state.layoutInfo.visibleItemsInfo.firstOrNull()?.index

        if (firstVisibleElementIndex != null) {

            val scrollableItems = state.layoutInfo.totalItemsCount - state.layoutInfo.visibleItemsInfo.size
            val scrollBarWidth = this.size.width / scrollableItems
            var offsetX = ((this.size.width - scrollBarWidth) * firstVisibleElementIndex) / scrollableItems

            drawRect(
                color = backgroundColor,
                topLeft = Offset(x = 0f, y = this.size.height),
                size = Size(this.size.width, height),
                alpha = 1f
            )

            drawRect(
                color = color,
                topLeft = Offset(x = offsetX, y = this.size.height),
                size = Size(scrollBarWidth, height),
                alpha = 1f
            )
        }
    }
}