/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models

import org.openapitools.client.models.CategoryItem

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param projectId 
 * @param featureId 
 * @param name 
 * @param description 
 * @param priority 
 * @param taskStatus 
 * @param assignedTo 
 * @param category 
 * @param scheduledDate 
 * @param dueDate 
 * @param startTime 
 * @param stopTime 
 * @param estimatedTimeInMS 
 * @param attachments 
 */
@Serializable

data class UpdateTaskRequest (

    @SerialName(value = "projectId") val projectId: kotlin.String? = null,

    @SerialName(value = "featureId") val featureId: kotlin.String? = null,

    @SerialName(value = "name") val name: kotlin.String? = null,

    @SerialName(value = "description") val description: kotlin.String? = null,

    @SerialName(value = "priority") val priority: kotlin.String? = null,

    @SerialName(value = "taskStatus") val taskStatus: kotlin.String? = null,

    @SerialName(value = "assignedTo") val assignedTo: kotlin.String? = null,

    @SerialName(value = "category") val category: CategoryItem? = null,

    @SerialName(value = "scheduledDate") val scheduledDate: String? = null,

    @SerialName(value = "dueDate") val dueDate: String? = null,

    @SerialName(value = "startTime") val startTime: kotlin.String? = null,

    @SerialName(value = "stopTime") val stopTime: kotlin.String? = null,

    @SerialName(value = "estimatedTimeInMS") val estimatedTimeInMS: kotlin.Double? = null,

    @SerialName(value = "attachments") val attachments: kotlin.collections.List<kotlin.String>? = null,

    @SerialName(value = "capacityType") val capacityType: kotlin.String? = null,

    @SerialName(value = "capacityValue") val capacityValue: kotlin.String? = null,

)

