/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models

import org.openapitools.client.models.CategoryItem
import org.openapitools.client.models.TaskItemComments
import org.openapitools.client.models.TaskStatusDetailsResponse
import org.openapitools.client.models.UserRegistration

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param id 
 * @param parentTaskId 
 * @param taskType 
 * @param taskStatus 
 * @param status 
 * @param projectId 
 * @param featureId 
 * @param name 
 * @param description 
 * @param priority 
 * @param assignedTo 
 * @param category 
 * @param scheduledDate 
 * @param dueDate 
 * @param createdAt 
 * @param createdBy 
 * @param attachments 
 * @param startTime 
 * @param stopTime 
 * @param totalTimeInMS 
 * @param totalTimeInStr 
 * @param estimatedTimeInMS 
 * @param taskNumber 
 * @param subTasksCount 
 * @param doneSubTasksCount 
 * @param capacityType 
 * @param capacityValue 
 * @param assignedToDetails 
 * @param taskStatusDetails 
 * @param comments 
 * @param blockers 
 * @param commentCount 
 * @param blockerCount 
 */
@Serializable

data class TaskItem (

    @SerialName(value = "id") var id: kotlin.String? = null,

    @SerialName(value = "parentTaskId") var parentTaskId: kotlin.String? = null,

    @SerialName(value = "taskType") var taskType: kotlin.String? = null,

    @SerialName(value = "taskStatus") var taskStatus: kotlin.String? = null,

    @SerialName(value = "status") var status: kotlin.String? = null,

    @SerialName(value = "projectId") var projectId: kotlin.String? = null,

    @SerialName(value = "featureId") var featureId: kotlin.String? = null,

    @SerialName(value = "name") var name: kotlin.String? = null,

    @SerialName(value = "description") var description: kotlin.String? = null,

    @SerialName(value = "priority") var priority: kotlin.String? = null,

    @SerialName(value = "assignedTo") var assignedTo: kotlin.String? = null,

    @SerialName(value = "category") var category: CategoryItem? = null,

    @SerialName(value = "scheduledDate") var scheduledDate: String? = null,

    @SerialName(value = "dueDate") var dueDate: String? = null,

    @SerialName(value = "createdAt") var createdAt: kotlin.String? = null,

    @SerialName(value = "createdBy") var createdBy: kotlin.String? = null,

    @SerialName(value = "attachments") var attachments: MutableList<String>? = null,

    @SerialName(value = "startTime") var startTime: kotlin.String? = null,

    @SerialName(value = "stopTime") var stopTime: kotlin.String? = null,

    @SerialName(value = "totalTimeInMS") var totalTimeInMS: kotlin.Double? = null,

    @SerialName(value = "totalTimeInStr") var totalTimeInStr: kotlin.String? = null,

    @SerialName(value = "estimatedTimeInMS") var estimatedTimeInMS: kotlin.Double? = null,

    @SerialName(value = "taskNumber") var taskNumber: kotlin.String? = null,

    @SerialName(value = "subTasksCount") var subTasksCount: kotlin.Double? = null,

    @SerialName(value = "doneSubTasksCount") var doneSubTasksCount: kotlin.Double? = null,

    @SerialName(value = "capacityType") var capacityType: kotlin.String? = null,

    @SerialName(value = "capacityValue") var capacityValue: kotlin.String? = null,

    @SerialName(value = "assignedToDetails") var assignedToDetails: UserRegistration? = null,

    @SerialName(value = "taskStatusDetails") var taskStatusDetails: TaskStatusDetailsResponse? = null,

    @SerialName(value = "comments") var comments: TaskItemComments? = null,

    @SerialName(value = "blockers") var blockers: MutableList<TaskItem>? = null,

    @SerialName(value = "commentCount") var commentCount: kotlin.Int? = null,

    @SerialName(value = "blockerCount") var blockerCount: kotlin.Int? = null

)

