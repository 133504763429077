/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models

import org.openapitools.client.models.Pagination
import org.openapitools.client.models.Project

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param code 
 * @param message 
 * @param `data` 
 * @param pagination 
 */
@Serializable

data class ProjectListResponse (

    @SerialName(value = "code") val code: kotlin.String? = null,

    @SerialName(value = "message") val message: kotlin.String? = null,

    @SerialName(value = "data") val `data`: kotlin.collections.List<Project>? = null,

    @SerialName(value = "pagination") val pagination: Pagination? = null

)

