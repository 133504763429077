/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param name 
 * @param focus 
 * @param role 
 * @param areasOfInterest 
 * @param avatar 
 * @param userName 
 */
@Serializable

data class UserUpdateRequest (

    @SerialName(value = "name") @Required val name: kotlin.String?,

    @SerialName(value = "focus") @Required val focus: kotlin.String?,

    @SerialName(value = "role") @Required val role: kotlin.String?,

    @SerialName(value = "areasOfInterest") val areasOfInterest: kotlin.String? = null,

    @SerialName(value = "avatar") val avatar: kotlin.String? = null,

    @SerialName(value = "userName") val userName: kotlin.String? = null

)

