import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.layout.*
import androidx.compose.material.LinearProgressIndicator
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.platform.Font
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.CanvasBasedWindow
import app.softwork.routingcompose.HashRouter
import app.softwork.routingcompose.Router
import cafe.adriel.voyager.navigator.Navigator
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import com.seiko.imageloader.Bitmap
import org.company.app.di.initKoin
import com.seiko.imageloader.ImageLoader
import com.seiko.imageloader.LocalImageLoader
import com.seiko.imageloader.cache.memory.MemoryCacheBuilder
import com.seiko.imageloader.cache.memory.MemoryKey
import com.seiko.imageloader.component.setupDefaultComponents
import com.seiko.imageloader.intercept.bitmapMemoryCacheConfig
import kotlinx.browser.window
import kotlinx.coroutines.delay
import okio.FileSystem
import okio.fakefilesystem.FakeFileSystem
import org.company.app.commonConfig
import org.company.app.presentation.ui.HomeScreen
import org.company.app.presentation.ui.LoginScreen
import org.company.app.presentation.ui.RegisterScreen
import org.company.app.presentation.ui.carddetails.CardDetailsScreen
import org.company.app.presentation.viewmodel.HomeViewModel
import org.company.app.theme.Sf
import org.company.app.util.Constants
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.configureWebResources
import org.jetbrains.compose.resources.readResourceBytes

import org.jetbrains.skiko.wasm.onWasmReady
import org.koin.compose.koinInject

@OptIn(ExperimentalComposeUiApi::class, ExperimentalResourceApi::class)
fun main() {
    configureWebResources {
        // Not necessary - It's the same as the default. We add it here just to present this feature.
        resourcePathMapping { path -> "./$path" }
    }
    onWasmReady {

        CanvasBasedWindow("ZiFocus") {

            CompositionLocalProvider(

            ) {

                var loading: Boolean by remember { mutableStateOf(true) }



                if (loading) {
                    LinearProgressIndicator(modifier = Modifier.fillMaxWidth())
                } else {
                    HashRouter(initPath = "/login") { // or BrowserRouter(initPath = "/hello") {
                        route("/hello") {
                            Text("Hello World")
                        }
                        route("/clear"){
                            val settings: Settings = Settings()
                            settings.clear()
                        }
                        route("/inviteToWorkspace") {
                            val router = Router.current
                            val params: Map<String, List<String>>? = parameters?.map
                            var boardId = remember { mutableStateOf(params?.get("boardId")?.firstOrNull()) }
                            val settings: Settings = Settings()
                            settings["board_invite"] = boardId.value?:""
                            val viewModel = koinInject<HomeViewModel>()
                            val homeState = viewModel.homeState.collectAsState()
                            if (settings.get("access_token","").isEmpty()){
                                Text("Sign in First to accept the invite")
                                LaunchedEffect(Unit){
                                    delay(2000L)
                                    router.navigate("/login")
                                }
                            } else {
                                viewModel.acceptWorkspaceInvite(boardId.value)
                                Constants.token = settings["access_token", ""]
                                Column(modifier = Modifier.fillMaxSize(), verticalArrangement = Arrangement.Center,
                                    horizontalAlignment = Alignment.CenterHorizontally) {
                                    AnimatedVisibility(homeState.value.acceptedBoardInvite==null){
                                        Column(verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally) {
                                            Text("Adding you to the board", fontSize = 28.sp, fontFamily = MaterialTheme.typography.bodyLarge.fontFamily,
                                                style = MaterialTheme.typography.bodyLarge)
                                            CircularProgressIndicator()
                                        }

                                    }
                                }


                                if (homeState.value.acceptedBoardInvite != null){
                                    settings.remove("board_invite")
                                    router.navigate("/home")
                                }

                            }

                        }
                        route("/login") {
                            val router = Router.current
                            val settings: Settings = Settings()
                            val homeViewModel = koinInject<HomeViewModel>()
                            if (settings["access_token", ""] !=""){
                                homeViewModel.getUser{
                                    if (it?.isEmailVerified==true){
                                        router.navigate("/home")
                                    }
                                }

                            }

                            Navigator(LoginScreen(nextWithRouter = {
                                if (settings["board_invite", ""] == ""){
                                    router.navigate("/home")
                                } else {
                                    router.navigate("/inviteToWorkspace?boardId=${window.localStorage.getItem("board_invite")}")
                                }

                                // Save the token to local storage

                            },
                                registerWithRouter = {
                                    router.navigate("/register")
                                }))

                            val params: Map<String, List<String>>? = parameters?.map

                        }
                        route("/register"){
                            val router = Router.current

                            Navigator(RegisterScreen(
                                nextWithRouter = {
                                    router.navigate("/home")
                                }
                                ,backWithRouter = {
                                    router.navigate("/login")
                                }))
                        }
                        route("/home"){
                            val settings: Settings = Settings()
                            val router = Router.current

                            if (settings.get("access_token","").isEmpty()){
                                    router.navigate("/login")
                                    settings.clear()
                            }

                            val viewModel = koinInject<HomeViewModel>()
                            val params: Map<String, List<String>>? = parameters?.map
                            var taskUrlId = remember { mutableStateOf(params?.get("taskId")?.firstOrNull()) }
                            Navigator(HomeScreen(navigateWithRouterCardDetails = {
                                val currentTask = viewModel.homeState.value.task?.id
                                router.navigate("/home?taskId=${currentTask}")
                            },
                                navigateBackFromCardDetails = {
                                    router.navigate("/home")
                                    taskUrlId.value = null
                                }, navigateAfterSignOut = {
                                    router.navigate("/login")
                                },taskId =taskUrlId))

                        }
                        route("/taskDetails"){
                            if (Constants.token==""){
                                Constants.token = window.localStorage.getItem("access_token")?:""
                            }
                            val viewModel = koinInject<HomeViewModel>()
                            val params: Map<String, List<String>>? = parameters?.map
                            viewModel.getTaskDetails(params?.get("id")?.firstOrNull())
                            Navigator(CardDetailsScreen())
                        }
                    }
                }
                LaunchedEffect(Unit) {
                    loadSfFont()
                    initKoin()
                    loading = false
                }

            }

        }


    }
}





@OptIn(ExperimentalResourceApi::class)
private suspend fun loadSfFont() {
    val regular = loadResource("sf_regular.otf")
    val medium = loadResource("sf_medium.otf")
    val semiBold = loadResource("sf_semibold.otf")
    val bold = loadResource("sf_bold.otf")

    Sf = FontFamily(
        Font(identity = "SFProRegular", data = regular, weight = FontWeight.Normal),
        Font(identity = "SFProMedium", data = medium, weight = FontWeight.Medium),
        Font(identity = "SFProBold", data = bold, weight = FontWeight.Bold),
        Font(identity = "SFProSemiBold", data = semiBold, weight = FontWeight.SemiBold),
    )
}

@OptIn(InternalResourceApi::class)
internal suspend fun loadResource(resourcePath: String): ByteArray {
    return readResourceBytes(resourcePath)
}




