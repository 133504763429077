/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param name 
 * @param `value` 
 * @param order 
 * @param considerCompleted 
 * @param isBlocker 
 */
@Serializable

data class TaskStatusDetailsRequest (

    @SerialName(value = "name") val name: kotlin.String? = null,

    @SerialName(value = "value") val `value`: kotlin.String? = null,

    @SerialName(value = "order") val order: kotlin.Int? = null,

    @SerialName(value = "considerCompleted") val considerCompleted: kotlin.Boolean? = null,

    @SerialName(value = "isBlocker") val isBlocker: kotlin.Boolean? = null

)

