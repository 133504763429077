package org.company.app.presentation.ui.onBoarding

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.DropdownMenuItem
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowBack
import androidx.compose.material.icons.filled.ArrowForward
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.Navigator
import cafe.adriel.voyager.navigator.currentOrThrow

import org.company.app.platformName
import org.company.app.presentation.component.ZiFocusDropDownTextField
import org.company.app.presentation.component.ZiFocusTextField
import org.company.app.presentation.ui.HomeScreen
import org.company.app.presentation.viewmodel.HomeViewModel
import org.company.app.theme.AppTheme
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.koin.compose.koinInject
import org.openapitools.client.models.UserRegistration

class CreateOrganizationScreen(val user: UserRegistration,val nextWithRouter: () -> Unit={}) : Screen {
    @OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        val windowSizeClass = calculateWindowSizeClass()
        val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
        AppTheme {
            Column(verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally, modifier = Modifier.fillMaxSize().padding(16.dp)) {
                BoardNameContent(useNavRail,navigator)
            }
        }


    }
    @OptIn(ExperimentalResourceApi::class, ExperimentalMaterial3Api::class)
    @Composable
    fun BoardNameContent(useNavRail: Boolean, navigator: Navigator){

            var boardName = remember { mutableStateOf("") }

            val viewModel = koinInject<HomeViewModel>()
            val onBoardingState by viewModel.homeState.collectAsState()
            var createBoardClicked by remember { mutableStateOf(false) }

        val tooLOptions = listOf("Select","Clickup", "Monday", "Notion", "Flat", "Craft.io")
        var toolUsed = remember { mutableStateOf(tooLOptions[0]) }

        val peoplePptions = listOf("Select","Only me", "0-10", "11-50", "51-100", "101-500")
        var numPeopleOptions = remember { mutableStateOf(peoplePptions[0]) }



            if (onBoardingState.errorMessage!=null) {

                AlertDialog(
                    onDismissRequest = {  },
                    properties = DialogProperties(
                        dismissOnClickOutside = false,
                        dismissOnBackPress = true
                    ),
                    title = {  },
                    text = { Text(onBoardingState.errorMessage?:"Error") },
                    confirmButton = {
                        Button(
                            modifier = Modifier.fillMaxWidth(),
                            onClick = {
                                viewModel.clearUIState()

                            }
                        ) {
                            Text("Dismiss")
                        }
                    }
                )
            }




            Column(horizontalAlignment = Alignment.CenterHorizontally, verticalArrangement = Arrangement.Center,
                modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f)) {
                Text("Create an organization", modifier = Modifier.padding(top = 8.dp),fontSize = 32.sp,
                    style = MaterialTheme.typography.bodyLarge
                    ,
                    color = Color.Black)
                Spacer(modifier = Modifier.height(32.dp))
                ZiFocusTextField(text = boardName,
                    placeHolder = "Organization name",
                    modifier = Modifier.padding(8.dp),
                    textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp)
                )
                Spacer(modifier = Modifier.height(32.dp))

                Column {
                    Text(
                        text = "How many people work in your company?",
                        modifier = Modifier.padding(bottom = 8.dp,start = 8.dp),
                        style = MaterialTheme.typography.bodyLarge

                    )
                    Spacer(modifier = Modifier.height(24.dp))
                    var expanded by remember { mutableStateOf(false) }

                    ExposedDropdownMenuBox(
                        expanded = expanded,
                        onExpandedChange = { expanded = !expanded },
                    ) {
                        ZiFocusDropDownTextField(
                            text = numPeopleOptions,
                            placeHolder = "Select an option",
                            trailingIcon = {
                                ExposedDropdownMenuDefaults.TrailingIcon(
                                    expanded = expanded
                                )
                            },
                            modifier = Modifier.menuAnchor().padding(8.dp)
                        )

                        ExposedDropdownMenu(
                            expanded = expanded,
                            onDismissRequest = { expanded = false },
                            modifier = Modifier.background(Color(0xFFFAFAFA))
                        ) {
                            peoplePptions.filter { it!="Select" }.forEach { option ->
                                DropdownMenuItem(onClick = {
                                    numPeopleOptions.value = option
                                    expanded = false
                                }) {
                                    Text(text = option, style = MaterialTheme.typography.bodySmall)
                                }
                            }
                        }
                    }
                }
                Spacer(modifier = Modifier.height(32.dp))
                Column {
                    Text(
                        text = "What tool is your team using currently?",
                        modifier = Modifier.padding(bottom = 8.dp,start = 8.dp),
                        style = MaterialTheme.typography.bodyLarge

                    )
                    Spacer(modifier = Modifier.height(24.dp))
                    var expanded by remember { mutableStateOf(false) }

                    ExposedDropdownMenuBox(
                        expanded = expanded,
                        onExpandedChange = { expanded = !expanded },
                    ) {
                        ZiFocusDropDownTextField(
                            text = toolUsed,
                            placeHolder = "Select an option",
                            trailingIcon = {
                                ExposedDropdownMenuDefaults.TrailingIcon(
                                    expanded = expanded
                                )
                            },
                            modifier = Modifier.menuAnchor().padding(8.dp)
                        )

                        ExposedDropdownMenu(
                            expanded = expanded,
                            onDismissRequest = { expanded = false },
                            modifier = Modifier.background(Color(0xFFFAFAFA))
                        ) {
                            tooLOptions.filter { it!="Select" }.forEach { option ->
                                DropdownMenuItem(onClick = {
                                    toolUsed.value = option
                                    expanded = false
                                }) {
                                    Text(text = option, style = MaterialTheme.typography.bodySmall)
                                }
                            }
                        }
                    }
                }



            }
            Spacer(modifier = Modifier.height(32.dp))
            Row(modifier =  Modifier.fillMaxSize(if (useNavRail) 0.5f else 1f)) {
                OutlinedButton(onClick = {
                    navigator.pop()
                },
                    shape = RoundedCornerShape(10.dp)
                ) {
                    Icon(imageVector = (Icons.Default.ArrowBack), contentDescription = null, tint = Color.Black)
                    Text("Back", fontSize = 15.sp,
                        style = MaterialTheme.typography.displayMedium
                        , color = Color.Black)

                }
                Spacer(modifier = Modifier.weight(1f))
                Button(onClick = {
                    createBoardClicked = true
                    viewModel.createBoard(name=boardName.value,toolUsed = toolUsed.value, numPeopleOptions = numPeopleOptions.value){
                        viewModel.saveUser(user)
                        if (platformName() =="Web") nextWithRouter()
                        else navigator.push(HomeScreen())
                    }
                }, enabled = boardName.value.isNotEmpty()
                    , shape = RoundedCornerShape(10.dp)) {
                    Text("Next", fontSize = 15.sp, style = MaterialTheme.typography.displayMedium)
                    Icon(imageVector = (Icons.Default.ArrowForward), contentDescription = null)
                }
            }
        AnimatedVisibility(createBoardClicked){
                CircularProgressIndicator()
        }

            /*
            Column(modifier = if (useNavRail) Modifier.padding(top = 100.dp, end = 113.dp) else Modifier) {
                Text("Let's start working together"
                    , fontSize = if(useNavRail)32.sp else 20.sp
                    , fontFamily = fontFamilyResource(MR.fonts.sf.bold)
                )
                Text("Give your board a name, e.g. marketing plan, sales\n pipeline, quarterly roadmap.",
                    fontSize = if(useNavRail)17.sp else 14.sp,
                    fontWeight = FontWeight.Light,
                    fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                )
                OutlinedTextField(
                    value = boardName,
                    onValueChange = { boardName = it },
                    label = { Text("Board Name") },
                    singleLine = true,
                    modifier = Modifier.fillMaxWidth().padding(16.dp)
                )
                Spacer(modifier = Modifier.weight(1f))
                Row {
                    OutlinedButton(onClick = {
                        navigator.pop()
                    },
                        shape = RoundedCornerShape(10.dp)
                    ) {
                        Icon(imageVector = (Icons.Default.ArrowBack), contentDescription = null, tint = Color.Black)
                        Text("Back", fontSize = 17.sp, fontFamily = fontFamilyResource(MR.fonts.sf.semibold), color = Color.Black)

                    }
                    Spacer(modifier = Modifier.weight(1f))
                    Button(onClick = {
                      //  navigator.push(HomeScreen())
                    }, enabled = boardName.isNotEmpty()
                        , shape = RoundedCornerShape(10.dp)) {
                        Text("Next", fontSize = 17.sp, fontFamily = fontFamilyResource(MR.fonts.sf.semibold))
                        Icon(imageVector = (Icons.Default.ArrowForward), contentDescription = null)
                    }


                }
                Spacer(modifier = Modifier.height(120.dp))
                if (!useNavRail) Spacer(Modifier.weight(1f))
            }
            */



    }
}