package org.company.app.data.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VerifyOtpData(
    @SerialName("id"         ) var id         : String?  = null,
    @SerialName("email"      ) var email      : String?  = null,
    @SerialName("otp"        ) var otp        : Int?     = null,
    @SerialName("expiryDate" ) var expiryDate : String?  = null,
    @SerialName("verified"   ) var verified   : Boolean? = null

)
