package org.company.app.util

object Constants {
    const val BASE_URL = "https://kpe4gb716l.execute-api.ap-south-1.amazonaws.com/production"
    const val UPLOAD_URL = "https://bjfn6d54vk.execute-api.ap-south-1.amazonaws.com"
    const val env = "production"
    const val WEBSITE_URL = "https://zifoco.zimozi.xyz"
    const val PARAM_AUTH = "Authorization"
    var token =  ""
    const val FirebaseKey = "AIzaSyCwTCYFS7TM0i2bnAA01uhHje5QHKS-mlk"
    const val REPO_OWNER = "ZiFOCO"

    const val REPO_NAME = "ZiPages"

    const val GITHUB_BASE_URL = "https://api.github.com/repos/$REPO_OWNER/$REPO_NAME/contents"
}

