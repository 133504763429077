package org.company.app.presentation.ui

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.*

import androidx.compose.foundation.shape.RoundedCornerShape


import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color

import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight

import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.Navigator
import cafe.adriel.voyager.navigator.currentOrThrow
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.google_
import multiplatform_app.composeapp.generated.resources.zifocus_logo


import org.company.app.platformName
import org.company.app.presentation.component.ZiFocusPasswordTextField
import org.company.app.presentation.component.ZiFocusTextField
import org.company.app.presentation.ui.onBoarding.OtpVerificationScreen
import org.company.app.presentation.ui.onBoarding.UsageIdentificationScreen
import org.company.app.presentation.viewmodel.LoginViewModel
import org.company.app.presentation.viewmodel.RegisterViewModel

import org.company.app.theme.AppTheme

import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject

class RegisterScreen(val nextWithRouter: () -> Unit={},val backWithRouter: () -> Unit={}) : Screen {
    @OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        RegisterScreenContent(navigator)
    }
    @OptIn(ExperimentalResourceApi::class, ExperimentalMaterial3WindowSizeClassApi::class)
    @Composable
    fun RegisterScreenContent(navigator: Navigator){
        AppTheme {
            val email = remember { mutableStateOf("") }
            val password = remember { mutableStateOf("") }
            val emailError = remember { mutableStateOf("invalid email") }
            var isEmailValid by remember { mutableStateOf(true) }
            var isPasswordValid by remember { mutableStateOf(true) }
            val signUpEnabled = remember { derivedStateOf { isEmailValid && isPasswordValid } }
            val windowSizeClass = calculateWindowSizeClass()
            val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
            var agreeToTerms by remember { mutableStateOf(false) }
            var registerClicked by remember { mutableStateOf(false) }
            val viewModel = koinInject<RegisterViewModel>()
            val registerState by viewModel.registerState.collectAsState()
            val loginViewModel = koinInject<LoginViewModel>()
            val loginState by loginViewModel.loginState.collectAsState()
            LaunchedEffect(Unit){
                viewModel.loadImages()
            }

            if (registerState.registerResponse?.data!=null){
                navigator.push(OtpVerificationScreen(email.value,nextWithRouter))
                viewModel.clearRegisterUIState()
            }

            if (registerState.errorMessage!=null){
                viewModel.setOpenDialog(true)
            }

            if (registerState.errorMessage!=null) {
                AlertDialog(
                    onDismissRequest = {  },
                    properties = DialogProperties(
                        dismissOnClickOutside = false,
                        dismissOnBackPress = true
                    ),
                    title = { Text(text = "Register Failed") },
                    text = {
                        Text(registerState.errorMessage?:"Error")
                        emailError.value = registerState.errorMessage?.split("_")?.joinToString(" ")?:"invalid email"
                        isEmailValid= false
                           },
                    confirmButton = {
                        Button(
                            modifier = Modifier.fillMaxWidth(),
                            onClick = {
                                viewModel.clearRegisterUIState()
                                registerClicked = false
                            }
                        ) {
                            Text("Dismiss")
                        }
                    }
                )
            }

            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
                modifier =
                if (useNavRail) Modifier.fillMaxSize().windowInsetsPadding(WindowInsets.safeDrawing).padding( top = 30.dp)
                else Modifier.fillMaxSize().windowInsetsPadding(WindowInsets.safeDrawing).padding(top = 30.dp)
            ) {
                //  Spacer(modifier = Modifier.height(69.dp))
                if (!useNavRail) Image(painterResource(Res.drawable.zifocus_logo),contentDescription = "logo")
                Row(modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f)) {
                    Text(
                        text = if (useNavRail) "Get started on your journey\ntoday!" else "Get started on your journey today!",
                        modifier = Modifier.padding(8.dp),
                        fontSize = if (useNavRail )32.sp else 20.sp,
                        style = if (useNavRail )MaterialTheme.typography.bodyLarge else MaterialTheme.typography.displayMedium,
                        fontFamily = if (useNavRail )MaterialTheme.typography.bodyLarge.fontFamily else MaterialTheme.typography.displayMedium.fontFamily,
                        fontWeight = FontWeight.Bold,
                        lineHeight = 40.sp,
                        color = Color(0xFF03264A)
                    )
                }


                Spacer(modifier = Modifier.height(12.dp))

                ZiFocusTextField(
                    value = email.value,
                    onValueChange = {
                        email.value = it
                        isEmailValid = isValidEmail(it)
                    },
                    placeHolder = "Type Email",
                    modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(horizontal = 8.dp),
                    isError = !isEmailValid,
                    supportingText = emailError.value

                )



                Spacer(modifier = Modifier.height(32.dp))
                ZiFocusPasswordTextField(
                    value = password.value,
                    onValueChange = {
                        password.value = it
                        isPasswordValid = it.length>=8
                    },
                    placeHolder = "Type Password",
                    modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(8.dp),
                    isError = !isPasswordValid,
                    supportingText = "invalid password"
                )



                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center,modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f)) {
                    Checkbox(
                        checked = agreeToTerms,
                        onCheckedChange = { agreeToTerms = it }
                    )
                    Text(text = buildAnnotatedString {
                        append("By Signing Up I agree with ")
                        withStyle(
                            SpanStyle(
                                color = MaterialTheme.colorScheme.primary,
                                textDecoration = TextDecoration.Underline
                            )
                        ) {
                            append("Terms & Conditions")
                        }
                    }, fontSize = 15.sp,
                        style =  MaterialTheme.typography.bodySmall
                       // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        , modifier = Modifier.padding(start = 4.dp))
                    Spacer(modifier = Modifier.weight(1f))
                }
                Button(
                    onClick = {
                        viewModel.register(email.value, password.value)
                        registerClicked = true
                    },
                    shape = RoundedCornerShape(10.dp),
                    modifier = Modifier.padding(16.dp).width(213.dp),
                    enabled = signUpEnabled.value && agreeToTerms
                ) {
                    Text("Register",
                        color = Color(0xFFFAFAFA),
                        fontFamily = MaterialTheme.typography.displayMedium.fontFamily,
                        style = MaterialTheme.typography.displayMedium,
                        fontSize = 17.sp)
                }
                AnimatedVisibility(registerClicked){
                    CircularProgressIndicator()
                }
                Spacer(modifier = Modifier.height(24.dp))
                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                    Divider(modifier = Modifier.width(130.dp))
                    Text("or", fontSize = 15.sp,
                        style = MaterialTheme.typography.bodySmall, modifier = Modifier.padding(bottom = 8.dp).padding(horizontal = 4.dp))
                    Divider(modifier = Modifier.width(130.dp))
                }
                Spacer(modifier = Modifier.height(24.dp))



                OutlinedButton(onClick = {},shape = RoundedCornerShape(10.dp),){
                    Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                        Image(painterResource(Res.drawable.google_),contentDescription = null,
                            modifier = Modifier.size(24.dp).padding(end = 4.dp))
                        Text("Sign up using google", color = Color.Black,fontSize = 15.sp, style = MaterialTheme.typography.bodySmall)
                    }
                }


                Spacer(modifier = Modifier.height(12.dp))
                Text(
                    text = buildAnnotatedString {
                        append("Already have an account? ")
                        withStyle(
                            SpanStyle(
                              //  fontFamily = fontFamilyResource(MR.fonts.sf.semibold),

                                fontFamily =  MaterialTheme.typography.displayMedium.fontFamily,
                                fontStyle =  MaterialTheme.typography.displayMedium.fontStyle,
                                fontSize = 15.sp,
                                color = MaterialTheme.colorScheme.primaryContainer
                            )
                        ) {
                            append("Login")
                        }
                    },
                    color = Color(0xFF03264A).copy(alpha = 0.8f),
                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                    style = MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    fontSize = 15.sp,
                   // fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                    modifier =Modifier.fillMaxWidth().padding(16.dp).clickable(onClick = {
                        if (platformName()=="Web") backWithRouter()
                        else navigator.replace(LoginScreen())

                    }, interactionSource = MutableInteractionSource(), indication = null),
                    textAlign = TextAlign.Center
                )
            }
        }
    }

}


