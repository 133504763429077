package org.company.app.data.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SendOtpResponse(
    @SerialName("code"       ) var code       : String?         = null,
    @SerialName("message"    ) var message    : String?         = null,
    @SerialName("data"       ) var data       : SendOtpData? = SendOtpData(),
)
