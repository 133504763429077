package org.company.app.di

import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import io.github.aakira.napier.DebugAntilog
import io.github.aakira.napier.Napier
import io.ktor.client.HttpClient
import io.ktor.client.plugins.HttpResponseValidator
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.HttpStatusCode
import io.ktor.http.URLProtocol
import io.ktor.http.contentType
import io.ktor.http.isSuccess
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.Json
import org.company.app.util.Constants
import org.company.app.util.HttpExceptions
import org.koin.dsl.module

@OptIn(ExperimentalSerializationApi::class)
val networkModule = module {
    single {
        val settings: Settings = Settings()
        HttpClient {
            // setting the `expectSuccess` property to `true.` This terminates `HttpClient.receivePipeline` if the status code is unsuccessful.
            expectSuccess = true
            install(ContentNegotiation) {
                json(
                    Json {
                        ignoreUnknownKeys = true
                        prettyPrint = true
                        isLenient = true
                        explicitNulls = false
                    }
                )
            }
            HttpResponseValidator {
                validateResponse { response ->

                    if (!response.status.isSuccess()) {
                        val httpFailureReason = when (response.status) {
                            HttpStatusCode.Unauthorized -> "Unauthorized request"
                            HttpStatusCode.Forbidden -> "${response.status.value} Missing API key"
                            HttpStatusCode.NotFound -> "Invalid Request"
                            HttpStatusCode.UpgradeRequired -> "Upgrade to VIP"
                            HttpStatusCode.RequestTimeout -> "Network Timeout"
                            in HttpStatusCode.InternalServerError..HttpStatusCode.GatewayTimeout -> "${response.status.value} Server Error"
                            else -> "Network error!"
                        }

                        throw HttpExceptions(
                            response = response,
                            cachedResponseText = response.bodyAsText(),
                            failureReason = httpFailureReason,
                        )
                    }
                }
            }

            install(Logging) {
                logger = object : Logger {
                    override fun log(message: String) {
                        Napier.i(tag = "HTTP Client", message = message)
                    }
                }
                level = LogLevel.BODY
            }.also { Napier.base(DebugAntilog()) }

            defaultRequest {
                url {
                 // base URL
                    url(Constants.BASE_URL)
                    protocol = URLProtocol.HTTPS
                    // to add parameter

                       // headers.append(Constants.PARAM_AUTH, Constants.token)
                        headers.append(Constants.PARAM_AUTH, settings["access_token", Constants.token])

                    // to add token  headers.append()
                }
                contentType(ContentType.Application.Json)
            }

        }
    }
}