/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package api

import org.openapitools.client.models.Comment
import org.openapitools.client.models.CommentListResponse
import org.openapitools.client.models.CommentRequest
import org.openapitools.client.models.CommentResponse
import org.openapitools.client.models.InternalComment
import org.openapitools.client.models.InternalCommentResponse
import org.openapitools.client.models.MessageResponse

import org.openapitools.client.infrastructure.*
import io.ktor.client.HttpClient
import io.ktor.client.HttpClientConfig
import io.ktor.client.request.forms.formData
import io.ktor.client.engine.HttpClientEngine
import kotlinx.serialization.json.Json
import io.ktor.http.ParametersBuilder
import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

open class CommentsApi : ApiClient {

    constructor(
        baseUrl: String = ApiClient.BASE_URL,
        httpClientEngine: HttpClientEngine? = null,
        httpClientConfig: ((HttpClientConfig<*>) -> Unit)? = null,
        jsonSerializer: Json = ApiClient.JSON_DEFAULT
    ) : super(baseUrl = baseUrl, httpClientEngine = httpClientEngine, httpClientConfig = httpClientConfig, jsonBlock = jsonSerializer)

    constructor(
        baseUrl: String,
        httpClient: HttpClient
    ): super(baseUrl = baseUrl, httpClient = httpClient)

    /**
     * Create Comment
     * 
     * @param commentRequest Comment Data
     * @param authorization  (optional)
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createComment(commentRequest: CommentRequest, authorization: kotlin.String? = null): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = commentRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/comment",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Comment on Epic
     * 
     * @param comment Comment Data
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createCommentOnEpic(comment: Comment): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = comment

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createCommentOnEpic",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Comment on Feature
     * 
     * @param comment Comment Data
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createCommentOnFeature(comment: Comment): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = comment

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createCommentOnFeature",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Comment on Goal
     * 
     * @param comment Comment Data
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createCommentOnGoal(comment: Comment): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = comment

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createCommentOnGoal",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Comment on Initiative
     * 
     * @param comment Comment Data
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createCommentOnInitiative(comment: Comment): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = comment

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createCommentOnInitiative",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Comment on Note
     * 
     * @param comment Comment Data
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createCommentOnNote(comment: Comment): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = comment

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createCommentOnNote",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Comment on Release
     * 
     * @param comment Comment Data
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createCommentOnRelease(comment: Comment): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = comment

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createCommentOnRelease",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Comment on Release Phase
     * 
     * @param comment Comment Data
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createCommentOnReleasePhase(comment: Comment): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = comment

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createCommentOnReleasePhase",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Comment on Requirement
     * 
     * @param comment Comment Data
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createCommentOnRequirement(comment: Comment): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = comment

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createCommentOnRequirement",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Comment on To-Do
     * 
     * @param comment Comment Data
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createCommentOnToDo(comment: Comment): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = comment

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createCommentOnToDo",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Internal Comment for Idea
     * 
     * @param internalComment Internal Comment Data
     * @return InternalCommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createInternalCommentForIdea(internalComment: InternalComment): HttpResponse<InternalCommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = internalComment

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createInternalCommentForIdea",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Delete Comment
     * 
     * @param commentId The ID of the comment to delete.
     * @param authorization  (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun deleteComment(commentId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.DELETE,
            "/comment/{commentId}".replace("{" + "commentId" + "}", "$commentId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Specific Comment
     * 
     * @param commentId The ID of the comment to retrieve.
     * @param authorization  (optional)
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getCommentDetails(commentId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/comment/{commentId}".replace("{" + "commentId" + "}", "$commentId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments
     * 
     * @param taskId 200
     * @param authorization  (optional)
     * @param nextPageToken asdaksljdfksajf (optional)
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getComments(taskId: kotlin.String, authorization: kotlin.String? = null, nextPageToken: kotlin.String? = null): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        taskId?.apply { localVariableQuery["taskId"] = listOf("$taskId") }
        nextPageToken?.apply { localVariableQuery["nextPageToken"] = listOf("$nextPageToken") }
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/comment",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Specific Comment
     * 
     * @param commentId The ID of the comment to retrieve.
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getSpecificComment(commentId: kotlin.Int): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        commentId?.apply { localVariableQuery["commentId"] = listOf("$commentId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/getSpecificComment",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments in Product
     * 
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listCommentsInProduct(): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listCommentsInProduct",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments on Epic
     * 
     * @param epicId The ID of the epic to list comments for.
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listCommentsOnEpic(epicId: kotlin.Int): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        epicId?.apply { localVariableQuery["epicId"] = listOf("$epicId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listCommentsOnEpic",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments on Feature
     * 
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listCommentsOnFeature(): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listCommentsOnFeature",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments on Goal
     * 
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listCommentsOnGoal(): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listCommentsOnGoal",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments on Idea
     * 
     * @param ideaId The ID of the idea to retrieve comments for.
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listCommentsOnIdea(ideaId: kotlin.Int): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        ideaId?.apply { localVariableQuery["ideaId"] = listOf("$ideaId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listCommentsOnIdea",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments on Initiative
     * 
     * @param initiativeId The ID of the initiative to retrieve comments for.
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listCommentsOnInitiative(initiativeId: kotlin.Int): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        initiativeId?.apply { localVariableQuery["initiativeId"] = listOf("$initiativeId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listCommentsOnInitiative",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments on a Note
     * 
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listCommentsOnNote(): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listCommentsOnNote",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments on a Release
     * 
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listCommentsOnRelease(): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listCommentsOnRelease",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments on a Release Phase
     * 
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listCommentsOnReleasePhase(): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listCommentsOnReleasePhase",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments on Requirement
     * 
     * @param requirementId The ID of the requirement to list comments for.
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listCommentsOnRequirement(requirementId: kotlin.Int): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        requirementId?.apply { localVariableQuery["requirementId"] = listOf("$requirementId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listCommentsOnRequirement",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Comments on To-Do
     * 
     * @param todoId The ID of the to-do to list comments for.
     * @return CommentListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listCommentsOnToDo(todoId: kotlin.Int): HttpResponse<CommentListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        todoId?.apply { localVariableQuery["todoId"] = listOf("$todoId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listCommentsOnToDo",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Update Comment
     * 
     * @param commentId The ID of the comment to update.
     * @param commentRequest Updated Comment Data
     * @param authorization  (optional)
     * @return CommentResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateComment(commentId: kotlin.String, commentRequest: CommentRequest, authorization: kotlin.String? = null): HttpResponse<CommentResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = commentRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/comment/{commentId}".replace("{" + "commentId" + "}", "$commentId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



}
