/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param id 
 * @param email 
 * @param name 
 * @param focus 
 * @param areasOfInterest 
 * @param role 
 * @param avatar 
 * @param status 
 * @param createdAt 
 * @param userPageId 
 * @param userName 
 * @param isEmailVerified 
 */
@Serializable

data class UserRegistration (

    @SerialName(value = "id") var id: kotlin.String? = null,

    @SerialName(value = "email") var email: kotlin.String? = null,

    @SerialName(value = "name") var name: kotlin.String? = null,

    @SerialName(value = "focus") val focus: kotlin.String? = null,

    @SerialName(value = "areasOfInterest") val areasOfInterest: kotlin.collections.List<kotlin.String>? = null,

    @SerialName(value = "role") val role: kotlin.String? = null,

    @SerialName(value = "avatar") var avatar: kotlin.String? = null,

    @SerialName(value = "status") val status: kotlin.String? = null,

    @SerialName(value = "createdAt") val createdAt: kotlin.String? = null,

    @SerialName(value = "userPageId") val userPageId: kotlin.String? = null,

    @SerialName(value = "userName") val userName: kotlin.String? = null,

    @SerialName(value = "isEmailVerified") val isEmailVerified: kotlin.Boolean? = null

)

