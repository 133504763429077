/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package api

import org.openapitools.client.models.CreateFeatureRequest
import org.openapitools.client.models.CreateProjectResponse
import org.openapitools.client.models.Feature
import org.openapitools.client.models.FeatureCustomFields
import org.openapitools.client.models.FeatureCustomFieldsWithTagValue
import org.openapitools.client.models.FeatureCustomWorksheetFields
import org.openapitools.client.models.FeatureDetailResponse
import org.openapitools.client.models.FeatureEpic
import org.openapitools.client.models.FeatureGoals
import org.openapitools.client.models.FeatureListResponse
import org.openapitools.client.models.FeatureProgress
import org.openapitools.client.models.FeatureProgressSource
import org.openapitools.client.models.FeatureRelease
import org.openapitools.client.models.FeatureResponse
import org.openapitools.client.models.FeatureScore
import org.openapitools.client.models.FeatureTags
import org.openapitools.client.models.FeatureTagsCSV
import org.openapitools.client.models.FeatureWatchers
import org.openapitools.client.models.FeatureWithAssignee
import org.openapitools.client.models.FeatureWithGoals
import org.openapitools.client.models.FeatureWithScore
import org.openapitools.client.models.FeatureWithTags
import org.openapitools.client.models.FeatureWithWatchers
import org.openapitools.client.models.MessageResponse
import org.openapitools.client.models.ProjectDetailResponse
import org.openapitools.client.models.ProjectListResponse
import org.openapitools.client.models.ProjectRequest
import org.openapitools.client.models.ProjectResponse
import org.openapitools.client.models.UpdateProjectRequest

import org.openapitools.client.infrastructure.*
import io.ktor.client.HttpClient
import io.ktor.client.HttpClientConfig
import io.ktor.client.request.forms.formData
import io.ktor.client.engine.HttpClientEngine
import kotlinx.serialization.json.Json
import io.ktor.http.ParametersBuilder
import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

open class FeaturesApi : ApiClient {

    constructor(
        baseUrl: String = ApiClient.BASE_URL,
        httpClientEngine: HttpClientEngine? = null,
        httpClientConfig: ((HttpClientConfig<*>) -> Unit)? = null,
        jsonSerializer: Json = ApiClient.JSON_DEFAULT
    ) : super(baseUrl = baseUrl, httpClientEngine = httpClientEngine, httpClientConfig = httpClientConfig, jsonBlock = jsonSerializer)

    constructor(
        baseUrl: String,
        httpClient: HttpClient
    ): super(baseUrl = baseUrl, httpClient = httpClient)

    /**
     * Create Feature
     * 
     * @param createFeatureRequest Feature Data
     * @param authorization Bearer token for authentication (optional)
     * @return FeatureResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createFeature(createFeatureRequest: CreateFeatureRequest, authorization: kotlin.String? = null): HttpResponse<FeatureResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = createFeatureRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/features/createFeature",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Feature with Assignee
     * 
     * @param featureWithAssignee Feature Data with Assignee
     * @param authorization Bearer token for authentication (optional)
     * @return Feature
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createFeatureWithAssignee(featureWithAssignee: FeatureWithAssignee, authorization: kotlin.String? = null): HttpResponse<Feature> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureWithAssignee

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/features/createFeatureWithAssignee",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Feature with Goals
     * 
     * @param featureWithGoals Feature Data with Goals
     * @param authorization Bearer token for authentication (optional)
     * @return Feature
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createFeatureWithGoals(featureWithGoals: FeatureWithGoals, authorization: kotlin.String? = null): HttpResponse<Feature> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureWithGoals

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/features/createFeatureWithGoals",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Feature with Score
     * 
     * @param featureWithScore Feature Data with Score
     * @param authorization Bearer token for authentication (optional)
     * @return Feature
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createFeatureWithScore(featureWithScore: FeatureWithScore, authorization: kotlin.String? = null): HttpResponse<Feature> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureWithScore

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/features/createFeatureWithScore",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Feature with Tags
     * 
     * @param featureWithTags Feature Data with Tags
     * @param authorization Bearer token for authentication (optional)
     * @return Feature
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createFeatureWithTags(featureWithTags: FeatureWithTags, authorization: kotlin.String? = null): HttpResponse<Feature> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureWithTags

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/features/createFeatureWithTags",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Feature with Watchers
     * 
     * @param featureWithWatchers Feature Data with Watchers
     * @param authorization Bearer token for authentication (optional)
     * @return Feature
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createFeatureWithWatchers(featureWithWatchers: FeatureWithWatchers, authorization: kotlin.String? = null): HttpResponse<Feature> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureWithWatchers

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/features/createFeatureWithWatchers",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create Project
     * 
     * @param projectRequest Project Data
     * @param authorization Bearer token for authentication (optional)
     * @return CreateProjectResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createProject(projectRequest: ProjectRequest, authorization: kotlin.String? = null): HttpResponse<CreateProjectResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = projectRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/projects/createProject",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Delete Feature
     * 
     * @param featureId The ID of the feature to delete.
     * @param authorization Bearer token for authentication (optional)
     * @return FeatureListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun deleteFeature(featureId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<FeatureListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.DELETE,
            "/features/deleteFeature/{featureId}".replace("{" + "featureId" + "}", "$featureId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Delete Project
     * 
     * @param projectId 
     * @param authorization Bearer token for authentication (optional)
     * @return ProjectListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun deleteProject(projectId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<ProjectListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.DELETE,
            "/projects/deleteProject/{projectId}".replace("{" + "projectId" + "}", "$projectId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Board Projects
     * 
     * @param boardId boardId
     * @param authorization Bearer token for authentication (optional)
     * @return ProjectListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getBoardProjects(boardId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<ProjectListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/projects/getBoardProjects/{boardId}".replace("{" + "boardId" + "}", "$boardId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Projects created by me
     * 
     * @param authorization Bearer token for authentication (optional)
     * @return ProjectListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getMyCreatedProjects(authorization: kotlin.String? = null): HttpResponse<ProjectListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/projects/getMyCreatedProjects",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Project Details
     * 
     * @param projectId 
     * @param authorization Bearer token for authentication (optional)
     * @return ProjectDetailResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getProjectDetails(projectId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<ProjectDetailResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/projects/getProjectDetails/{projectId}".replace("{" + "projectId" + "}", "$projectId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Specific Feature
     * 
     * @param featureId The ID of the feature to retrieve.
     * @param authorization Bearer token for authentication (optional)
     * @return FeatureDetailResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getSpecificFeature(featureId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<FeatureDetailResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/features/getSpecificFeature/{featureId}".replace("{" + "featureId" + "}", "$featureId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Features
     * 
     * @param projectId The ID of the project to retrieve.
     * @param authorization Bearer token for authentication (optional)
     * @return FeatureListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listFeatures(projectId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<FeatureListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/features/listFeatures/{projectId}".replace("{" + "projectId" + "}", "$projectId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Features Associated with a Goal
     * 
     * @param goalId 
     * @param authorization Bearer token for authentication (optional)
     * @return FeatureListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listFeaturesAssociatedWithGoal(goalId: kotlin.Int, authorization: kotlin.String? = null): HttpResponse<FeatureListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        goalId?.apply { localVariableQuery["goalId"] = listOf("$goalId") }
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/features/listFeaturesAssociatedWithGoal",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Features Associated with an Initiative
     * 
     * @param initiativeId 
     * @param authorization Bearer token for authentication (optional)
     * @return FeatureListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listFeaturesAssociatedWithInitiative(initiativeId: kotlin.Int, authorization: kotlin.String? = null): HttpResponse<FeatureListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        initiativeId?.apply { localVariableQuery["initiativeId"] = listOf("$initiativeId") }
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/features/listFeaturesAssociatedWithInitiative",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Features in an Epic
     * 
     * @param authorization Bearer token for authentication (optional)
     * @return FeatureListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listFeaturesInEpic(authorization: kotlin.String? = null): HttpResponse<FeatureListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/features/listFeaturesInEpic",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Features in a Product
     * 
     * @param productId 
     * @param authorization Bearer token for authentication (optional)
     * @return FeatureListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listFeaturesInProduct(productId: kotlin.Int, authorization: kotlin.String? = null): HttpResponse<FeatureListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        productId?.apply { localVariableQuery["productId"] = listOf("$productId") }
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/features/listFeaturesInProduct",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List Features in a Release
     * 
     * @param authorization Bearer token for authentication (optional)
     * @return FeatureListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listFeaturesInRelease(authorization: kotlin.String? = null): HttpResponse<FeatureListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/features/listFeaturesInRelease",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Update Feature
     * 
     * @param featureId The ID of the feature to delete.
     * @param createFeatureRequest Feature Data
     * @param authorization Bearer token for authentication (optional)
     * @return FeatureResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeature(featureId: kotlin.String, createFeatureRequest: CreateFeatureRequest, authorization: kotlin.String? = null): HttpResponse<FeatureResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = createFeatureRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/features/updateFeature/{featureId}".replace("{" + "featureId" + "}", "$featureId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update a Feature&#39;s Custom Fields
     * 
     * @param featureCustomFields Updated Custom Fields Data
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureCustomFields(featureCustomFields: FeatureCustomFields, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureCustomFields

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/features/updateFeatureCustomFields",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Feature&#39;s Custom Fields with Tag-like Value
     * 
     * @param featureCustomFieldsWithTagValue Updated Custom Fields Data with Tag-like Value
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureCustomFieldsWithTagValue(featureCustomFieldsWithTagValue: FeatureCustomFieldsWithTagValue, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureCustomFieldsWithTagValue

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/features/updateFeatureCustomFieldsWithTagValue",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Feature&#39;s Custom Worksheet Fields
     * 
     * @param featureCustomWorksheetFields Updated Custom Worksheet Fields Data
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureCustomWorksheetFields(featureCustomWorksheetFields: FeatureCustomWorksheetFields, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureCustomWorksheetFields

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/features/updateFeatureCustomWorksheetFields",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Feature&#39;s Epic
     * 
     * @param featureEpic Updated Epic Data
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureEpic(featureEpic: FeatureEpic, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureEpic

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/features/updateFeatureEpic",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Feature&#39;s Goals
     * 
     * @param featureGoals Updated Goals Data
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureGoals(featureGoals: FeatureGoals, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureGoals

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/features/updateFeatureGoals",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Feature&#39;s Progress
     * 
     * @param featureProgress Feature Progress Data
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureProgress(featureProgress: FeatureProgress, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureProgress

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/features/updateFeatureProgress",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Feature&#39;s Progress Source
     * 
     * @param featureProgressSource Feature Progress Source Data
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureProgressSource(featureProgressSource: FeatureProgressSource, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureProgressSource

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/features/updateFeatureProgressSource",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Feature&#39;s Release
     * 
     * @param featureRelease Feature Release Data
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureRelease(featureRelease: FeatureRelease, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureRelease

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/features/updateFeatureRelease",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Feature&#39;s Score
     * 
     * @param featureScore Feature Score Data
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureScore(featureScore: FeatureScore, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureScore

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/features/updateFeatureScore",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Feature Tags with an Array
     * 
     * @param featureTags Update Feature Tags Data with Array
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureTagsWithArray(featureTags: FeatureTags, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureTags

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/features/updateFeatureTagsWithArray",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Feature Tags with Comma-Separated Values
     * 
     * @param featureTagsCSV Update Feature Tags Data with Comma-Separated Values
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureTagsWithCommaSeparatedValues(featureTagsCSV: FeatureTagsCSV, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureTagsCSV

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/features/updateFeatureTagsWithCommaSeparatedValues",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Feature Watchers
     * 
     * @param featureWatchers Update Feature Watchers Data
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateFeatureWatchers(featureWatchers: FeatureWatchers, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = featureWatchers

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/features/updateFeatureWatchers",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update Project
     * 
     * @param projectId 
     * @param updateProjectRequest Project Data
     * @param authorization Bearer token for authentication (optional)
     * @return ProjectResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateProject(projectId: kotlin.String, updateProjectRequest: UpdateProjectRequest, authorization: kotlin.String? = null): HttpResponse<ProjectResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = updateProjectRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/projects/updateProject/{projectId}".replace("{" + "projectId" + "}", "$projectId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



}
