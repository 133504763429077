package org.company.app


import kotlinx.browser.window

internal actual fun openUrl(url: String?) {
    url?.let { window.open(it) }
}

internal actual fun initFirebase(context:Any?){

}

internal actual fun platformName(): String {
    return "Web"
}