package org.company.app.data.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RefreshTokenResponse(
    @SerialName("access_token"  ) var accessToken  : String? = null,
    @SerialName("expires_in"    ) var expiresIn    : String? = null,
    @SerialName("token_type"    ) var tokenType    : String? = null,
    @SerialName("refresh_token" ) var refreshToken : String? = null,
    @SerialName("id_token"      ) var idToken      : String? = null,
    @SerialName("user_id"       ) var userId       : String? = null,
    @SerialName("project_id"    ) var projectId    : String? = null
)
