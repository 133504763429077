package org.company.app.data.model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DocsContentResponse(
    @SerialName("download_url")
    var downloadUrl: String? = null,
    @SerialName("git_url")
    var gitUrl: String? = null,
    @SerialName("html_url")
    var htmlUrl: String? = null,
    @SerialName("_links")
    val links: Links? = null,
    @SerialName("name")
    val name: String? = null,
    @SerialName("path")
    val path: String? = null,
    @SerialName("sha")
    var sha: String? = null,
    @SerialName("size")
    val size: Int? = null,
    @SerialName("content")
    var content: String? = null,
    @SerialName("type")
    val type: String? = null,
    @SerialName("url")
    val url: String? = null,
    var parent: String? = null,
    var children: List<DocsContentResponse> = mutableListOf(),
    var pages: List<DocsContentResponse> = mutableListOf(),
)