package org.company.app.presentation.component

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.eye
import multiplatform_app.composeapp.generated.resources.eye_hide
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource


@Composable
fun ZiFocusTextField(text: MutableState<String>, textStyle: TextStyle=MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A)), placeHolder:String, modifier: Modifier=Modifier, withDivider:Boolean=true){
    Box {
        if (text.value==""){
            Text(placeHolder, style = textStyle, color = Color.Gray,modifier = modifier)
        }
        BasicTextField(value = text.value,
            onValueChange = {
                text.value=it
            }
            ,
            modifier = modifier.padding(bottom = 8.dp),
            textStyle = textStyle,
            keyboardOptions = KeyboardOptions(
                keyboardType = KeyboardType.Text,
                imeAction = ImeAction.Done
            ),

            )
        if (withDivider) Divider(modifier = modifier.align(Alignment.BottomCenter))

    }
}


@Composable
fun ZiFocusTextField(value:String,  onValueChange: (String) -> Unit,textStyle: TextStyle=MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp), placeHolder:String, modifier: Modifier=Modifier,withDivider:Boolean=true,isError:Boolean=false,supportingText:String="",readOnly:Boolean=false){
    if (isError){
        Text(supportingText,color=Color(0xFFE35D6A),fontSize=11.sp,
            style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
            modifier = modifier)
    }

    Box {
        if (value==""){
            Text(placeHolder, style = textStyle, color = Color.Gray,modifier = modifier)
        }
        BasicTextField(value = value,
            onValueChange = {
                onValueChange(it)
            }
            ,
            modifier = modifier.padding(bottom = 8.dp),
            textStyle = textStyle.copy(color = if (isError)Color(0xFFE35D6A)else Color(0xFF03264A)),
            keyboardOptions = KeyboardOptions(
                keyboardType = KeyboardType.Text,
                imeAction = ImeAction.Done
            ),
            readOnly = readOnly

            )
        if (withDivider) HorizontalDivider(
            modifier = modifier.align(Alignment.BottomCenter),
            color = if (isError)Color(0xFFE35D6A)else DividerDefaults.color
        )

    }
}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun ZiFocusPasswordTextField(text: MutableState<String>, textStyle: TextStyle=TextStyle(fontSize = 15.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily),placeHolder:String,modifier: Modifier=Modifier){
    var passwordError by remember { mutableStateOf(false) }

    var passwordVisibility by remember { mutableStateOf(false) }


    Box {
        if (text.value==""){
            Text(placeHolder, style = textStyle, color = Color.Gray,modifier = modifier)
        }
            BasicTextField(value = text.value,
                onValueChange = {
                    text.value=it
                }
                ,
                modifier = modifier.padding(bottom = 8.dp),
                textStyle = textStyle,
                visualTransformation = if (passwordVisibility) VisualTransformation.None else PasswordVisualTransformation(),
                keyboardOptions = KeyboardOptions(
                    keyboardType = KeyboardType.Text,
                    imeAction = ImeAction.Done
                ),

                )

        IconButton(modifier=Modifier.align(Alignment.CenterEnd),onClick = { passwordVisibility = !passwordVisibility }) {
            val imageVector = if (passwordVisibility) painterResource(Res.drawable.eye_hide)  else painterResource(
                Res.drawable.eye)
            Icon(
                imageVector,
                modifier=Modifier.size(24.dp),
                contentDescription = if (passwordVisibility) "Hide password" else "Show password"
            )
        }




        Divider(modifier = modifier.align(Alignment.BottomCenter))
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun ZiFocusPasswordTextField(value:String,  onValueChange: (String) -> Unit, textStyle: TextStyle=TextStyle(fontSize = 15.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily),placeHolder:String,modifier: Modifier=Modifier,isError:Boolean=false,supportingText:String=""){
    if (isError){
        Text(supportingText,color=Color(0xFFE35D6A),fontSize=11.sp,
            style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
            modifier = modifier)
    }

    var passwordVisibility by remember { mutableStateOf(false) }


    Box {
        if (value==""){
            Text(placeHolder, style = textStyle, color = Color.Gray,modifier = modifier)
        }
        BasicTextField(value = value,
            onValueChange = {
                onValueChange(it)
            }
            ,
            modifier = modifier.padding(bottom = 8.dp),
            textStyle = textStyle.copy(color = if (isError)Color(0xFFE35D6A)else Color(0xFF03264A)),
            visualTransformation = if (passwordVisibility) VisualTransformation.None else PasswordVisualTransformation(),
            keyboardOptions = KeyboardOptions(
                keyboardType = KeyboardType.Text,
                imeAction = ImeAction.Done
            ),

            )


        val imageVector = if (passwordVisibility) painterResource(Res.drawable.eye_hide)  else painterResource(
            Res.drawable.eye)
        Image(
            imageVector,
            modifier=Modifier.size(24.dp).align(Alignment.CenterEnd).padding(end = 4.dp).clickable { passwordVisibility = !passwordVisibility },
            contentDescription = if (passwordVisibility) "Hide password" else "Show password"
        )


        HorizontalDivider(
            modifier = modifier.align(Alignment.BottomCenter),
            color = if (isError)Color(0xFFE35D6A)else DividerDefaults.color
        )
    }
}


@Composable
fun ZiFocusDropDownTextField(text: MutableState<String>, textStyle: TextStyle=MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp),placeHolder:String,modifier: Modifier=Modifier,trailingIcon: @Composable() (() -> Unit)){

    Box {
        if (text.value==""){
            Text(placeHolder, style = textStyle, color = Color.Gray,modifier = modifier)
        }
        BasicTextField(value = text.value,
            onValueChange = {
                text.value=it
            }
            ,
            modifier = modifier.fillMaxWidth().padding(bottom = 8.dp),
            textStyle = textStyle,
            readOnly = true,
            keyboardOptions = KeyboardOptions(
                keyboardType = KeyboardType.Text,
                imeAction = ImeAction.Done
            ),

            )

            Row(modifier=Modifier.align(Alignment.CenterEnd)) {
                trailingIcon()
            }






        Divider(modifier = modifier.align(Alignment.BottomCenter))
    }
}