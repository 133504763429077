package org.company.app.data.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VerifyOtpResponse (

    @SerialName("code"     ) var code     : String?  = null,
    @SerialName("message"  ) var message  : String?  = null,
    @SerialName("verified" ) var verified : Boolean? = null,
    @SerialName("data"     ) var data     : VerifyOtpData?    = VerifyOtpData()

)
