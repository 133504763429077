package org.company.app.presentation.ui

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow

import org.company.app.presentation.component.ZiFocusTextField
import org.company.app.presentation.viewmodel.LoginViewModel
import org.company.app.theme.AppTheme
import org.koin.compose.koinInject

object ForgotPasswordScreen : Screen {
    @Composable
    override fun Content() {
        AppTheme {
            ForgotPasswordContent()
        }

    }
    @OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
    @Composable
    fun ForgotPasswordContent(){
        AppTheme {



            var email = remember { mutableStateOf("") }
            var message = remember { mutableStateOf("We will send a password reset link to your mail address.") }
            var startLoading = remember { mutableStateOf(false) }
            var emailSent = remember { mutableStateOf(false) }


            val viewModel = koinInject<LoginViewModel>()
            val loginState by viewModel.loginState.collectAsState()
            val navigator = LocalNavigator.currentOrThrow



            val windowSizeClass = calculateWindowSizeClass()
            val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact




            if (loginState.errorMessage!=null){
                viewModel.setOpenDialog(true)
            }

            if (loginState.isError) {
                AlertDialog(
                    onDismissRequest = {  },
                    properties = DialogProperties(
                        dismissOnClickOutside = false,
                        dismissOnBackPress = true
                    ),
                    title = { Text(text = "Login Failed") },
                    text = { Text(loginState.errorMessage?:"Error") },
                    confirmButton = {
                        Button(
                            modifier = Modifier.fillMaxWidth(),
                            onClick = {
                                viewModel.clearLoginUIState()
                            }
                        ) {
                            Text("Dismiss")
                        }
                    }
                )
            }
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
                modifier =
                if (useNavRail) Modifier.fillMaxSize().windowInsetsPadding(WindowInsets.safeDrawing).padding( top = 40.dp)
                else Modifier.fillMaxSize().windowInsetsPadding(WindowInsets.safeDrawing).padding(top = 40.dp)
            ) {
                Row(modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f)) {
                    Text(
                        text = buildAnnotatedString {
                            withStyle(
                                SpanStyle(
                                    fontSize = if (useNavRail )32.sp else 20.sp,
                                    fontStyle = if (useNavRail ) MaterialTheme.typography.bodyLarge.fontStyle else MaterialTheme.typography.displayMedium.fontStyle,
                                    fontWeight = FontWeight.Bold,
                                    color = Color.Black
                                )
                            ) {
                                append("Forgot your password?")
                            }
                            append("\n ${message.value}")
                        },
                        modifier = Modifier.padding(8.dp),
                        fontSize = if (useNavRail) 17.sp else 15.sp,
                        style = MaterialTheme.typography.bodySmall,
                        color = Color.Gray,
                        textAlign = TextAlign.Start

                    )
                }
                Spacer(modifier = Modifier.height(32.dp))
                if (!emailSent.value){
                    ZiFocusTextField(
                        text = email,
                        textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp),
                        placeHolder = "Type Email",
                        modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(8.dp)

                    )
                } else {
                    Text(email.value, fontSize = 15.sp,style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily)
                }

                Spacer(modifier = Modifier.height(32.dp))


                if (!emailSent.value) {
                    Button(
                        onClick = {
                            startLoading.value=true
                            viewModel.resetPassword(email.value) {
                                // password sent successfully
                                startLoading.value=false
                                message.value= "A password reset link has been sent to your email address. Please check your email and follow the instructions to reset your password."
                                emailSent.value=true
                            }

                        },
                        modifier = Modifier.padding(16.dp).width(213.dp),
                        shape = RoundedCornerShape(10.dp),
                        enabled = email.value.isNotEmpty()
                    ) {
                        Text("Send", fontSize = 17.sp,
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.semibold)
                            style = MaterialTheme.typography.displayMedium,
                            fontFamily = MaterialTheme.typography.displayMedium.fontFamily
                        )
                    }
                }

                AnimatedVisibility(startLoading.value){
                    CircularProgressIndicator()
                }
                Spacer(modifier = Modifier.height(24.dp))

                TextButton(onClick = {navigator.pop()}){
                    Text("Back to login", color = MaterialTheme.colorScheme.primaryContainer,fontSize = 15.sp, style = MaterialTheme.typography.bodySmall)

                }

            }



            // App Version Number


        }
    }
}