package org.company.app.di

import api.*
import org.company.app.presentation.viewmodel.*
import org.koin.dsl.module

val apisModule = module {

    single<BoardsApi> {
        BoardsApi()
    }
    single<ToDoApi> {
        ToDoApi()
    }
    single<RegistrationApi> {
        RegistrationApi()
    }
    single<FeaturesApi> {
        FeaturesApi()
    }
    single<CommentsApi> {
        CommentsApi()
    }
    single<NotificationApi> {
        NotificationApi()
    }


}