package org.company.app.presentation.component

import androidx.compose.foundation.*
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.Surface
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.AttachFile
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import com.mohamedrejeb.calf.core.LocalPlatformContext
import com.mohamedrejeb.calf.picker.FilePickerFileType
import com.mohamedrejeb.calf.picker.FilePickerSelectionMode
import com.mohamedrejeb.calf.picker.rememberFilePickerLauncher
import com.mohamedrejeb.richeditor.model.rememberRichTextState
import com.mohamedrejeb.richeditor.ui.BasicRichTextEditor
import com.seiko.imageloader.rememberImagePainter
import kotlinx.coroutines.launch
import multiplatform_app.composeapp.generated.resources.*
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.edit
import multiplatform_app.composeapp.generated.resources.mark_done
import multiplatform_app.composeapp.generated.resources.x_cross
import org.company.app.data.model.Category
import org.company.app.presentation.ui.board.PriorityChooseCreateTask
import org.company.app.presentation.ui.board.StatusChooseCreateTask
import org.company.app.presentation.ui.board.formatDateString
import org.company.app.presentation.ui.carddetails.extractFileNameFromUrl
import org.company.app.presentation.utils.dateTimeToString
import org.company.app.presentation.utils.getRandomColorForColumns
import org.company.app.presentation.utils.selectedDateMillisToLocalDateTime
import org.company.app.presentation.viewmodel.HomeViewModel
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject
import org.openapitools.client.models.CategoryItem
import org.openapitools.client.models.CreateTaskRequest
import org.openapitools.client.models.Project

@OptIn(ExperimentalResourceApi::class)
@Composable

fun CreateTaskDialog(onDismiss:()->Unit={}){
        AlertDialog(
            onDismissRequest = { onDismiss() },
            properties = DialogProperties(
                dismissOnClickOutside = true,
                dismissOnBackPress = true,
                usePlatformDefaultWidth = false
            ),
            modifier = Modifier.fillMaxWidth(0.7f).padding(40.dp),
            containerColor = Color(0xFFFAFAFA),
            title = {
                Row {

                    Spacer(modifier = Modifier.weight(1f))
                    Image(painterResource(Res.drawable.x_cross),contentDescription = null,modifier= Modifier.clickable { onDismiss() })


                }
            },
            text = {
                CreateTaskDialogContent()
                   },
            confirmButton = {

            }
        )

}
@OptIn(ExperimentalMaterial3Api::class, ExperimentalResourceApi::class)
@Composable
fun CreateTaskDialogContent(){
        var taskName by remember { mutableStateOf("") }

        var taskPriority = remember { mutableStateOf("") }
        var memberAssigned = remember { mutableStateOf("") }
        var assignedToId = remember { mutableStateOf("") }
        var memberAssignedAvatar = remember { mutableStateOf("") }
        var statusChange = remember { mutableStateOf(false) }
        var priortyChange = remember { mutableStateOf(false) }
        val changeCategory = remember { mutableStateOf(false) }

        val customCategory = remember { mutableStateOf(false) }
        val customCategoryToEdit = remember { mutableStateOf(Category()) }
        val categorySelectedtitle = remember { mutableStateOf(customCategoryToEdit.value.title) }
        val selectedColor = remember { mutableStateOf(customCategoryToEdit.value.color) }


        var assignToMember = remember { mutableStateOf(false) }
        val category = remember { mutableStateOf(CategoryItem(title = "Work", color = 0xFFA98EDA)) }

        val attachments = remember { mutableStateListOf<String>() }
        val viewModel = koinInject<HomeViewModel>()

        val homeState = viewModel.homeState.collectAsState()
        val userId = homeState.value.user?.id
        val projectId = homeState.value.selectedWorkspaceId?:""
        val selectedProject = homeState.value.selectedProject
        val taskStatus = remember { mutableStateOf(selectedProject?.taskStatusDetails?.firstOrNull()?.id?:"") }

        val dateSelected = rememberDatePickerState(yearRange = 2023..2050,)
        val datePicker = remember { mutableStateOf(false) }
        // var dueDate by remember { mutableStateOf("") }
        var dueDate by mutableStateOf(dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().dateTimeToString())

        val scope = rememberCoroutineScope()
        val context = LocalPlatformContext.current

        val pickerLauncher = rememberFilePickerLauncher(
            type = FilePickerFileType.Custom(listOf("application/pdf","image/*")),
            selectionMode = FilePickerSelectionMode.Multiple,
            onResult = { files ->
                scope.launch {
                    try {
                        files.forEach { file ->
                            // Do something with the selected file
                            // You can get the ByteArray of the file
                            viewModel.uploadFileOnCreateTask(file,context){
                                attachments.add(it?:"")
                            }
                        }
                    }catch (exception:Exception){}

                }
            }
        )


        if (customCategory.value) {
            AlertDialog(modifier = Modifier.width(250.dp),containerColor = Color(0xFFFAFAFA),
                onDismissRequest = { customCategory.value=false },
                properties = DialogProperties(
                    dismissOnClickOutside = true,
                    dismissOnBackPress = true,
                    usePlatformDefaultWidth = false

                ),
                title = {  Row {
                    Spacer(modifier = Modifier.weight(1f))
                    Image(painterResource(Res.drawable.x_cross),contentDescription = null,modifier=Modifier.clickable { customCategory.value=false })
                } },
                text = {


                    Column {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            Box {
                                Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                    drawCircle(Color(selectedColor.value?:0))
                                })

                            }

                            Text(categorySelectedtitle.value?:"", fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A),
                                modifier = Modifier.padding(start = 4.dp))
                            Spacer(modifier = Modifier.weight(1f))


                        }
                        Spacer(modifier = Modifier.height(18.dp))
                        ZiFocusTextField(value = categorySelectedtitle.value?:"", onValueChange = {
                            categorySelectedtitle.value = it
                        }, placeHolder = "Title")
                        Spacer(modifier = Modifier.height(18.dp))
                        Row {
                            Box(modifier = Modifier.clickable { selectedColor.value= 0xFFA98EDA}) {
                                Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                    drawCircle(Color(0xFFA98EDA))
                                })
                                if (selectedColor.value==0xFFA98EDA)
                                    Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                        Alignment.Center))
                            }

                            Spacer(modifier = Modifier.width(12.dp))
                            Box(modifier = Modifier.clickable { selectedColor.value= 0xFFFEB272}) {
                                Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                    drawCircle(Color(0xFFFEB272))
                                })
                                if (selectedColor.value==0xFFFEB272)
                                    Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                        Alignment.Center))
                            }
                            Spacer(modifier = Modifier.width(12.dp))
                            Box(modifier = Modifier.clickable { selectedColor.value= 0xFFFFDA6A}) {
                                Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                    drawCircle(Color(0xFFFFDA6A))
                                })
                                if (selectedColor.value==0xFFFFDA6A)
                                    Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                        Alignment.Center))
                            }
                            Spacer(modifier = Modifier.width(12.dp))
                            Box(modifier = Modifier.clickable { selectedColor.value= 0xFF79DFC1}) {
                                Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                    drawCircle(Color(0xFF79DFC1))
                                })
                                if (selectedColor.value==0xFF79DFC1)
                                    Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                        Alignment.Center))
                            }
                            Spacer(modifier = Modifier.width(12.dp))
                            Box(modifier = Modifier.clickable { selectedColor.value= 0xFF6EA8FE}) {
                                Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                    drawCircle(Color(0xFF6EA8FE))
                                })
                                if (selectedColor.value==0xFF6EA8FE)
                                    Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                        Alignment.Center))
                            }


                        }
                        Spacer(modifier = Modifier.height(18.dp))
                        Row {
                            Box(modifier = Modifier.clickable { selectedColor.value= 0xFF75B798}) {
                                Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                    drawCircle(Color(0xFF75B798))
                                })
                                if (selectedColor.value==0xFF75B798)
                                    Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                        Alignment.Center))
                            }

                            Spacer(modifier = Modifier.width(12.dp))
                            Box(modifier = Modifier.clickable { selectedColor.value= 0xFF6EDFF6}) {
                                Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                    drawCircle(Color(0xFF6EDFF6))
                                })
                                if (selectedColor.value==0xFF6EDFF6)
                                    Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                        Alignment.Center))
                            }
                            Spacer(modifier = Modifier.width(12.dp))
                            Box(modifier = Modifier.clickable { selectedColor.value= 0xFFEA868F}) {
                                Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                    drawCircle(Color(0xFFEA868F))
                                })
                                if (selectedColor.value==0xFFEA868F)
                                    Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                        Alignment.Center))
                            }
                            Spacer(modifier = Modifier.width(12.dp))
                            Box(modifier = Modifier.clickable { selectedColor.value= 0xFFADB5BD}) {
                                Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                    drawCircle(Color(0xFFADB5BD))
                                })
                                if (selectedColor.value==0xFFADB5BD)
                                    Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                        Alignment.Center))
                            }
                            Spacer(modifier = Modifier.width(12.dp))
                            Box(modifier = Modifier.clickable { selectedColor.value= 0xFFE685B5}) {
                                Canvas(modifier = Modifier.size(30.dp), onDraw = {
                                    drawCircle(Color(0xFFE685B5))
                                })
                                if (selectedColor.value==0xFFE685B5)
                                    Image(painterResource(Res.drawable.mark_done),contentDescription = null, modifier = Modifier.align(
                                        Alignment.Center))
                            }


                        }

                    }


                },
                confirmButton = {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        TextButton(onClick = {
                            customCategory.value=false
                        }){
                            Text("Discard", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                color = Color(0xFF3b4958))
                        }
                        Spacer(modifier = Modifier.weight(1f))
                        Button(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                            modifier = Modifier
                                .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp).width(56.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF495564)),onClick = {
                                category.value = CategoryItem(title = categorySelectedtitle.value, color = selectedColor.value?:0)
                                customCategory.value=false
                                changeCategory.value = false

                            }){
                            Text("Save", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                        }



                    }

                }
            )
        }




        Column(modifier = Modifier.background(Color(0xFFFAFAFA))) {
            if (datePicker.value){
                DatePickerDialog(onDismissRequest = {datePicker.value=false}, confirmButton = {
                    TextButton(onClick = {
                        dueDate = dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().dateTimeToString()
                        datePicker.value = false
                    }) {
                        Text("Confirm",
                            // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                            style =  MaterialTheme.typography.bodySmall
                            , fontSize = 15.sp, color = MaterialTheme.colorScheme.primaryContainer)
                    }
                }, colors = DatePickerDefaults.colors(containerColor = Color(0xFFFAFAFA))){
                    DatePicker(
                        state = dateSelected,
                        modifier = Modifier.wrapContentHeight().wrapContentWidth(),
                        colors = DatePickerDefaults.colors(selectedDayContainerColor = MaterialTheme.colorScheme.primaryContainer, todayDateBorderColor = MaterialTheme.colorScheme.primaryContainer, todayContentColor = MaterialTheme.colorScheme.primaryContainer)
                    )
                }
            }
            // Task name here
            Box {
                if (taskName==""){
                    Text("Task Title", fontSize = 24.sp,
                        //  fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                        style =  MaterialTheme.typography.bodyMedium
                        , color = Color.Gray,modifier = Modifier.padding(start = 16.dp))
                }
                BasicTextField(value = taskName,
                    onValueChange = {
                        taskName=it
                    }
                    ,
                    modifier = Modifier.padding(start = 16.dp),
                    textStyle = MaterialTheme.typography.bodyMedium.copy(fontSize = 24.sp, fontFamily = MaterialTheme.typography.bodyMedium.fontFamily),
                    keyboardOptions = KeyboardOptions(
                        keyboardType = KeyboardType.Text,
                        imeAction = ImeAction.Done
                    ),


                    )
            }
            Spacer(modifier = Modifier.height(8.dp))
            Box(modifier = Modifier.padding(start = 16.dp)) {

                //Collecting states from ViewModel
                val searchText by viewModel.searchText.collectAsState() // Query for SearchBar
                val countriesList by viewModel.categories.collectAsState()

                TextButton(contentPadding = PaddingValues(), modifier = Modifier.defaultMinSize(1.dp,1.dp),
                    onClick = { changeCategory.value = !changeCategory.value }
                ) {
                    Row {
                        Canvas(modifier = Modifier.size(18.dp).padding(top = 4.dp), onDraw = {
                            drawCircle(Color(category.value.color?.toLong()?:0xFF9056F0))
                        })
                        Text(category.value.title?:"Work", fontSize = 18.sp, style = MaterialTheme.typography.bodySmall,
                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A),
                            modifier = Modifier.padding(start = 4.dp))
                    }

                }
                DropdownMenu(
                    expanded = changeCategory.value,
                    onDismissRequest = { changeCategory.value = false },
                    modifier = Modifier.background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp))
                ){

                    DockedSearchBar(
                        query = searchText,//text showed on SearchBar
                        onQueryChange = viewModel::onSearchTextChange, //update the value of searchText
                        onSearch = viewModel::onSearchTextChange, //the callback to be invoked when the input service triggers the ImeAction.Search action
                        active = true, //whether the user is searching or not
                        onActiveChange = { viewModel.onToogleSearch() }, //the callback to be invoked when this search bar's active state is changed
                        modifier = Modifier
                            .width(250.dp)
                            .padding(4.dp),
                        placeholder = {
                            Text(text = "Search", fontSize = 15.sp,
                                style = MaterialTheme.typography.bodySmall,
                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily)
                        },
                        leadingIcon = {
                            Icon(imageVector = Icons.Default.Search, contentDescription = null)
                        },
                        colors = SearchBarDefaults.colors(containerColor = Color(0xFFFAFAFA))
                    ) {
                        countriesList.let {
                            Column(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .heightIn(max=250.dp)
                                    .verticalScroll(state = rememberScrollState())
                            ) {
                                it.forEach { categpry ->
                                    val interactionSource = remember { MutableInteractionSource() }
                                    val isHovered = interactionSource.collectIsHoveredAsState().value
                                    Box(modifier = Modifier.fillMaxWidth().hoverable(interactionSource)) {


                                        TextButton(modifier = Modifier.padding(end = 24.dp),
                                            onClick = {
                                                category.value = categpry
                                                changeCategory.value = false
                                            }
                                        ) {
                                            Row {
                                                Canvas(modifier = Modifier.size(18.dp).padding(top = 4.dp), onDraw = {
                                                    drawCircle(Color(categpry.color?.toLong()?:0))
                                                })
                                                Text(categpry.title?:"", fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A),
                                                    modifier = Modifier.padding(start = 4.dp))
                                                Spacer(modifier = Modifier.weight(1f))

                                            }

                                        }
                                        if (isHovered){
                                            // hamokshaa
                                            Image(painterResource(Res.drawable.edit),contentDescription = null, modifier = Modifier.align(
                                                Alignment.CenterEnd).clickable {
                                                categorySelectedtitle.value = categpry.title
                                                selectedColor.value = categpry.color?.toLong()
                                                customCategory.value = true

                                            })
                                        }

                                    }


                                }
                                if (searchText.isNotEmpty()){

                                    Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(horizontal = 12.dp).fillMaxWidth()) {
                                        Canvas(modifier = Modifier.size(18.dp).padding(top = 4.dp), onDraw = {
                                            drawCircle(Color((if (category.value.title?.lowercase()==searchText.lowercase()) category.value.color?.toLong() else 0xFF1BBEC1)?:0xFF1BBEC1))
                                        })
                                        Text(searchText, fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A),
                                            modifier = Modifier.padding(start = 4.dp).fillMaxWidth(0.6f))
                                        Spacer(modifier = Modifier.weight(1f))
                                        if (category.value.title?.lowercase()==searchText.lowercase()){
                                            TextButton(onClick = {
                                                categorySelectedtitle.value = category.value.title
                                                selectedColor.value = category.value.color?.toLong()
                                                customCategory.value = true
                                            }){
                                                Image(painterResource(Res.drawable.edit),contentDescription = null)
                                            }

                                        } else {
                                            TextButton(onClick = {
                                                categorySelectedtitle.value = searchText
                                                selectedColor.value = 0xFF1BBEC1
                                                customCategory.value=true


                                            }){
                                                Text("Create", fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = MaterialTheme.colorScheme.primary,
                                                )
                                            }
                                        }

                                    }







                                }
                            }
                        }
                    }
                }
            }
            Spacer(modifier = Modifier.height(6.dp))
            Row(modifier = Modifier.padding(start = 16.dp), verticalAlignment = Alignment.CenterVertically){

                Box {
                    TextButton(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                        modifier = Modifier
                            .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp)
                            .width(100.dp)
                        ,onClick = {statusChange.value=!statusChange.value}, colors = ButtonDefaults.textButtonColors(containerColor = when (taskStatus.value) {
                            selectedProject?.taskStatusDetails?.firstOrNull()?.id -> Color(0xFFCFE2FF)
                            selectedProject?.taskStatusDetails?.find { it.name== "In Progress"}?.id -> Color(0xFFFFF3CD)
                            selectedProject?.taskStatusDetails?.lastOrNull()?.id -> Color(0xFFD1E7DD)
                            else -> getRandomColorForColumns()
                        })){
                        Text(selectedProject?.taskStatusDetails?.find { it.id==taskStatus.value }?.name?:"", fontFamily = MaterialTheme.typography.bodySmall.fontFamily, fontSize = 12.sp, color = Color(0xFF03264A))
                    }


                    DropdownMenu(
                        expanded = statusChange.value,
                        onDismissRequest = { statusChange.value = false },
                        modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                    ){
                        StatusChooseCreateTask(taskStatus,statusChange,selectedProject)
                    }
                }

                Spacer(modifier = Modifier.width(16.dp))
                // menna
                Box {

                    //Collecting states from ViewModel
                    val searchText by viewModel.searchText.collectAsState() // Query for SearchBar
                    val countriesList by viewModel.boardMemberList.collectAsState()


                    TextButton(
                        onClick = { assignToMember.value = !assignToMember.value }
                    ) {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            if (memberAssigned.value==""){
                                Image(painterResource(Res.drawable.assign_member_),modifier = Modifier.size(24.dp), contentDescription = null)
                            } else {

                                if (memberAssignedAvatar.value.isEmpty()){
                                    val name = memberAssigned.value
                                    val words = name.split(" ")
                                    val initials = if (words.size > 1) {
                                        words[0].take(1) + words[words.lastIndex].take(1)
                                    } else {
                                        words[0].take(2)
                                    }.uppercase()
                                    TextButton(onClick = {},modifier = Modifier
                                        .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)
                                    ){
                                        Text(
                                            text = initials,
                                            fontSize = 16.sp,
                                            color = Color.White,
                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                            maxLines = 1,
                                            overflow = TextOverflow.Ellipsis,
                                            textAlign = TextAlign.Center
                                        )
                                    }

                                }
                                else {
                                    Image(
                                        rememberImagePainter(memberAssignedAvatar.value?:""),modifier = Modifier
                                            .size(30.dp)
                                            .clip(CircleShape)
                                            .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                }
                                Spacer(modifier = Modifier.width(6.dp))
                                Text(memberAssigned.value,
                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                    style =  MaterialTheme.typography.bodySmall
                                    ,
                                    fontSize = 14.sp, color = Color(0xFF5C728A),modifier = Modifier.padding(start = 4.dp))
                            }

                        }

                    }
                    DropdownMenu(
                        expanded = assignToMember.value,
                        onDismissRequest = { assignToMember.value = false },
                        modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                    ){
                        Text("Assign to", fontSize = 16.sp,
                            //   fontFamily = fontFamilyResource(MR.fonts.sf.semibold)
                            style =  MaterialTheme.typography.displayMedium
                        )
                        DockedSearchBar(
                            query = searchText,//text showed on SearchBar
                            onQueryChange = viewModel::onSearchTextChange, //update the value of searchText
                            onSearch = viewModel::onSearchTextChange, //the callback to be invoked when the input service triggers the ImeAction.Search action
                            active = true, //whether the user is searching or not
                            onActiveChange = { viewModel.onToogleSearch() }, //the callback to be invoked when this search bar's active state is changed
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(16.dp),
                            placeholder = {
                                Text(text = "Search members", fontSize = 15.sp,
                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                    style =  MaterialTheme.typography.bodySmall
                                )
                            },
                            leadingIcon = {
                                Icon(imageVector = Icons.Default.Search, contentDescription = null)
                            },
                            colors = SearchBarDefaults.colors(containerColor = Color(0xFFFAFAFA))
                        ) {
                            countriesList.let {
                                Column(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .heightIn(max=250.dp)
                                        .verticalScroll(state = rememberScrollState())
                                ) {
                                    it.forEach { member ->
                                        TextButton(
                                            onClick = {
                                                assignedToId.value = member?.id?:""
                                                memberAssigned.value = member?.name?:""
                                                assignToMember.value = false
                                                memberAssignedAvatar.value = member?.avatar?:""
                                            }
                                        ) {
                                            Row(verticalAlignment = Alignment.CenterVertically) {
                                                if (member?.avatar.isNullOrEmpty()){
                                                    val name = member?.name?:""
                                                    val words = name.split(" ")
                                                    val initials = if (words.size > 1) {
                                                        words[0].take(1) + words[words.lastIndex].take(1)
                                                    } else {
                                                        words[0].take(2)
                                                    }.uppercase()
                                                    TextButton(onClick = {},modifier = Modifier
                                                        .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)
                                                    ){
                                                        Text(
                                                            text = initials,
                                                            fontSize = 16.sp,
                                                            color = Color.White,
                                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                            maxLines = 1,
                                                            overflow = TextOverflow.Ellipsis,
                                                            textAlign = TextAlign.Center
                                                        )
                                                    }

                                                }
                                                else {
                                                    Image(
                                                        rememberImagePainter(member?.avatar?:""),modifier = Modifier
                                                            .size(35.dp)
                                                            .clip(CircleShape)
                                                            .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                                }
                                                Spacer(modifier = Modifier.width(6.dp))

                                                Text(if (member?.id==userId) "${member?.name} - @Me" else member?.name?:"",
                                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                    style =  MaterialTheme.typography.bodySmall,
                                                    fontSize = 14.sp, color = Color.Black,modifier = Modifier.padding(start = 4.dp))
                                            }

                                        }

                                    }
                                }
                            }
                        }
                    }
                }

                Spacer(modifier = Modifier.width(16.dp))

                Box {
                    TextButton(
                        onClick = { priortyChange.value=!priortyChange.value }
                    ) {
                        Row(verticalAlignment = Alignment.CenterVertically) {

                            if (taskPriority.value==""){
                                Image(painterResource(Res.drawable.priority_flag_), modifier = Modifier.size(24.dp), contentDescription = null)
                            } else {
                                Image(
                                    painterResource(when(taskPriority.value){
                                        "Urgent"->Res.drawable.urgent_flag
                                        "Medium"->Res.drawable.medium_flag
                                        "Low"->Res.drawable.low_flag
                                        else -> Res.drawable.urgent_flag
                                    }
                                    )
                                    ,contentDescription = null)
                                Spacer(modifier=Modifier.width(8.dp))
                                Text(taskPriority.value, color = Color(0xFF5C728A),
                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                    style =  MaterialTheme.typography.bodySmall, fontSize = 14.sp)

                            }

                        }


                    }
                    DropdownMenu(
                        expanded = priortyChange.value,
                        onDismissRequest = { priortyChange.value = false },
                        modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                    ){
                        PriorityChooseCreateTask(taskPriority,priortyChange)
                    }
                }

                Spacer(modifier = Modifier.width(16.dp))
                TextButton(
                    onClick = { datePicker.value=true }
                ) {
                    if (dueDate.contains("1970")){
                        Image(painterResource(Res.drawable.due_datepicker_), modifier = Modifier.size(24.dp), contentDescription = null)
                    } else {
                        Text(
                            formatDateString(dueDate),
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                            style =  MaterialTheme.typography.bodySmall, fontSize = 15.sp, color = Color(0xFF5C728A))
                    }


                }



            }
            val basicRichTextState = rememberRichTextState()

            Spacer(modifier = Modifier.height(24.dp))
            Surface(elevation = 3.dp, color = Color(0xFFFAFAFA)) {
                RichTextStyleRow(
                    modifier = Modifier.fillMaxWidth(),
                    state = basicRichTextState,
                )
            }

            Spacer(modifier = Modifier.height(8.dp))
            Box(modifier = Modifier.background(Color(0xFFedf3fc)).padding(12.dp)) {

                if (basicRichTextState.annotatedString.text==""){
                    Text("Type Description", fontSize = 16.sp,
                        //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall, color = Color.Gray,modifier = Modifier.padding(start = 8.dp))
                }
                BasicRichTextEditor(
                    modifier = Modifier.fillMaxWidth().height(150.dp).padding(start = 8.dp),
                    state = basicRichTextState,
                )
                /*
                if (description==""){
                    Text("Type Description", fontSize = 16.sp,
                        //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall, color = Color.Gray,modifier = Modifier.padding(start = 8.dp))
                }

                BasicTextField(value = description,
                    onValueChange = {
                        description=it
                    }
                    ,
                    modifier = Modifier.padding(start = 8.dp).height(156.dp).fillMaxWidth(),
                    textStyle = TextStyle(fontSize = 16.sp, fontFamily = FontFamily(Font(Res.font.sf_regular)) ),
                    keyboardOptions = KeyboardOptions(
                        keyboardType = KeyboardType.Text,
                        imeAction = ImeAction.Done
                    ),


                    ) */
            }
            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(top = 12.dp)) {
                Image(Icons.Default.AttachFile,null,modifier = Modifier.padding(start = 16.dp).clickable {
                    pickerLauncher.launch()
                })
                LazyRow(verticalAlignment = Alignment.CenterVertically) {
                    items(attachments){
                        Box {

                            Card(colors = CardDefaults.cardColors(containerColor = Color(0xFFFAFAFA)), modifier = Modifier.height(144.dp).width(166.dp).padding(start = 8.dp, bottom = 8.dp, end = 6.dp, top = 24.dp),
                                elevation = CardDefaults.cardElevation(defaultElevation = 4.dp)) {
                                Column {
                                    if (it.contains(".pdf")){
                                        Image(painterResource(Res.drawable.pdf_svgrepo_com),null,modifier = Modifier.weight(0.8f).fillMaxWidth(), contentScale = ContentScale.Crop)

                                    } else {
                                        Image(rememberImagePainter(it),null,modifier = Modifier.weight(0.75f).fillMaxWidth(), contentScale = ContentScale.Crop)
                                    }
                                    Text(extractFileNameFromUrl(it), textAlign = TextAlign.Center,overflow = TextOverflow.Ellipsis, style =  MaterialTheme.typography.bodyMedium, fontSize = 14.sp,color = Color(0xFF5C728A),modifier = Modifier.weight(0.25f).padding(horizontal = 8.dp).padding(top = 4.dp), maxLines = 1)

                                }

                            }
                            Image(painterResource(Res.drawable.delete),contentDescription = null, modifier = Modifier.align(
                                Alignment.TopEnd).size(18.dp).clickable { attachments.remove(it) })
                        }

                    }
                }
                Spacer(modifier = Modifier.weight(1f))
                Column {
                    if (attachments.isNotEmpty()) Spacer(modifier = Modifier.weight(1f))
                    Button(//contentPadding = PaddingValues(horizontal = 12.dp, vertical = 7.dp),
                        modifier = Modifier
                            .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp)
                        ,
                        shape = RoundedCornerShape(8.dp),
                        onClick = {
                            viewModel.createTask(
                                CreateTaskRequest(
                                    name = taskName,
                                    description = basicRichTextState.toHtml(),
                                    //  description = description,
                                    projectId = projectId, featureId = null,  priority = taskPriority.value,
                                    assignedTo = if (assignedToId.value!="")assignedToId.value else null,
                                    category = category.value,
                                    attachments = attachments, taskStatus = taskStatus.value, dueDate = if (dueDate.contains("1970")) null else dueDate
                                )
                            )
                        },
                        enabled = (taskName.isNotEmpty())
                    ){
                        Text("Create", style = MaterialTheme.typography.bodyMedium, fontSize = 15.sp)
                    }
                }



            }




        }

    }
