/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param id 
 * @param name 
 * @param columns 
 * @param numPeopleOptions 
 * @param toolUsed 
 * @param workType 
 * @param viewLayout 
 * @param status 
 * @param createdBy 
 * @param createdAt 
 * @param invitedUsers 
 * @param memberCount 
 */
@Serializable

data class Board (

    @SerialName(value = "id") @Required val id: kotlin.String,

    @SerialName(value = "name") @Required val name: kotlin.String,

    @SerialName(value = "columns") @Required val columns: kotlin.collections.List<kotlin.String>,

    @SerialName(value = "numPeopleOptions") val numPeopleOptions: kotlin.String? = null,

    @SerialName(value = "toolUsed") val toolUsed: kotlin.String? = null,

    @SerialName(value = "workType") val workType: kotlin.String? = null,

    @SerialName(value = "viewLayout") val viewLayout: kotlin.String? = null,

    @SerialName(value = "status") val status: kotlin.String? = null,

    @SerialName(value = "createdBy") val createdBy: kotlin.String? = null,

    @SerialName(value = "createdAt") val createdAt: kotlin.String? = null,

    @SerialName(value = "invitedUsers") val invitedUsers: kotlin.collections.List<kotlin.String>? = null,

    @SerialName(value = "memberCount") val memberCount: kotlin.Int? = null

)

