/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models

import org.openapitools.client.models.User

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param id 
 * @param type 
 * @param subType 
 * @param sender 
 * @param reference 
 * @param createdAt 
 * @param title 
 * @param referenceId 
 * @param content 
 * @param isRead 
 * @param isViewed 
 */
@Serializable

data class NotificationItems (

    @SerialName(value = "id") val id: kotlin.String? = null,

    @SerialName(value = "type") val type: kotlin.String? = null,

    @SerialName(value = "subType") val subType: kotlin.String? = null,

    @SerialName(value = "sender") val sender: User? = null,

    @SerialName(value = "reference") val reference: User? = null,

    @SerialName(value = "createdAt") val createdAt: kotlin.String? = null,

    @SerialName(value = "title") val title: kotlin.String? = null,

    @SerialName(value = "referenceId") val referenceId: kotlin.String? = null,

    @SerialName(value = "content") val content: kotlin.String? = null,

    @SerialName(value = "isRead") var isRead: kotlin.Boolean? = null,

    @SerialName(value = "isViewed") var isViewed: kotlin.Boolean? = null

)

