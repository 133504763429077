package org.company.app.presentation.component
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.graphics.vector.rememberVectorPainter
import cafe.adriel.voyager.navigator.Navigator
import cafe.adriel.voyager.navigator.tab.Tab
import cafe.adriel.voyager.navigator.tab.TabOptions

import org.company.app.presentation.ui.DocsScreen
import org.company.app.presentation.ui.board.TaskBoardScreen

import org.jetbrains.compose.resources.ExperimentalResourceApi


class HomeTab(
    val navigateWithRouter: () -> Unit = {},
    val navigateBackFromCardDetails: () -> Unit = {},
    val taskId: MutableState<String?>? = null,
    val name: String,
) : Tab {
        @OptIn(ExperimentalResourceApi::class)
        override val options: TabOptions
            @Composable
            get() {
                val title = "Home"
                val icon = rememberVectorPainter(Icons.Filled.Home)

                return remember {
                    TabOptions(
                        index = 0u,
                        title = title,
                        icon = icon
                    )
                }
            }

        @Composable
        override fun Content() {
           // Navigator(HomeDashboard(controlProject,name))

            Navigator(TaskBoardScreen(navigateWithRouter,navigateBackFromCardDetails,taskId))

        }
    }

class TaskTab(var controlProject: SnapshotStateList<String>) : Tab {
    @OptIn(ExperimentalResourceApi::class)
    override val options: TabOptions
        @Composable
        get() {
            val title = "Task"
            val icon = rememberVectorPainter(Icons.Filled.Home)

            return remember {
                TabOptions(
                    index = 1u,
                    title = title,
                    icon = icon
                )
            }
        }

    @Composable
    override fun Content() {



    }
}

    internal object GraphTab : Tab {
        @OptIn(ExperimentalResourceApi::class)
        override val options: TabOptions
            @Composable
            get() {
                val title = "Graph"
                val icon = rememberVectorPainter(Icons.Filled.Home)

                return remember {
                    TabOptions(
                       // index = 4u,
                        index = 2u,
                        title = title,
                        icon = icon
                    )
                }
            }

        @Composable
        override fun Content() {
            // MOKO MVVM declaration -- no DI
            //val viewModel = getViewModel(Unit, viewModelFactory { BirdsViewModel() })

            // Koin DI declaration
          //  val viewModel = koinInject<BirdsViewModel>()

          //  Navigator(BirdsScreen(viewModel))
        }
    }

internal object CalenderTab : Tab {
    @OptIn(ExperimentalResourceApi::class)
    override val options: TabOptions
        @Composable
        get() {
            val title = "Calender"
            val icon = rememberVectorPainter(Icons.Filled.Home)

            return remember {
                TabOptions(
                    index = 3u,
                    title = title,
                    icon = icon
                )
            }
        }

    @Composable
    override fun Content() {
        // MOKO MVVM declaration -- no DI
        //val viewModel = getViewModel(Unit, viewModelFactory { BirdsViewModel() })

        // Koin DI declaration
     //   val viewModel = koinInject<BirdsViewModel>()

    //    Navigator(BirdsScreen(viewModel))
    }
}

internal object DocsTab : Tab {
    @OptIn(ExperimentalResourceApi::class)
    override val options: TabOptions
        @Composable
        get() {
            val title = "Docs"
            val icon = rememberVectorPainter(Icons.Default.FilePresent)

            return remember {
                TabOptions(
                    index = 3u,
                    title = title,
                    icon = icon
                )
            }
        }

    @Composable
    override fun Content() {

        Navigator(DocsScreen)
    }
}


internal object CreateTaskTab : Tab {
    @OptIn(ExperimentalResourceApi::class)
    override val options: TabOptions
        @Composable
        get() {
            val title = "Calender"
            val icon = rememberVectorPainter(Icons.Filled.Home)

            return remember {
                TabOptions(
                    index = 3u,
                    title = title,
                    icon = icon
                )
            }
        }

    @Composable
    override fun Content() {
        // MOKO MVVM declaration -- no DI
        //val viewModel = getViewModel(Unit, viewModelFactory { BirdsViewModel() })

        // Koin DI declaration
     //   val viewModel = koinInject<BirdsViewModel>()

      //  Navigator(BirdsScreen(viewModel))
    }
}