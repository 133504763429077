/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package api

import org.openapitools.client.models.CreateTaskRequest
import org.openapitools.client.models.MessageResponse
import org.openapitools.client.models.TaskDetailResponse
import org.openapitools.client.models.TaskListResponse
import org.openapitools.client.models.TaskResponse
import org.openapitools.client.models.ToDo
import org.openapitools.client.models.ToDoList
import org.openapitools.client.models.ToDoStatus
import org.openapitools.client.models.ToDoWithDueDate
import org.openapitools.client.models.ToDoWithMultipleAssignees
import org.openapitools.client.models.ToDoWithoutAssociatedRecord
import org.openapitools.client.models.UpdateTaskRequest

import org.openapitools.client.infrastructure.*
import io.ktor.client.HttpClient
import io.ktor.client.HttpClientConfig
import io.ktor.client.request.forms.formData
import io.ktor.client.engine.HttpClientEngine
import kotlinx.serialization.json.Json
import io.ktor.http.ParametersBuilder
import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

open class ToDoApi : ApiClient {

    constructor(
        baseUrl: String = ApiClient.BASE_URL,
        httpClientEngine: HttpClientEngine? = null,
        httpClientConfig: ((HttpClientConfig<*>) -> Unit)? = null,
        jsonSerializer: Json = ApiClient.JSON_DEFAULT
    ) : super(baseUrl = baseUrl, httpClientEngine = httpClientEngine, httpClientConfig = httpClientConfig, jsonBlock = jsonSerializer)

    constructor(
        baseUrl: String,
        httpClient: HttpClient
    ): super(baseUrl = baseUrl, httpClient = httpClient)

    /**
     * Create a Task
     * 
     * @param createTaskRequest Task Data
     * @param authorization Access token for authorization (optional)
     * @return TaskResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createTask(createTaskRequest: CreateTaskRequest, authorization: kotlin.String? = null): HttpResponse<TaskResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = createTaskRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/tasks/createTask",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create a To-Do associated with a feature
     * 
     * @param toDo To-Do Data for Feature
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createToDoForFeature(toDo: ToDo): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = toDo

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createToDoForFeature",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create a To-Do with Due Date
     * 
     * @param toDoWithDueDate To-Do Data with Due Date
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createToDoWithDueDate(toDoWithDueDate: ToDoWithDueDate): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = toDoWithDueDate

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createToDoWithDueDate",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create a To-Do with Multiple Assignees
     * 
     * @param toDoWithMultipleAssignees To-Do Data with Multiple Assignees
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createToDoWithMultipleAssignees(toDoWithMultipleAssignees: ToDoWithMultipleAssignees): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = toDoWithMultipleAssignees

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createToDoWithMultipleAssignees",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Create a To-Do without an Associated Record
     * 
     * @param toDoWithoutAssociatedRecord To-Do Data without Associated Record
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createToDoWithoutAssociatedRecord(toDoWithoutAssociatedRecord: ToDoWithoutAssociatedRecord): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = toDoWithoutAssociatedRecord

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createToDoWithoutAssociatedRecord",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Delete a Task
     * 
     * @param taskId 
     * @param authorization Access token for authorization (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun deleteTask(taskId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.DELETE,
            "/tasks/deleteTask/{taskId}".replace("{" + "taskId" + "}", "$taskId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Delete a To-Do
     * 
     * @param todoId The ID of the to-do to delete.
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun deleteToDo(todoId: kotlin.Int): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        todoId?.apply { localVariableQuery["todoId"] = listOf("$todoId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.DELETE,
            "/deleteToDo/todoId",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Tasks of Feature
     * 
     * @param featureId 
     * @param authorization Access token for authorization (optional)
     * @param taskStatus fiter n task status (optional)
     * @return TaskListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getFeatureTasks(featureId: kotlin.String, authorization: kotlin.String? = null, taskStatus: kotlin.String? = null): HttpResponse<TaskListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        taskStatus?.apply { localVariableQuery["taskStatus"] = listOf("$taskStatus") }
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/tasks/getFeatureTasks/{featureId}".replace("{" + "featureId" + "}", "$featureId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get My Tasks
     * 
     * @param isFeatureTask Check is feaature task
     * @param authorization Access token for authorization (optional)
     * @param taskStatus fiter n task status (optional)
     * @param priority fiter on task priority (optional)
     * @param assignedTo fiter on assignedTo (optional)
     * @param scheduledDate fiter on scheduledDate (optional)
     * @param dueDate fiter on dueDate (optional)
     * @param name fiter on name (optional)
     * @param description fiter on description (optional)
     * @param nextPageToken asdaksljdfksajf (optional)
     * @return TaskListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getMyTasks(isFeatureTask: kotlin.Boolean, authorization: kotlin.String? = null, taskStatus: kotlin.String? = null, priority: kotlin.String? = null, assignedTo: kotlin.String? = null, scheduledDate: kotlin.String? = null, dueDate: kotlin.String? = null, name: kotlin.String? = null, description: kotlin.String? = null, nextPageToken: kotlin.String? = null): HttpResponse<TaskListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        isFeatureTask?.apply { localVariableQuery["isFeatureTask"] = listOf("$isFeatureTask") }
        taskStatus?.apply { localVariableQuery["taskStatus"] = listOf("$taskStatus") }
        priority?.apply { localVariableQuery["priority"] = listOf("$priority") }
        assignedTo?.apply { localVariableQuery["assignedTo"] = listOf("$assignedTo") }
        scheduledDate?.apply { localVariableQuery["scheduledDate"] = listOf("$scheduledDate") }
        dueDate?.apply { localVariableQuery["dueDate"] = listOf("$dueDate") }
        name?.apply { localVariableQuery["name"] = listOf("$name") }
        description?.apply { localVariableQuery["description"] = listOf("$description") }
        nextPageToken?.apply { localVariableQuery["nextPageToken"] = listOf("$nextPageToken") }
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/tasks/getMyTasks",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Tasks of Project
     * 
     * @param projectId 
     * @param authorization Access token for authorization (optional)
     * @param taskStatus fiter n task status (optional)
     * @param priority fiter on task priority (optional)
     * @param assignedTo fiter on assignedTo (optional)
     * @param scheduledDate fiter on scheduledDate (optional)
     * @param dueDate fiter on dueDate (optional)
     * @param name fiter on name (optional)
     * @param description fiter on description (optional)
     * @param nextPageToken asdaksljdfksajf (optional)
     * @return TaskListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getProjectTasks(projectId: kotlin.String, authorization: kotlin.String? = null, taskStatus: kotlin.String? = null, priority: kotlin.String? = null, assignedTo: kotlin.String? = null, scheduledDate: kotlin.String? = null, dueDate: kotlin.String? = null, name: kotlin.String? = null, description: kotlin.String? = null, nextPageToken: kotlin.String? = null): HttpResponse<TaskListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        taskStatus?.apply { localVariableQuery["taskStatus"] = listOf("$taskStatus") }
        priority?.apply { localVariableQuery["priority"] = listOf("$priority") }
        assignedTo?.apply { localVariableQuery["assignedTo"] = listOf("$assignedTo") }
        scheduledDate?.apply { localVariableQuery["scheduledDate"] = listOf("$scheduledDate") }
        dueDate?.apply { localVariableQuery["dueDate"] = listOf("$dueDate") }
        name?.apply { localVariableQuery["name"] = listOf("$name") }
        description?.apply { localVariableQuery["description"] = listOf("$description") }
        nextPageToken?.apply { localVariableQuery["nextPageToken"] = listOf("$nextPageToken") }
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/tasks/getProjectTasks/{projectId}".replace("{" + "projectId" + "}", "$projectId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get a Specific To-Do
     * 
     * @param todoId The ID of the to-do to retrieve.
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getSpecificToDo(todoId: kotlin.Int): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        todoId?.apply { localVariableQuery["todoId"] = listOf("$todoId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/getSpecificToDo/todoId",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Sub-Tasks of Task
     * 
     * @param taskId 
     * @param authorization Access token for authorization (optional)
     * @param nextPageToken asdaksljdfksajf (optional)
     * @return TaskListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getSubTasks(taskId: kotlin.String, authorization: kotlin.String? = null, nextPageToken: kotlin.String? = null): HttpResponse<TaskListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        nextPageToken?.apply { localVariableQuery["nextPageToken"] = listOf("$nextPageToken") }
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/tasks/getSubTasks/{taskId}".replace("{" + "taskId" + "}", "$taskId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Task Details
     * 
     * @param taskId 
     * @param authorization Access token for authorization (optional)
     * @return TaskDetailResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getTaskDetails(taskId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<TaskDetailResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/tasks/getTaskDetails/{taskId}".replace("{" + "taskId" + "}", "$taskId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Tasks Updated After a Certain Time
     * 
     * @param taskId Get tasks updated after this time.
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getTasksUpdatedAfter(taskId: kotlinx.datetime.Instant): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        taskId?.apply { localVariableQuery["taskId"] = listOf("$taskId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/getTasksUpdatedAfte/taskIdr",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List To-Dos
     * 
     * @return ToDoList
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listToDos(): HttpResponse<ToDoList> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listToDos",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List To-Dos Assigned to a User
     * 
     * @param userId The ID of the user to filter by.
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listToDosAssignedToUser(userId: kotlin.Int): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        userId?.apply { localVariableQuery["userId"] = listOf("$userId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listToDosAssignedToUser/userId",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List To-Dos for an Epic
     * 
     * @param epicId The ID of the epic to filter by.
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listToDosForEpic(epicId: kotlin.Int): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        epicId?.apply { localVariableQuery["epicId"] = listOf("$epicId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listToDosForEpic/epicId",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List To-Dos for a Feature
     * 
     * @param featureId The ID of the feature to filter by.
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listToDosForFeature(featureId: kotlin.String): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        featureId?.apply { localVariableQuery["featureId"] = listOf("$featureId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listToDosForFeature/featureId",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List To-Dos for an Idea
     * 
     * @param ideaId The ID of the idea to filter by.
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listToDosForIdea(ideaId: kotlin.Int): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        ideaId?.apply { localVariableQuery["ideaId"] = listOf("$ideaId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listToDosForIdea/ideaId",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List To-Dos for a Product
     * 
     * @param productId The ID of the product to filter by.
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listToDosForProduct(productId: kotlin.Int): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        productId?.apply { localVariableQuery["productId"] = listOf("$productId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listToDosForProduct/productId",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List To-Dos for a Release
     * 
     * @param releaseId The ID of the release to filter by.
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listToDosForRelease(releaseId: kotlin.Int): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        releaseId?.apply { localVariableQuery["releaseId"] = listOf("$releaseId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listToDosForRelease/releaseId",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * List To-Dos for a Requirement
     * 
     * @param requirementId The ID of the requirement to filter by.
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun listToDosForRequirement(requirementId: kotlin.Int): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        requirementId?.apply { localVariableQuery["requirementId"] = listOf("$requirementId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/listToDosForRequirement/requirementId",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Update a Task
     * 
     * @param taskId 
     * @param updateTaskRequest Task Data
     * @param authorization Access token for authorization (optional)
     * @return TaskResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateTask(taskId: kotlin.String, updateTaskRequest: UpdateTaskRequest, authorization: kotlin.String? = null): HttpResponse<TaskResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = updateTaskRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/tasks/updateTask/{taskId}".replace("{" + "taskId" + "}", "$taskId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update a To-Do
     * 
     * @param todoId The ID of the To-Do.
     * @return ToDo
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateToDo(todoId: kotlin.Int): HttpResponse<ToDo> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        todoId?.apply { localVariableQuery["todoId"] = listOf("$todoId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/updateToDo/todoId",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Update a To-Do&#39;s Status
     * 
     * @param todoId The ID of the To-Do to be updated.
     * @return ToDoStatus
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateToDoStatus(todoId: kotlin.Int): HttpResponse<ToDoStatus> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        todoId?.apply { localVariableQuery["todoId"] = listOf("$todoId") }
        val localVariableHeaders = mutableMapOf<String, String>()

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/updateToDoStatus/todoId",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


}
