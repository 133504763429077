package org.company.app.di

import org.company.app.presentation.viewmodel.*
import org.koin.dsl.module

val viewModelsModule = module {

    single<LoginViewModel> {
        LoginViewModel(get())
    }
    single<RegisterViewModel> {
        RegisterViewModel(get())
    }
    single<OnBoardingViewModel> {
        OnBoardingViewModel(get())
    }
    single<HomeViewModel> {
        HomeViewModel(get(),get(),get(),get(),get(),get(),get())
    }

    single<DocsViewModel> {
        DocsViewModel(get(), it.get())
    }
}