package org.company.app.presentation.ui.board.menu.slide

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.MaterialTheme
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.*
import androidx.compose.material3.Checkbox
import androidx.compose.material3.Divider
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.rememberVectorPainter
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.jettaskboard.multiplatform.ui.screens.board.ExpandedBoardDrawerState

import org.jetbrains.compose.resources.ExperimentalResourceApi

@Composable
fun SlideMenu(
    modifier: Modifier = Modifier,
    backgroundColor: Color,
    changeToFullScreen: () -> Unit,
    ) {
    Box(
        modifier = modifier
            .fillMaxWidth(0.36f)
            .drawWithCache {
                onDrawBehind {
                    drawRect(color = backgroundColor)
                }
            }
            .shadow(elevation = 0.2.dp)
            .verticalScroll(rememberScrollState())

            // click without hoaver effect
         /*  .clickable(
                onClick = {
                    // Do something when the user clicks outside the box
                          changeToFullScreen()
                },
                indication = null,
                interactionSource = remember { MutableInteractionSource() }
            ) */

    ) {

        Column(
            modifier = Modifier.align(Alignment.CenterEnd).fillMaxHeight()
                .padding(horizontal = 16.dp, vertical = 16.dp),
            horizontalAlignment = Alignment.Start,
        ) {
            // Slide Menu content
            SlideMenuContent()


            Spacer(modifier = Modifier.weight(1f))
            Row {
                Spacer(modifier = Modifier.weight(1f))
                Icon(Icons.Default.Expand,null, modifier = Modifier.clickable { changeToFullScreen() })

            }


        }

    }
}
@Composable
fun SlideMenuContent(){


}



@Composable
fun SlideMenu(
    modifier: Modifier = Modifier,
    backgroundColor: Color,
    changeToFullScreen: () -> Unit,
    content: @Composable () -> Unit,
) {
    Box(
        modifier = modifier
            .fillMaxWidth(0.36f)
            .drawWithCache {
                onDrawBehind {
                    drawRect(color = backgroundColor)
                }
            }
            .shadow(elevation = 0.2.dp)
            .verticalScroll(rememberScrollState())

        // click without hoaver effect
        /*  .clickable(
               onClick = {
                   // Do something when the user clicks outside the box
                         changeToFullScreen()
               },
               indication = null,
               interactionSource = remember { MutableInteractionSource() }
           ) */

    ) {

        Column(
            modifier = Modifier.fillMaxHeight()
                .padding(horizontal = 16.dp, vertical = 16.dp),
            horizontalAlignment = Alignment.Start,
        ) {
            // Slide Menu content
            content()
        }

    }
}


@Composable
fun SlideMenu(
    modifier: Modifier = Modifier,
    backgroundColor: Color,
    navigateToChangeBackgroundRoute: (String) -> Unit,
    changeToDrawerScreenState: () -> Unit,
    updateBoardBg: (String) -> Unit,
    expandedScreenState: ExpandedBoardDrawerState
) {
    Box(
        modifier = modifier
            .fillMaxWidth(0.36f)
            .drawWithCache {
                onDrawBehind {
                    drawRect(color = backgroundColor)
                }
            }
            .shadow(elevation = 0.dp)
    ) {
        when (expandedScreenState) {
            ExpandedBoardDrawerState.DRAWER_SCREEN_STATE -> {
                SlideMenuContent(
                    navigateToChangeBackgroundRoute = navigateToChangeBackgroundRoute
                )
            }

            ExpandedBoardDrawerState.CHANGE_BACKGROUND_SCREEN_STATE -> {

            }

            ExpandedBoardDrawerState.FILTER_SCREEN_STATE -> {
                // Do Nothing
            }
        }
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun SlideMenuContent(
    navigateToChangeBackgroundRoute: (String) -> Unit,
    navigateToFilterRoute: () -> Unit = {},
    navigateToAutomationRoute: () -> Unit = {},
    navigateToPowerUpRoute: () -> Unit = {},
) {
    Column(
        modifier = Modifier.fillMaxHeight(),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {


        // Filter Item
        SlideMenuItem(
            title = "Filter",
            icon = rememberVectorPainter(Icons.Default.Filter) ,
            onItemClickListener = navigateToChangeBackgroundRoute
        )


    }
}

@Composable
fun SlideMenuItem(
    title: String = "Change Background",
    icon: Painter,
    onItemClickListener: (String) -> Unit
) {
    Row(
        modifier = Modifier
            .clickable { onItemClickListener("") }
            .padding(16.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        androidx.compose.material.Icon(
            painter = icon,
            modifier = Modifier.size(18.dp),
            contentDescription = "star",
            tint = Color(0xFFFFEB3B)
        )

        androidx.compose.material.Text(
            text = title,
            style = MaterialTheme.typography.caption,
            modifier = Modifier
                .padding(start = 16.dp)
                .weight(1f),
            fontSize = 14.sp
        )
    }
}