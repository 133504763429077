/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param id 
 * @param email 
 * @param sharedLink 
 * @param boardId 
 */
@Serializable

data class BoardMemberInvite (

    @SerialName(value = "id") @Required val id: kotlin.String,

    @SerialName(value = "email") @Required val email: kotlin.String,

    @SerialName(value = "sharedLink") @Required val sharedLink: kotlin.String,

    @SerialName(value = "boardId") val boardId: kotlin.String? = null

)

