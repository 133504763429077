/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param id 
 * @param name 
 * @param `value` 
 * @param order 
 * @param considerCompleted 
 * @param isBlocker 
 */
@Serializable

data class TaskStatusDetailsResponse (

    @SerialName(value = "_id") val id: kotlin.String? = null,

    @SerialName(value = "name") var name: kotlin.String? = null,

    @SerialName(value = "value") val `value`: kotlin.String? = null,

    @SerialName(value = "order") var order: kotlin.Int? = null,

    @SerialName(value = "considerCompleted") val considerCompleted: kotlin.Boolean? = null,

    @SerialName(value = "isBlocker") val isBlocker: kotlin.Boolean? = null

)

