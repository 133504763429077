/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param id 
 * @param email 
 * @param role 
 * @param name 
 * @param userName 
 * @param avatar 
 */
@Serializable

data class BoardMember (

    @SerialName(value = "id") val id: kotlin.String? = null,

    @SerialName(value = "email") val email: kotlin.String? = null,

    @SerialName(value = "role") val role: kotlin.String? = null,

    @SerialName(value = "name") val name: kotlin.String? = null,

    @SerialName(value = "userName") val userName: kotlin.String? = null,

    @SerialName(value = "avatar") val avatar: kotlin.String? = null

)

