package org.company.app.presentation.component

import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

@Composable
fun CustomAlertDialog(title:String="Delete Task",description:String="Are you sure you want to delete this task?",confirmButtonTitle:String="Delete",onDelete: () -> Unit, onDismiss: () -> Unit) {
    val openDialog = remember { mutableStateOf(true) }

    if (openDialog.value) {
        AlertDialog(modifier = Modifier.widthIn(min = 500.dp),
            onDismissRequest = { openDialog.value = false
                                 onDismiss()
                               },
            title = { Text(text = title, fontSize = 20.sp,style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily, color = Color(0xFFE35D6A)) },
            text = { Text(text = description, fontSize = 16.sp,style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A)) },
            containerColor = Color(0xFFFAFAFA),
            confirmButton = {
                Button(
                    onClick = {
                        onDelete()
                        openDialog.value = false
                    },
                    colors = ButtonDefaults.buttonColors(
                      //  containerColor = MaterialTheme.colors.error
                    ),
                    contentPadding = ButtonDefaults.ContentPadding,
                    elevation = ButtonDefaults.buttonElevation(
                        defaultElevation = 0.dp,
                        pressedElevation = 0.dp,
                        disabledElevation = 0.dp,
                        hoveredElevation = 0.dp,
                        focusedElevation = 0.dp
                    )
                ) {
                    Text(text = confirmButtonTitle, style = MaterialTheme.typography.bodyMedium, fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                }
            },
            dismissButton = {
                TextButton(
                    onClick = {
                        openDialog.value = false
                        onDismiss()
                    }
                ) {
                    Text(text = "Cancel",style = MaterialTheme.typography.bodyMedium, fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,color = Color(0xFF3b4958))
                }
            }
        )
    }
}