package org.company.app.presentation.viewmodel

import androidx.compose.ui.text.input.TextFieldValue
import dev.icerock.moko.mvvm.viewmodel.ViewModel
import io.github.aakira.napier.Napier
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.utils.io.charsets.*
import io.ktor.utils.io.charsets.Charsets.UTF_8
import io.ktor.utils.io.core.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.company.app.data.model.DocsContentResponse
import org.company.app.data.model.DocsFileRequest
import org.company.app.data.model.DocsFileResponse

import org.company.app.util.Constants
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi


public class DocsViewModel(private val httpClient: HttpClient, private val folderName: String) : ViewModel() {

    private var _docsContentState = MutableStateFlow<List<DocsContentResponse>>(emptyList())
    val docsContentState: StateFlow<List<DocsContentResponse>> = _docsContentState

    private var _fileContentState = MutableStateFlow(TextFieldValue(""))
    val fileContentState: StateFlow<TextFieldValue> = _fileContentState

    private var _currentFileState = MutableStateFlow<DocsContentResponse?>(null)
    val currentFileState: StateFlow<DocsContentResponse?> = _currentFileState

    private var _loadingState = MutableStateFlow(false)
    var loadingState: StateFlow<Boolean> = _loadingState


    init {
        getDocsContentForProject()
    }


    fun getDocsContentForProject() {
        viewModelScope.launch() {
            _loadingState.value = true
            _docsContentState.value = try {
                val docsContentListResponse = httpClient.get {
                    url("${Constants.GITHUB_BASE_URL}/$folderName")
                }.body<MutableList<DocsContentResponse>>()
                if (_currentFileState.value != null) _currentFileState.update {
                    docsContentListResponse.searchByPath(
                        _currentFileState.value?.path.toString()
                    )
                }
                docsContentListResponse
            } catch (e: ClientRequestException) {
                Napier.d("List error - ${e.response.status}")
                emptyList()
            } finally {
                _loadingState.value = false
            }
        }

    }

    fun getContentForFolder(folderPath: String) {
        viewModelScope.launch() {
            _loadingState.value = true
            try {
                val docsContentListResponse = httpClient.get {
                    url("${Constants.GITHUB_BASE_URL}/$folderPath")
//                    header("Authorization", "Bearer ${BuildConfig.GITHUB_TOKEN}")
                }.body<MutableList<DocsContentResponse>>()
                _docsContentState.update { list ->
                    list.map { updateRecursive(it, folderPath, docsContentListResponse) }
                }
            } catch (e: ClientRequestException) {
                Napier.d("Folder get content error - ${e.response}")
            } finally {
                _loadingState.value = false
            }
        }
    }

    @OptIn(ExperimentalEncodingApi::class)
    fun getContentForFile(docsContentResponse: DocsContentResponse) {
        viewModelScope.launch() {
            _loadingState.value = true
            val docsGetFileResponse = httpClient.get {
                url("${Constants.GITHUB_BASE_URL}/${docsContentResponse.path}")
//                header("Authorization", "Bearer ${BuildConfig.GITHUB_TOKEN}")
            }.body<DocsContentResponse>()
            _fileContentState.value = TextFieldValue(
                String(
                    Base64.decode(docsGetFileResponse.content.toString().replace("\n", "")),
                    charset = UTF_8
                )
            )
            _docsContentState.value.searchByPath(docsGetFileResponse.path.toString())?.content =
                _fileContentState.value.text
            _docsContentState.value.searchByPath(docsGetFileResponse.path.toString())?.sha =
                docsGetFileResponse.sha
            docsGetFileResponse.content = _fileContentState.value.text
            _currentFileState.update { docsGetFileResponse }
            _loadingState.value = false
//            Napier.d(Json.encodeToString(_docsContentState.value.searchByPath(docsContentResponse.path.toString())))
        }
    }

    fun updateContentForFile(textFieldValue: TextFieldValue) {
        _fileContentState.value = textFieldValue

    }

    fun addSubFolder(addItem: DocsContentResponse, parent: DocsContentResponse?) {
        _docsContentState.update { list ->
            if (parent != null) {
                list.map { addRecursive(it, parent.path.toString(), addItem) }
            } else {
                list + addItem
            }
        }
    }

    fun deleteFolder(folder: DocsContentResponse) {
        viewModelScope.launch {
            try {
                val docsContentListResponse = if (folder.children.isEmpty()) {
                    httpClient.get {
                        url("${Constants.GITHUB_BASE_URL}/${folder.path.toString()}")
    //                    header("Authorization", "Bearer ${BuildConfig.GITHUB_TOKEN}")
                    }.body<MutableList<DocsContentResponse>>()
                } else {
                    folder.children
                }
                val deleteFolder = folder.copy(children = docsContentListResponse)
                deleteFolder.children.forEach { content ->
                    if (content.type == "file") {
                        deleteFile(content)
                        delay(1000)
                    } else {
                        deleteFolder(content)
                    }
                }

                _docsContentState.value =
                    if (folder.parent != null) {
                        _docsContentState.value.map { removeRecursive(it, folder) }
                    } else {

                        _docsContentState.value.filter { it.path != folder.path }
                    }
                Napier.d("Removed after: ${Json.encodeToString(_docsContentState.value)}, To remove: ${Json.encodeToString(folder)}")

            } catch (e: ClientRequestException) {
                Napier.d("Folder delete error - ${e.response}")
            }

        }

    }

    @OptIn(ExperimentalEncodingApi::class)
    fun addOrUpdateFile(file: DocsContentResponse, isUpdate: Boolean = true) {
        try {
            viewModelScope.launch() {
                _loadingState.value = !isUpdate
                val docsFileResponse = httpClient.put {
                    url("${Constants.GITHUB_BASE_URL}/${file.path}")
                    //                header("Authorization", "Bearer ${BuildConfig.GITHUB_TOKEN}")
                    accept(ContentType.Application.Json)
                    setBody(
                        DocsFileRequest(
                            message = "${if (isUpdate) "Update" else "Create"} ${file.name}",
                            content = file.content?.toByteArray()?.let { Base64.encode(it) } ?: "",
                            sha = file.sha
                        )
                    )
                }.body<DocsFileResponse>()

                if (!isUpdate) {
                    _docsContentState.update { list ->
                        if (file.parent != null) {
                            list.map { addRecursive(it, docsFileResponse.content.copy(parent = file.parent)) }
                        } else {
                            list + file
                        }
                    }
                }
                _currentFileState.value = docsFileResponse.content
                getContentForFile(docsFileResponse.content)

            }
        } catch (e: Exception) {
            Napier.i(e.message.toString(), e)
        } finally {
            _loadingState.value = false
        }
    }

    fun deleteFile(file: DocsContentResponse) {
//        Napier.d("To delete ${Json.encodeToString(file)}")
        viewModelScope.launch() {
            try {
                _loadingState.value = true
                httpClient.delete {
                    url("${Constants.GITHUB_BASE_URL}/${file.path}")
    //                header("Authorization", "Bearer ${BuildConfig.GITHUB_TOKEN}")
                    accept(ContentType.Application.Json)
                    setBody(
                        DocsFileRequest(
                            message = "Delete ${file.name}",
                            sha = file.sha
                        )
                    )
                }.also { httpResponse ->
                    if (httpResponse.status == HttpStatusCode.OK) {
                        _docsContentState.update { list ->
                            if (file.parent != null) {
                                list.map { removeRecursive(it, file) }
                            } else {
                                list - file
                            }
                        }
                        if (file.path == currentFileState.value?.path) clearCurrentFile()
                        Napier.d("After delete ${Json.encodeToString(_docsContentState.value)}")
                    }
                }
            } catch (e: ClientRequestException) {
                Napier.d("File delete error - ${e.message}")
            } finally {
                _loadingState.value = false
            }

        }
    }

    fun clearCurrentFile() {
        _currentFileState.value = null
    }

    fun clearDocsList() {
        _docsContentState.value = emptyList()
    }

}

fun removeRecursive(docsContentResponse: DocsContentResponse, file: DocsContentResponse): DocsContentResponse {
    Napier.d("Current: ${docsContentResponse.path}; File to remove: ${file.path?.substringBeforeLast('/')}")
    if (docsContentResponse.path == file.path?.substringBeforeLast('/')) {
        Napier.d("Got into remove")
        return docsContentResponse.copy(children = docsContentResponse.children.filter { it.path != file.path })
    }
    return docsContentResponse.copy(children = docsContentResponse.children.map {
        removeRecursive(
            it,
            file
        )
    })
}

fun removeChild(current: DocsContentResponse, file: DocsContentResponse): DocsContentResponse {
    if (current.children.contains(file)) {
        // Current node is parent
        val updatedChildren = current.children - file
        return if (updatedChildren.isEmpty()) {
            // Remove parent
            DocsContentResponse()
        } else {
            current.copy(children = updatedChildren)
        }
    } else {
        return current.copy(
            children = current.children.map { removeChild(it, file) }
        )
    }
}


fun addRecursive(docsContentResponse: DocsContentResponse, file: DocsContentResponse): DocsContentResponse {
    if (docsContentResponse.path == file.path?.substringBeforeLast('/')) {

        return docsContentResponse.copy(children = (docsContentResponse.children + file).sortedBy { it.name })
    }
    return docsContentResponse.copy(children = docsContentResponse.children.map {
        addRecursive(
            it,
            file
        )
    })
}


fun addRecursive(
    docsContentResponse: DocsContentResponse,
    folderPath: String,
    addItem: DocsContentResponse
): DocsContentResponse {
    if (docsContentResponse.path == folderPath) {
        return docsContentResponse.copy(children = docsContentResponse.children + addItem)
    }
    return docsContentResponse.copy(children = docsContentResponse.children.map {
        addRecursive(
            it,
            folderPath,
            addItem
        )
    })
}

fun updateRecursive(
    docsContentResponse: DocsContentResponse,
    folderPath: String,
    docsContentListResponse: MutableList<DocsContentResponse>
): DocsContentResponse {
    if (docsContentResponse.path == folderPath) {
        return docsContentResponse.copy(children = docsContentListResponse.map { it.copy(parent = docsContentResponse.path) })
    }
    return docsContentResponse.copy(children = docsContentResponse.children.map {
        updateRecursive(
            it,
            folderPath,
            docsContentListResponse
        )
    })
}

public fun Iterable<DocsContentResponse>.searchBySHA(sha: String): DocsContentResponse? {
    for (element in this) {
        if (element.sha == sha) {
            return element
        }
        element.children.takeIf { it.isNotEmpty() }?.let { elements ->
            elements.searchBySHA(sha)?.let { return it }
        }
    }
    return null
}

public fun Iterable<DocsContentResponse>.searchByPath(path: String): DocsContentResponse? {
    for (element in this) {
        if (element.path == path) {
            return element
        }
        element.children.takeIf { it.isNotEmpty() }?.let { elements ->
            elements.searchByPath(path)?.let { return it }
        }
    }
    return null
}