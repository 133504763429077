/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models

import org.openapitools.client.models.TaskStatusDetailsResponse

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param projectName 
 * @param description 
 * @param startDate 
 * @param endDate 
 * @param boardId 
 * @param taskStatusDetails 
 */
@Serializable

data class UpdateProjectRequest (

    @SerialName(value = "projectName") val projectName: kotlin.String? = null,

    @SerialName(value = "description") val description: kotlin.String? = null,

    @SerialName(value = "startDate") val startDate: String? = null,

    @SerialName(value = "endDate") val endDate: String? = null,

    @SerialName(value = "boardId") val boardId: kotlin.String? = null,

    @SerialName(value = "taskStatusDetails") val taskStatusDetails: kotlin.collections.List<TaskStatusDetailsResponse>? = null

)

