/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models

import org.openapitools.client.models.UserRegistration

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param id 
 * @param text 
 * @param createdBy 
 * @param createdAt 
 * @param attachments 
 * @param taskId 
 * @param createdByDetails 
 */
@Serializable

data class Comment (

    @SerialName(value = "id") val id: kotlin.String? = null,

    @SerialName(value = "text") var text: kotlin.String? = null,

    @SerialName(value = "createdBy") val createdBy: kotlin.String? = null,

    @SerialName(value = "createdAt") val createdAt: kotlin.String? = null,

    @SerialName(value = "attachments") val attachments: kotlin.collections.List<kotlin.String>? = null,

    @SerialName(value = "taskId") val taskId: kotlin.String? = null,

    @SerialName(value = "createdByDetails") val createdByDetails: UserRegistration? = null

)

