package org.company.app.presentation.ui.onBoarding

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowForward
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.Navigator
import cafe.adriel.voyager.navigator.currentOrThrow
import org.company.app.data.model.User
import org.company.app.presentation.component.ZiFocusTextField
import org.company.app.presentation.viewmodel.RegisterViewModel
import org.company.app.theme.AppTheme
import org.koin.compose.koinInject

class WhatWeCallYouScreen(val nextWithRouter: () -> Unit={}) : Screen {
    @OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        val windowSizeClass = calculateWindowSizeClass()
        val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
        WhatWeCallYouContent(useNavRail, navigator)

    }

    @OptIn(ExperimentalMaterial3Api::class)
    @Composable
    fun WhatWeCallYouContent(useNavRail: Boolean, navigator: Navigator) {
        val registerViewModel = koinInject<RegisterViewModel>()
        val registerState = registerViewModel.registerState.collectAsState()
        AppTheme {
            if (registerState.value.errorMessage!=null) {
                AlertDialog(
                    onDismissRequest = {  },
                    properties = DialogProperties(
                        dismissOnClickOutside = false,
                        dismissOnBackPress = true
                    ),
                    title = { Text(text = "Error") },
                    text = {
                        Text(registerState.value.errorMessage?:"Error")
                    },
                    confirmButton = {
                        Button(
                            modifier = Modifier.fillMaxWidth(),
                            onClick = {
                                registerViewModel.clearRegisterUIState()
                            }
                        ) {
                            Text("Dismiss")
                        }
                    }
                )
            }
            val name = rememberSaveable { mutableStateOf("") }
            val displayName = rememberSaveable { mutableStateOf("") }
            Column(horizontalAlignment = Alignment.CenterHorizontally, verticalArrangement = Arrangement.Center,modifier =  Modifier.fillMaxSize() ) {
                if (!useNavRail) Spacer(modifier = Modifier.height(48.dp))
                Row(modifier =  Modifier.fillMaxWidth(if (useNavRail) 0.5f else 1f).padding(top = 12.dp)) {
                    Text("Time to set you up", modifier = Modifier,fontSize = 32.sp,
                        style = MaterialTheme.typography.bodyLarge,
                        color = Color.Black, textAlign = TextAlign.Start)
                    Spacer(modifier = Modifier.weight(1f))
                }
                Spacer(modifier = Modifier.height(64.dp))
                ZiFocusTextField(
                    value = name.value,
                    onValueChange = {
                        name.value = it
                        displayName.value =  name.value.split(" ")[0]
                    },
                    textStyle =  MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp),
                    modifier = Modifier.padding(8.dp).fillMaxWidth(if (useNavRail)0.5f else 1f),
                    placeHolder = "Your Full Name"
                )
                Spacer(modifier = Modifier.height(32.dp))
                ZiFocusTextField(
                    text = displayName,
                    textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp),
                    modifier = Modifier.padding(8.dp).fillMaxWidth(if (useNavRail)0.5f else 1f),
                    placeHolder = "Username"
                )



                /*
              OutlinedTextField(
                  value = name,
                  onValueChange = {
                      name = it
                      displayName =  name.split(" ")[0]
                  },
                  label = { Text("Your Full Name") },
                  singleLine = true,
                  shape = RoundedCornerShape(10.dp),
                  modifier = Modifier.padding(8.dp).fillMaxWidth(0.5f)
              )


              OutlinedTextField(
                  value = displayName,
                  onValueChange = { displayName = it },
                  label = { Text("Username") },
                  singleLine = true,
                  shape = RoundedCornerShape(10.dp),
                  modifier = Modifier.padding(horizontal = 8.dp).fillMaxWidth(0.5f)
              )
               */


                Row(modifier =  Modifier.fillMaxSize(if (useNavRail) 0.5f else 1f).padding(top = 12.dp)) {
                    Spacer(modifier = Modifier.weight(1f))
                    Button(modifier = Modifier.padding(end = if (useNavRail)0.dp else 15.dp),onClick = {
                        registerViewModel.updateUser(User(name = name.value, userName = displayName.value, isEmailVerified = true)){
                            navigator.push(UsageIdentificationScreen(name.value,displayName.value,nextWithRouter))
                        }

                    },
                        shape = RoundedCornerShape(10.dp)
                        , enabled = name.value.isNotEmpty()) {
                        Text("Next", fontSize = 15.sp, style = MaterialTheme.typography.displayMedium)
                        Icon(imageVector = (Icons.Default.ArrowForward), contentDescription = null)
                    }
                }

            }
        }


    }
}