/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models

import org.openapitools.client.models.TaskStatusDetailsResponse

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param id 
 * @param projectId 
 * @param projectName 
 * @param description 
 * @param startDate 
 * @param endDate 
 * @param createdBy 
 * @param createdAt 
 * @param boardId 
 * @param taskStatusDetails 
 */
@Serializable

data class Project (

    @SerialName(value = "id") val id: kotlin.String? = null,

    @SerialName(value = "projectId") val projectId: kotlin.String? = null,

    @SerialName(value = "projectName") var projectName: kotlin.String? = null,

    @SerialName(value = "description") var description: kotlin.String? = null,

    @SerialName(value = "startDate") val startDate: String? = null,

    @SerialName(value = "endDate") val endDate: String? = null,

    @SerialName(value = "createdBy") val createdBy: kotlin.String? = null,

    @SerialName(value = "createdAt") val createdAt: String? = null,

    @SerialName(value = "boardId") val boardId: kotlin.String? = null,

    @SerialName(value = "taskStatusDetails") var taskStatusDetails: kotlin.collections.List<TaskStatusDetailsResponse>? = null

)

