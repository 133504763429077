/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param text 
 * @param attachments 
 * @param taskId 
 */
@Serializable

data class CommentRequest (

    @SerialName(value = "text") val text: kotlin.String? = null,

    @SerialName(value = "attachments") val attachments: kotlin.collections.List<kotlin.String>? = null,

    @SerialName(value = "taskId") val taskId: kotlin.String? = null

)

