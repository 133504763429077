package org.company.app.presentation.ui.onBoarding

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowForward
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.Navigator
import cafe.adriel.voyager.navigator.currentOrThrow
import multiplatform_app.composeapp.generated.resources.*
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.personal_image
import multiplatform_app.composeapp.generated.resources.work_filled
import multiplatform_app.composeapp.generated.resources.work_image
import org.company.app.data.model.User
import org.company.app.platformName
import org.company.app.presentation.component.CheckItem
import org.company.app.presentation.ui.HomeScreen
import org.company.app.presentation.viewmodel.HomeViewModel
import org.company.app.presentation.viewmodel.RegisterViewModel
import org.company.app.theme.AppTheme
import org.koin.compose.koinInject

class UsageIdentificationScreen(val name: String,val userName: String, val nextWithRouter: () -> Unit = {})  : Screen {
    @OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        val windowSizeClass = calculateWindowSizeClass()
        val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact

            UsageIdentification(useNavRail,navigator)


    }
    @Composable
    fun UsageIdentification(useNavRail: Boolean, navigator: Navigator) {
        AppTheme {
            val viewModel = koinInject<RegisterViewModel>()
            val registerState by viewModel.registerState.collectAsState()
            var selectedOption by rememberSaveable { mutableStateOf("") }
            val loading = remember { mutableStateOf(false) }
            val onBoardViewModel = koinInject<HomeViewModel>()
            val onBoardingState by onBoardViewModel.homeState.collectAsState()



            if (registerState.errorMessage!=null) {
                AlertDialog(
                    onDismissRequest = {  },
                    properties = DialogProperties(
                        dismissOnClickOutside = false,
                        dismissOnBackPress = true
                    ),
                    title = {  },
                    text = { Text(onBoardingState.errorMessage?:"Error") },
                    confirmButton = {
                        Button(
                            modifier = Modifier.fillMaxWidth(),
                            onClick = {
                                onBoardViewModel.clearUIState()
                            }
                        ) {
                            Text("Dismiss")
                        }
                    }
                )
            }

            val options = listOf("Work", "Personal")

            val images = listOf(Res.drawable.work_image,Res.drawable.personal_image)
            val imagesFilled = listOf(Res.drawable.work_filled,Res.drawable.personal_filled)

            // Define the selected option state
            Column(modifier = if (useNavRail) Modifier.padding(top = 80.dp).fillMaxSize() else Modifier.padding(top = 40.dp).padding(8.dp),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                //   Spacer(modifier = Modifier.height(if (useNavRail) 100.dp else 0.dp))

                Row(modifier =  Modifier.fillMaxWidth(if (useNavRail) 0.5f else 1f).padding(top = 12.dp)) {
                    Text(text = buildAnnotatedString {
                        append("Hello ${name}!\n")
                        withStyle(
                            SpanStyle(
                               // fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                                fontStyle = MaterialTheme.typography.bodySmall.fontStyle
                                , fontSize =  17.sp,
                                color = Color.Gray
                            )
                        ) {
                            append("How do you plan to use ZiFocus?")
                        }
                    },
                        fontSize = if (useNavRail)32.sp else 24.sp, style = MaterialTheme.typography.bodyLarge
                    )
                    Spacer(modifier = Modifier.weight(1f))


                }
                Spacer(modifier = Modifier.height(32.dp))
                if (useNavRail) {
                    Row(
                        modifier = Modifier
                        //  .fillMaxWidth()
                    ) {
                        // Loop through the options and create the radio buttons
                        options.forEachIndexed { index,option ->
                            CheckItem(
                                text = option, // pass the option text
                                selected = option == selectedOption, // pass the selected state
                                onSelect = { selectedOption = option }, // pass the callback function
                                imageChecked = imagesFilled[index], // pass the image to display when selected
                                imageUnChecked = images[index], // pass the image to display when selected
                                useNavRail = useNavRail
                            )
                            Spacer(modifier = Modifier.width(50.dp)) // add some vertical space
                        }
                    }
                }
                else {
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                    ) {
                        // Loop through the options and create the radio buttons
                        options.forEachIndexed { index,option ->
                            CheckItem(
                                text = option, // pass the option text
                                selected = option == selectedOption, // pass the selected state
                                onSelect = { selectedOption = option }, // pass the callback function
                                imageChecked = imagesFilled[index], // pass the image to display when selected
                                imageUnChecked = images[index], // pass the image to display when selected
                                useNavRail = useNavRail
                            )
                            Spacer(modifier = Modifier.height(22.dp)) // add some vertical space
                        }
                    }
                }



                // Create the radio group

                Spacer(modifier = Modifier.height(24.dp))
                // Spacer(modifier = Modifier.weight(1f))
                Row(modifier =  Modifier.fillMaxSize(if (useNavRail) 0.5f else 1f)) {
                    Spacer(modifier = Modifier.weight(1f))
                    Button(onClick = {
                        //  navigator.push(AreasOfInterestScreen(selectedOption,name))
                        loading.value = true
                        viewModel.updateUser(User(focus = selectedOption)){
                            loading.value = false
                            if (selectedOption=="Work"){
                                navigator.push(CreateOrganizationScreen(it,nextWithRouter))
                            } else {
                                onBoardViewModel.createBoard("Personal"){
                                    onBoardViewModel.saveUser(it)
                                    if (platformName()=="Web") this@UsageIdentificationScreen.nextWithRouter()
                                    else navigator.push(HomeScreen())
                                }
                            }
                        }
                    },shape = RoundedCornerShape(10.dp)) {
                        Text("Next", fontSize = 15.sp, style = MaterialTheme.typography.displayMedium)
                        Icon(imageVector = (Icons.Default.ArrowForward), contentDescription = null)
                    }
                }

                AnimatedVisibility(loading.value){
                    CircularProgressIndicator()
                }
                /*
                Row {
                    // Back Button
                    /*
                    OutlinedButton(onClick = {
                        navigator.pop()
                    },
                        shape = RoundedCornerShape(10.dp)
                    ) {
                        Icon(imageVector = (Icons.Default.ArrowBack), contentDescription = null, tint = Color.Black)
                        Text("Back", fontSize = 17.sp, fontFamily = fontFamilyResource(MR.fonts.sf.semibold), color = Color.Black)

                    }
                    */
                    Spacer(modifier = Modifier.weight(1f))
                    Button(onClick = {
                      //  navigator.push(AreasOfInterestScreen(selectedOption,name))
                        viewModel.updateUser(User(name = name, focus = selectedOption))
                    },shape = RoundedCornerShape(10.dp)) {
                        Text("Next", fontSize = 17.sp, fontFamily = fontFamilyResource(MR.fonts.sf.semibold))
                        Icon(imageVector = (Icons.Default.ArrowForward), contentDescription = null)
                    }


                }

                 */
                Spacer(modifier = Modifier.height(120.dp))
                // uncomment this to remove the padding and start from TOP of screen
                //  if (!useNavRail) Spacer(modifier = Modifier.weight(1f))
            }
        }


    }

}