package org.company.app.data.model

import kotlinx.serialization.Serializable

@Serializable
data class FirebaseLoginResponse(
    val kind: String,
    val localId: String,
    val email: String,
    val displayName: String,
    val idToken: String,
    val registered: Boolean,
    val refreshToken: String? = null, // Add this line
    val expiresIn: String? = null // Add this line
)
