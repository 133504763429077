/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param name 
 * @param columns 
 * @param workType 
 * @param viewLayout 
 * @param numPeopleOptions 
 * @param toolUsed 
 */
@Serializable

data class BoardRequest (

    @SerialName(value = "name") @Required val name: kotlin.String,

    @SerialName(value = "columns") @Required val columns: kotlin.collections.List<BoardRequest.Columns>?,

    @SerialName(value = "workType") val workType: BoardRequest.WorkType? = null,

    @SerialName(value = "viewLayout") val viewLayout: BoardRequest.ViewLayout? = null,

    @SerialName(value = "numPeopleOptions") val numPeopleOptions: kotlin.String? = null,

    @SerialName(value = "toolUsed") val toolUsed: kotlin.String? = null

) {

    /**
     * 
     *
     * Values: Title,Description,DueDate,CustomField
     */
    @Serializable
    enum class Columns(val value: kotlin.String) {
        @SerialName(value = "Title") Title("Title"),
        @SerialName(value = "Description") Description("Description"),
        @SerialName(value = "DueDate") DueDate("DueDate"),
        @SerialName(value = "CustomField") CustomField("CustomField");
    }
    /**
     * 
     *
     * Values: Project,Task,Custom
     */
    @Serializable
    enum class WorkType(val value: kotlin.String) {
        @SerialName(value = "Project") Project("Project"),
        @SerialName(value = "Task") Task("Task"),
        @SerialName(value = "Custom") Custom("Custom");
    }
    /**
     * 
     *
     * Values: Table,Cards,Timeline,Calendar,Kanban,Gantt
     */
    @Serializable
    enum class ViewLayout(val value: kotlin.String) {
        @SerialName(value = "Table") Table("Table"),
        @SerialName(value = "Cards") Cards("Cards"),
        @SerialName(value = "Timeline") Timeline("Timeline"),
        @SerialName(value = "Calendar") Calendar("Calendar"),
        @SerialName(value = "Kanban") Kanban("Kanban"),
        @SerialName(value = "Gantt") Gantt("Gantt");
    }
}

