/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param page 
 * @param total 
 * @param propertySize 
 * @param nextPageToken 
 */
@Serializable

data class Pagination (

    @SerialName(value = "page") val page: kotlin.Int? = null,

    @SerialName(value = "total") val total: kotlin.Int? = null,

    @SerialName(value = "size") val propertySize: kotlin.Int? = null,

    @SerialName(value = "nextPageToken") val nextPageToken: kotlin.String? = null

)

