/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param userResponse 
 * @param boardId 
 */
@Serializable

data class BoardMemberInviteUserResponseRequest (

    @SerialName(value = "userResponse") val userResponse: BoardMemberInviteUserResponseRequest.UserResponse? = null,

    @SerialName(value = "boardId") val boardId: kotlin.String? = null

) {

    /**
     * 
     *
     * Values: Accepted,Rejected,Pending
     */
    @Serializable
    enum class UserResponse(val value: kotlin.String) {
        @SerialName(value = "Accepted") Accepted("Accepted"),
        @SerialName(value = "Rejected") Rejected("Rejected"),
        @SerialName(value = "Pending") Pending("Pending");
    }
}

