package org.company.app.data.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RefreshTokenRequest (
    @SerialName("grant_type"          ) var grant_type          : String? = null,
    @SerialName("refresh_token"   ) var refresh_token   : String? = null,
)

@Serializable
data class ResetPasswordRequest (
    @SerialName("requestType") var requestType : String? = null,
    @SerialName("email") var email   : String? = null,
)

@Serializable
data class ResetPasswordResponse (
    @SerialName("kind"    ) var kind    : String? = null,
    @SerialName("email"   ) var email   : String? = null,
)